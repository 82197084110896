import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export function success(message) {
  toast.success(message);
}
export function error(message) {
  toast.error(message);
}

const NotificationsContainer = () => (
  <ToastContainer
    className="ooo-toastify"
    theme="osoco"
    position="bottom-right"
    autoClose={3000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable={false}
    pauseOnHover={false}
  />
);

export default NotificationsContainer;
