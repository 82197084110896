import React, { useState } from "react";

import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import CreateNewAdministrator from "../admin-area-new-administrator/CreateNewAdministrator";
import EditAdministrator from "../admin-area-administrator-detail/EditAdministrator";
import AdministratorsTable from "../admin-area-administrator-table/AdministratorsTable";

const AdministratorsManagement = () => {
	const [showFormAdmin, setShowFormAdmin] = useState(false);
	const [showAdminDetails, setShowAdminDetails] = useState(null);

	const handleFormAdmin = () => {
		setShowFormAdmin(true);
	};

	const handleUser = (user) => {
		setShowAdminDetails(user);
	};

	return (
		<div className="team-management__form">
			{showFormAdmin ? (
				<CreateNewAdministrator />
			) : showAdminDetails ? (
				<div>
					<EditAdministrator user={showAdminDetails} />
				</div>
			) : (
				<>
					<AdministratorsTable onRowClick={handleUser} />

					<ButtonComponent
						text={"Add new admin"}
						onClick={handleFormAdmin}
					/>
				</>
			)}
		</div>
	);
};

export default AdministratorsManagement;
