import React from "react";

const CookiePolicy = () => {
	return (
		<section className="info-legal">
			<div className="div-block-7 legalintro">
				<h1 className="title-h1 title-legal">Cookie policy</h1>
				<p className="text-legal"><strong className="text-legal">Last updated: August 2024</strong></p>
				
				<p className="text-legal">
					<strong>1. Concept of Cookies</strong> <br /><br />
					Cookies are files that are stored on the user’s device that browses through the Internet orthrough other types of platforms and applications (for example, apps for smartphones andtablets) and that, in particular, it contains a number that uniquely identifies the user’s device,even if it changes location or IP address.
					In general, these technologies can serve a wide range of purposes, such as recognizing you asa user, obtaining information about your browsing habits or behavior on the Site, or evenshowing you personalized advertising based on those. The concrete uses we make of thesetechnologies are described below. <br /><br />

					<strong>2. Data protection</strong>	 <br /><br />
					In certain circumstances, the use of cookies may involve the processing of personal data ofusers of the devices when they visit the website (for example, because the cookie includes theIP address of the device). This data processing will have the purpose indicated for each type ofcookie and, except in cases where its use does not require it, it will have its legal basis on yourconsent and that you can revoke at any time deleted cookies as explained in section 5. <br /><br />

					<strong>3. Authorization for the use of cookies</strong> <br /><br />
					In accordance with this cookie notice and provided you have accepted cookies, we may placecookies in your browser. Your consent is not required for the use of technical cookies,preferences or customisation that are necessary to ensure the basic operation of the Site or toprovide the service you have requested, which do not require your consent and thereforecannot be disabled. <br /><br />

					<strong>4. Types of Cookies that are used</strong> <br /><br />
					On this website you can find cookies inserted directly by [include the name of the publishingentity of the website], or cookies inserted by third parties, in accordance with the informationdetailed in the following section: <br /><br />

					<strong>4.1. According to its purpose (own and third party cookies).</strong> <br /><br />
					Below, the categories of cookies that could be installed on the user’s device are detailedaccording to their purpose and considering that, according to the information in the table oneach particular cookie, such cookies can be installed by the owner of the Site or by thirdparties: <br /> <br />

					Technical cookies: These are Cookies that allow the user to browse through the Siteand the use of the different options or services that exist therein, including those thatare used to allow the management and operation of the Site and enable its functionsand services, such as, for example, control traffic and data communication, identifythe session, access restricted access parts, remember the elements that make up anorder, make the purchase process of an order, manage payment, control fraud linkedto the security of the service, make the request for registration or participation in anevent, count visits for the purpose of licensing the software with which the Siteoperates, use security elements during navigation, store content for the broadcast ofvideos or sound, enable dynamic content (for example, animation of loading a text orimage) or sharing content through social networks. <br /> <br />

					They also belong to this category, due to their technical nature and provided that theyare not used for other non-exempt purposes, those cookies that allow themanagement, in the most efficient way possible, of the advertising spaces that, asanother element of design or “layout” of the service offered to the user, it has beenincluded in the Site, application or platform based on criteria such as edited content, without collecting information from users for different purposes, how can it becustomizing that advertising content or other content. <br /> <br />

					Preference or personalization cookies. They are Cookies that allow the user toremember information so that the user accesses the service with certaincharacteristics that can differentiate their experience from that of other users, such as,for example, the language, the number of results to show when the user performs asearch, the aspect or content of the service depending on the type of browserthrough which the user accesses the service or the region from which the service isaccessed, etc.However, if it is the user who chooses these characteristics (for example, if he selectsthe language of a website by clicking on the flag icon of the respective country), andonly for this purpose, it will be considered as a service expressly requested by the userand will not be asked for consent to the installation. <br /> <br />

					Analysis or measurement cookies: These are own or third-party cookies that allowthe tracking and analysis of the behavior of the users of the Site, including thequantification of the impacts of the advertisements. The information collectedthrough this type of cookies is used to measure the activity of the Site, in order tointroduce improvements based on the analysis of the usage data made by users ofthe service. <br /><br />

					Behavioural advertising cookies. They are Cookies that store information on thebehavior of users obtained through the continuous observation of their browsinghabits, which allows to develop a specific profile to show advertising based on it.Refusal to use behavioral advertising cookies will prevent the Publisher from showingthe user personalized advertising of interest. <br /><br />

					<strong>4.2. According to the entity that manages them:</strong> <br /><br />
					Own cookies: These are Cookies that are sent to the user’s terminal equipment froma computer or domain managed by the owner of the Site and from which the servicerequested by the user is provided. <br />
					Third-party cookies: These are Cookies that are sent to the user’s terminal equipmentfrom a computer or domain that is not managed by the owner of the Site, but byanother entity that processes the data obtained through cookies. <br /> <br />

					<strong>4.3. Depending on the time period they remain active:</strong>	 <br /><br />
					Session Cookies: These are designed to collect and store data while the user accessesa Site. These cookies are not stored in the user’s terminal when the session expires orcloses the browser. <br />
					Persistent cookies: These are those in which the data is stored in the user’s terminalwhen he leaves the Site and can be accessed and processed for a period defined by the person responsible for the cookie, which can range from a few minutes to several years. <br /><br />


					<strong>5. Ways to disable or delete cookies</strong> <br /><br />
					The User may at any time revoke or modify his/her consent to the use of cookies, through theconfiguration panel referred to in paragraph 3, which can be found at the following link [-],through common platforms that may exist for this purpose, or through his/her browser. <br />
					If you wish to revoke consent through the browsers you use, you must follow the instructionsthereof. configure it in the following terms: <br />
					- Firefox <br />
					- Internet Explorer <br />
					- Safari <br />
					- Chrome <br /> <br />

					It is important to keep in mind that if you have accepted third-party cookies, you shoulddelete them from the browser options. In this sense, it is recommended that you consult thehelp of your browser or access the help web pages of the main browsers indicated in theprevious paragraph. <br /> <br />

					You can also use tracking cookie blocking tools like “do not track”. If you want moreinformation about how to revoke the consent given or about the procedure to disable cookies,as well as ask any questions about this Cookies Notice you can write to privacy@pagonxt.comindicating in the subject Cookies Policy. <br /><br />
					‍
					<strong>6. Effective date of the Policy:</strong> <br /><br />
					This policy was last modified on [ ], and may be subject to further modifications when requiredby current legislation at any time, or when there is a variation in the use of cookies used onthe Site, in which case and if necessary, we will again request consent for its use.To be informed at all times about the cookies we use and their purposes, we recommend thatyou access the content of this notice when you visit our Site.
				</p>


			</div>
		</section>

	);
};

export default CookiePolicy;
