export const arrayToSelectOptions = (arr) => {
	return arr.map((item) => ({
		value: capitalizeEachWord(item),
		label: capitalizeEachWord(item),
	}));
};

export const capitalizeEachWord = (str) => {
	return str.split(" ").map(capitalize).join(" ");
};

export const capitalize = (str) =>
	str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const createOrganizationTypeMap = (typeOrganization) => {
	const map = {};
	typeOrganization.forEach((type) => {
		map[type.codOrganization] = type.description;
	});
	return map;
};
