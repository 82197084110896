import React from "react";

const PrivacyNotice = () => {
	return (
		<section className="info-legal">
			<div className="div-block-7 legalintro">
				<h1 className="title-h1 title-legal">Privacy Notice</h1>
				<p className="text-legal"><strong className="text-legal">Last updated: August 2024</strong></p>
				
				<p className="text-legal">
				<strong>1. WHO ARE WE?</strong><br/>
				PagoNxt Payments Services is a modern and scalable platform for end-to-end payments and messaging solutions (hereinafter "we" or "PagoNxt"). Our clients are companies and financial institutions (legal entities, hereinafter "the Client" or "the Clients").
				</p>
				
				<p className="text-legal">
				<strong>2. WHAT IS THE OBJECTIVE OF THIS POLICY?</strong><br/>
				The purpose of this privacy policy is to explain in a transparent, clear and simple way how we collect, process and protect your personal information and to ensure that PagoNxt does not trade in personal information and that it allows data subjects to handle it in accordance with applicable regulations.
				</p>
				
				<p className="text-legal">
				<strong>3. TO WHOM IS THIS POLICY ADDRESSED?</strong><br/>
				This policy is aimed at data subjects who are the holders of the personal information that PagoNxt processes.  Specifically, we distinguish the following types of data subjects to which we will refer throughout this document:<br/><br />
				<strong>1. Users:</strong> These are the data subjects who browse our website.<br/>
				<strong>2. Applicants:</strong> data subjects who request information through one of our contact channels.<br/>
				<strong>3. Representatives:</strong> data subjects representing one of the companies or institutions that are customers of PagoNxt or are in the process of becoming so. This group includes the following:Legal representatives or representatives.Contact persons.Authorized persons. This being understood to mean those persons authorized by our clients to access the different portals that PagoNxt makes available to them such as the Developer Portal, the Quantum Portal, the Incident Portal or any others.<br/>
				<strong>4. Developers:</strong> Developer Portal users.<br/>
				<strong>5. Candidates:</strong> data subjects who have applied for one of PagoNxt’s job offers. The information regarding the processing of their data is collected at the end of this notice (here).
				</p>
				
				<p className="text-legal">
				<strong>AT A GLANCE: BASIC INFORMATION</strong><br/>
				To facilitate understanding, we include the most relevant information in the table below. You can find more detailed information in the following heading and contact our Data Protection Officer (DPD) for any clarification or additional queries by writing to <a href="mailto:privacy.trade@pagonxt.com">privacy.trade@pagonxt.com</a> or through any of our channels. .
				</p>

				<div>
				<table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
					<tbody>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WHO IS RESPONSIBLE FOR THE PROCESSING OF YOUR DATA?</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4'}}>
						<b>PagoNxt Payments Services S.L.</b><br/>
						<b>N.I.F.:</b> B-87959326<br/>
						<b>Address:</b> Avenida de Cantabria 5 – Edifico Alhambra Pl 1, CP 28660, Boadilla del Monte (Madrid)<br/>
						<b>Contact DPO:</b> <a href="mailto:privacy.trade@pagonxt.com">privacy.trade@pagonxt.com</a>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>HOW DO WE USE YOUR DATA?</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						We process your data to; i) be able to contact you or your company if you have asked us for information, ii) to maintain commercial and legal relations with the company you represent and provide the contracted services, iii) to comply with our legal obligations, to send commercial communications to your company, iv) if you are a developer to allow you to use our APIS, v) to send you commercial communications if you have consent and vi) in the case of users of our website to, provided they have accepted its use, insert cookies with various functions.
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WHAT LEGAL BASIS DO WE USE TO PROCESS YOUR DATA?</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#f4f4f4'}}>
						It depends on the processing, but the main legal bases are legitimate interest, compliance with legal obligations and consent.
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>WITH WHOM DO WE SHARE YOUR DATA?</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<b>We can share your data with:</b>
						<ul>
							<li>Authorities and courts in legally provided cases.</li>
							<li>Third parties providing services under a data processor agreement (per Article 28 of the GDPR).</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WHERE DO WE GET YOUR DATA FROM?</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#f4f4f4'}}>
						The data we process <b>may have been collected directly from you, may have been given to us by a third party or confirmed by the company for which you are proxy, contact</b>, they may be included in the information given to us by one of the companies of the Santander Group or PagoNxt of which your company is already a customer and whose communication has authorized.
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>WHAT ARE YOUR RIGHTS?</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						You can always write to us at privacy.trade@pagonxt.com or use any of our channels available on the private portal to exercise your rights of:
						<ul>
							<li>Access: Or what is the same, let us tell you what data we have about you and provide you with a copy of it.</li>
							<li>Rectification: That we correct the information we hold about you if it is incorrect.</li>
							<li>Cancellation or deletion: That we delete your data when it is no longer necessary or when we are processing it because you have given us your consent.</li>
							<li>Objection: When the processing of your data is based on a legitimate interest.</li>
							<li>Limitation of processing: For example, when you have challenged the accuracy of themselves</li>
							<li>Portability: Requesting that we communicate your data to a third party in a structured, commonly used and machine-readable format, wherever possible, or to yourself.</li>
							<li>Right not to be subject to automated decisions.</li>
						</ul>
						You can also file a claim with the AEPD at www.aepd.es
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>ADDITIONAL INFORMATION</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						You can find more detailed information in the second layer.
						</td>
					</tr>
					</tbody>
				</table>
				</div>
				<p className="text-legal">
				DETAILED INFORMATION ABOUT PRIVACY (SECOND LAYER)<br/><br />
				<strong>1. DO I HAVE TO SHARE MY DATA?</strong><br/>
				Yes. PagoNxt requests data from you directly, for example, when you contact us to request information or when we request data from your company, such as proxies or contact persons authorized to use different portals. Providing this data is necessary for the purposes for which it is requested and therefore mandatory.
				</p>
				<p className="text-legal">
					<strong>2. WHY DO WE PROCESS YOUR DATA? WHAT LEGAL BASIS DO WE USE? WHAT DATA ARE THEY AND WHERE DO THEY COME FROM?</strong>
				</p>

			<div>
			<table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
				<tbody>
				<tr>
					<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>DATA SUBJECT</th>
					<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WHAT DATA DO WE PROCESS ABOUT YOU AND WHAT IS ITS ORIGIN?</th>
					<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WHAT DO WE PROCESS YOUR DATA FOR?</th>
					<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WHAT IS THE LEGAL BASIS OF THE PROCESSING?</th>
				</tr>
				<tr>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>User</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					We treat the information we receive through the use of cookies if you have accepted them. This information may include your IP or unique identifiers that, although they do not allow us to uniquely identify you without additional information, are considered personal data and originate from your navigation on our websites. More information can be found in our cookies policy.
					</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					Depending on the type of cookies you have accepted, we will use them to improve our website by analyzing your usage or personalizing it. More information can be found in our cookies policy.
					</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					<b>Consent</b>
					</td>
				</tr>
				<tr>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Applicant</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					Depending on the means you use to contact us, we may process:<br/>
					<ul>
						<li>Name and surname</li>
						<li>Email</li>
						<li>Phone</li>
						<li>Position in your company</li>
					</ul>
					The data is obtained directly from you.
					</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					We process the data to meet your request and to inform you about our products or services.
					</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					<b>Legitimate interest</b> in responding to your proactive and express request for information. This is expected by you and does not infringe on your rights.
					</td>
				</tr>
				<tr>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Representative (includes proxies, contact persons, and authorized persons)</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					The data that we may process includes those listed below and may have been communicated by you directly, if, for example, you signed your company’s contract with us or registered with the Developer Portal, or by your company (when you provide us with a list of authorized persons).
					<ul>
						<li>Name and surname</li>
						<li>Professional location data (address, email, mobile, and post)</li>
						<li>NIF</li>
						<li>Any other information included in the documentation provided for client registration Access credentials</li>
						<li>Access credentials</li>
						<li>IP, time, and location of the device from which the services are accessed</li>
						<li>Voice in case of telephone recordings (in these cases, you will be previously informed of the recording)</li>
					</ul>
					</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					<b>1)</b> Register our Client and maintain the contractual and commercial relationship with it.<br/>
					<b>2)</b> Send communications regarding the contracted services.<br/>
					<b>3)</b> Send commercial communications (directed to your company) about products similar to those already contracted.<br/>
					<b>4)</b> Prevent and control the use of PagoNxt for illegal or unauthorized purposes.<br/>
					<b>5)</b>Send commercial communications of our own or of third parties for which we have requested consent.<br/>
					<b>6)</b> Send satisfaction surveys about the service. <br />
					<b>7)</b> Use voice recordings to evaluate service quality and as proof of instructions received or service provided, if necessary.
					</td>
					<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
					<b>1) Legitimate interest</b> of both our Client and PagoNxt in maintaining a contractual and commercial relationship.<br/>
					<b>2) Legitimate interest</b>  in informing our client about products similar to those contracted. <br />
					<b>3) Legitimate interest</b> in ensuring that PagoNxt is not used for illicit purposes and managing any illegal conduct.<br/>
					<b>4) Consent.</b><br/>
					<b>5) Legitimate interest</b> in knowing the degree of customer satisfaction and seeking improvements. <br />
					<b>6) Legitimate interest</b> in improving service quality and exercising our rights.<br/>
					
					</td>
				</tr>
				</tbody>
			</table>
			</div>

			<p className="text-legal">
			<strong>3. WITH WHOM CAN WE SHARE YOUR DATA?</strong>
			</p>

				<div>
				<table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
					<tbody>
					<tr>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>TYPE OF INTERESTED</th>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WITH WHOM CAN IT BE SHARED?</th>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>WHY CAN DATA BE SHARED?</th>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>ON WHAT LEGAL BASIS?</th>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Any interested party (users, applicants, representatives)</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<b>1.With third parties (data processors):</b> PagoNxt uses third parties in the framework of its activity. Some of these third parties may access the personal information processed by PagoNxt but will always do so under a data processing agreement and under the instructions of PagoNxt. These third parties may belong to sectors such as cloud services, technology, communications, etc.<br/>
						<b>2.With the following:</b> 
							<ul>
								<li>Public administrations</li>
								<li>Courts and courts</li>
								<li>State Security Forces and Corps</li>
							</ul>
						</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<b>1.</b> o provide services to PagoNxt.<br/>
						<b>2.</b> To respond to the specific requests of the same always complying with the applicable regulations or to exercise our rights.
						</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<b>1.</b> This communication is always supported by a processing order that will comply with the requirements of art. 28 of the General Data Protection Regulation, treating the data in charge of the processing on behalf and following the instructions of PagoNxt.<br/>
						<b>2.</b> Compliance with legal obligations and legitimate interest in exercising our rights.
						</td>
					</tr>
					</tbody>
				</table>
				</div>

				<p className="text-legal">
				<strong>4. INTERNATIONAL DATA TRANSFERS</strong><br/>
				No international transfers are carried out outside the European Economic Area, except in the case of the incident portal that is located whose services are provided by a processor besieged in the United States that is certified under the Data Privacy Framework which provides it with adequate guarantees.
				</p>

				<p className="text-legal">
				<strong>5. HOW LONG WILL WE KEEP PERSONAL INFORMATION?</strong>
				</p>

				<div className="w-embed">
				<table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
					<tbody>
					<tr>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>INTERESTED</th>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>CONSERVATION PERIOD</th>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>User</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						We treat the information we collect through the use of Cookies for the periods indicated in the cookie policy that you can review here
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Applicant</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						For the period necessary to respond to the inquiry or request for information. After that period, the data will be blocked for a period of 3 years and will be deleted. The 3 years are the maximum limitation period for actions in matters of data protection collected by the LOPDyGDD (Organic Law 3/2018).
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Representative</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						For the entire duration of the contractual relationship plus 5 years as a general rule, unless there are longer applicable limitation periods, in which the data will be kept blocked. In cases where data is processed in order to comply with legal obligations, these will be kept for the period indicated therein.
						</td>
					</tr>
					</tbody>
				</table>
				</div>

				<p className="text-legal">
				<strong>6. WHAT ARE MY RIGHTS AND HOW DO I EXERCISE THEM?<br/>
				You have the following rights:</strong><br/><br />
				· <strong>Access:</strong> in other words, we tell you what information we have about you.<br/>
				· <strong>Rectification:</strong> we correct the information we hold about you if it is incorrect.<br/>
				· <strong>Cancellation or deletion:</strong> we delete your data when they are no longer needed or when we are processing them because you have given us your consent.<br/>
				· <strong>Opposition:</strong> when the processing of your data is based on a legitimate interest.<br/>
				· <strong>Limitation of processing:</strong> for example, where you have challenged the accuracy of the data themselves.<br/>
				· <strong>Portability:</strong> by requesting that we communicate your data to a third party in a structured, commonly used, machine-readable format, where possible, or to you.<br/>
				· <strong>The right not to be subject to automated decisions:</strong> that we do not base a decision that has legal effect on you or significantly affects you on automated processing, with some exceptions.<br/>
				You can also file a claim with the Spanish Data Protection Agency (AEPD) at <a href="https://www.aepd.es/" rel="noreferrer" target="_blank">www.aepd.es</a>.
				</p>

				<p className="text-legal">
				<strong>7. HOW CAN I EXERCISE YOUR RIGHTS?</strong><br/>
				You can exercise your rights through your private portal, by writing an email to <a href="mailto:privacy.trade@pagonxt.com">privacy.trade@pagonxt.com</a> or by contacting us through any of our channels, indicating that this is a request to exercise your rights of GDPR. Remember if we need to confirm your identity we may ask you for some kind of documentation about it.
				</p>

				<p className="text-legal">
				<strong>8. WHAT SECURITY MEASURES DO WE APPLY?</strong><br/>
				At PagoNxt we are committed to security, and we apply technical and organizational measures aimed at guaranteeing the integrity, unavailability and confidentiality of your data.For example, all persons who can access personal information are subject to a contractual obligation of confidentiality, we use Data Lost Prevention (DLP) measures, encryption of information in transit and at rest, access control, establishment of secure passwords, etc.
				</p>

				<p className="text-legal">
				<strong>9. OTHER IMPORTANT INFORMATION <br /><br /> 9.1 ON THE COMMUNICATION OF PERSONAL INFORMATION OF A THIRD PARTY</strong><br/>
				As we have already explained in some cases the personal information, we collect is not received directly from you as a data subject, but rather from another person, that represents or provides services for our Client is the one that communicates to us that information or it is information that we access in the framework of the provision of our services. <br />
				If you are that person, you declare that you have sufficient authorization both to act on behalf of our Client and to communicate such personal information that, in addition, you guarantee that it is true, accurate, complete and up to date. In addition, on behalf of the Client you will inform the interested party of the communication and indicate that you can review this document on our website or, if applicable, show a physical copy of this document. <br /><br />
				<strong>9.2. ABOUT CHANGES TO THIS POLICY AND INQUIRIES</strong> If it is necessary for us to change the content of this privacy policy we will inform you of the relevant aspects indicating the date from which they will be applicable. We will also publish it on our website.<br/>
				</p>

				<p className="text-legal">
				<strong>10. PRIVACY NOTICE CANDIDATES<br/>
				10.1. ‍Purpose of the processing and legal basis <br /></strong> <br />

				<div className="w-embed">
				<table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
					<tbody>
					<tr>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#ffffff', fontWeight: 'bold'}}></th>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>Management of the selection process</th>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Purpose</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>We will process your personal data to evaluate your suitability for the job you have requested and to manage the selection process.</li>
							<li>Linked to this processing and in the event that you turn out to be the candidat@ selected, we may carry out a series of pre-hiring checks that may include: <br />
								<ul>
									<li>
										We request information related to your working life issued by official bodies in order to carry out the necessary checks before hiring.
									</li>
									<li>
										We carry out an analysis to verify the reliability, integrity and honesty of the canditat@ and to be able to comply with the legal requirements that may apply to us and with the internal policies of the PagoNxt Group.
									</li>
								</ul>
							</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Lawfulness</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>The legal basis for this processing is the execution of the contract or the application of pre-contractual measures.</li>
							<li>If we ask you for the working life issued by official bodies, the processing will be based on our legitimate interest in verifying the truthfulness and experience that you reflect in your request.</li>
							<li>The legal basis for integrity analysis, reliability and honesty of the candidat@ will be the legitimate interest of PagoNxt that is specified in that PagoNxt for the type of activity it carries out and for the fact of being part of a financial group must guarantee that the people who occupy certain positions and perform certain functions are the ones suitable considering the risks associated with these functions, this being expected by the candidates, without imbalancing their rights.</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Types of data processed</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>Contact and identification data (name, surname, address, telephone numbers); date of birth, gender, work and educational history, and any other information contained in your CV.</li>
							<li>Data derived from participation in tests during the selection process (group dynamics, interviews, language tests, etc.).</li>
							<li>The legal basis for integrity analysis, reliability and honesty of the candidat@ will be the legitimate interest of PagoNxt that is specified in that PagoNxt for the type of activity it carries out and for the fact of being part of a financial group must guarantee that the people who occupy certain positions and perform certain functions are the ones suitable considering the risks associated with these functions, this being expected by the candidates, without imbalancing their rights.</li>
							<li>Working life.</li>
							<li>Data publicly communicated by you in your social media profiles.</li>
						</ul>
						</td>
					</tr>
					</tbody>
				</table>
				</div> <br />

				<div className="w-embed">
				<table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
					<tbody>
					<tr>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#ffffff', fontWeight: 'bold'}}></th>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>Resolution and management of consultations</th>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Purpose</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>Answer the queries you can make during the selection process and after it.</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Lawfulness</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>Our legitimate interest in answering any questions that may arise during the selection process or subsequently.</li>
							
						</ul>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Types of data processed</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>Identification and contact data (name, surname, email, telephone).</li>
							<li>Any data that may be included in the consultation.</li>
						</ul>
						</td>
					</tr>
					</tbody>
				</table>
				</div> <br />

				<div className="w-embed">
				<table style={{width: '100%', borderCollapse: 'collapse', border: '2px solid #e0e0e0'}}>
					<tbody>
					<tr>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#ffffff', fontWeight: 'bold'}}></th>
						<th style={{padding: "12px 15px", border: '1px solid #e0e0e0', textAlign: 'left', backgroundColor: '#f4f4f4', fontWeight: 'bold'}}>Candidate Examination</th>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Purpose</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>For the purpose of confirming the suitability of the job seeker, the data of the candidate selected for checking that do not appear on the official sanctions lists (in particular in accordance with EU Regulations 2580/2001, 881/2002, 753/2011) are processed.</li>
							<li>This verification is done through the "Worldcheck" tool before making a firm offer to the selected candidate. For this purpose, personal data is verified only once.</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Lawfulness</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>Depending on the entity that carries out the processing, or the fulfillment of legal obligations or the legitimate interest in fulfilling the obligations of due diligence and avoiding the possible damages (including possible fines) that may arise from the hiring of sanctioned persons.</li>
							
						</ul>
						</td>
					</tr>
					<tr>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff', fontWeight: 'bold'}}>Types of data processed</td>
						<td style={{padding: "12px 15px", border: '1px solid #e0e0e0', backgroundColor: '#ffffff'}}>
						<ul>
							<li>Name and surname</li>
							<li>Date of birth</li>
							<li>Nationality</li>
						</ul>
						</td>
					</tr>
					</tbody>
				</table>
				</div> <br />


				<strong>10.2. Purpose of processing:</strong><br /> We can share your data with:<br/><br />
				<strong>1. With service providers</strong><br /> We may share your personal information with companies that provide services to us and with whom we have entered into a data processing agreement in accordance with applicable law.  They will process your data on our behalf and following our instructions.<br/>
				<strong>2. International data transfers</strong><br /> Your data is not transferred directly outside the EU.  However, we utilise the services of the company Workday, which provides 24/7 incident support. This could lead to a limited and specific access to your personal data from the United States in exceptional circumstances. It should be noted that the USA does not offer the same level of data protection as the EU. In these instances, we have implemented robust safeguards in line with data protection regulations and have put in place additional measures to ensure the security of your data. Should you require further information, please do not hesitate to contact the relevant DPO.<br/> <br />
				<strong>10.3. How long do we keep your data?</strong>‍We will process your personal data for the duration of the selection process and, where appropriate, for the time necessary to handle any enquiries that may arise. Once the process is complete, we will retain your information for a maximum period of one year in case there are future opportunities that you may be interested in, unless you indicate otherwise (right of objection). After the aforementioned period, unless you expressly authorise us to retain the information for a longer period, we will proceed to block the information and delete it after the expiry of the period of action (five years).<br/> <br />
				<strong>10.4. Exercise of rights</strong> ‍You can exercise your rights of access, rectification, erasure, limitation of processing, portability, opposition and the right not to be subject to automated individual decisions including profiling, sending an email to mailbox <a href="mailto:privacy.trade@pagonxt.com">privacy.trade@pagonxt.com</a>  as well as filing a claim with the Spanish Data Protection Agency at <a target="_blank" rel="noreferrer" href="https://www.aepd.es/">www.aepd.es</a> 
				</p>


			</div>
		</section>

	);
};

export default PrivacyNotice;
