import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { error, success } from "../components/utils/NotificationsContainer";

export const FETCH_PENDING = "PENDING";
export const FETCH_IN_PROGRESS = "IN_PROGRESS";
export const FETCH_OK = "OK";
export const FETCH_KO = "KO";
export const FETCH_ERROR = "ERROR";

export function isPending(state) {
	return state === FETCH_PENDING;
}

export function isFetchDone(state) {
	return state !== FETCH_PENDING && state !== FETCH_IN_PROGRESS;
}

export function allAreDone(...statuses) {
	return statuses.every(isFetchDone);
}

export function isAnyLoading(...statuses) {
	return !!statuses.filter((it) => it === "IN_PROGRESS").length;
}

export function useApiCall(
	apiCall,
	{
		executeImmediately = true,
		notify = true,
		notifySuccess,
		onSuccess,
		onFailure,
	} = {}
) {
	const [fetchState, setFetchState] = useState(FETCH_PENDING);
	const [response, setResponse] = useState();
	const { t } = useTranslation("global");

	const reset = () => {
		setFetchState(FETCH_PENDING);
		setResponse();
	};

	const notifyMessage =
		typeof notifySuccess === "string"
			? notifySuccess
			: t("app.notifications.success");

	// Memoiza fetchCall para evitar que cambie en cada renderizado
	const fetchCall = useCallback(
		(data) => {
			setFetchState(FETCH_IN_PROGRESS);
			return apiCall(data)
				.then((res) => {
					if (res.ok) {
						const responseContentType =
							res.headers.get("Content-Type");
						const isJson =
							responseContentType &&
							responseContentType?.indexOf("application/json") !==
								-1;

						if (res.statusText === "No Content" || !isJson) {
							setFetchState(FETCH_OK);
							notifySuccess && success(notifyMessage);
							onSuccess?.call(null, res);
							return null;
						} else {
							return res.json();
						}
					} else {
						setFetchState(FETCH_KO);
						onFailure?.call();
					}
				})
				.then((res) => {
					if (res) {
						setResponse(res);
						notifySuccess && success(notifyMessage);
						setFetchState(FETCH_OK);
						onSuccess?.call(null, res);
					}
				})
				.catch((errors) => {
					notify && error(t("app.notifications.error"));
					onFailure?.call();
					setFetchState(FETCH_ERROR);
				});
		},
		[apiCall, notifyMessage, notifySuccess, onSuccess, onFailure, t, notify]
	);

	useEffect(() => {
		if (executeImmediately) {
			fetchCall();
		}
	}, [executeImmediately, fetchCall]);

	return [response, fetchState, fetchCall, reset];
}
