import React from 'react';

const LogoutPage = () => {

  // const { isAuth, sessionId, setLoggedOut } = useGlobalVar();

return (
    <>
      Logout
    </>
  );
};

export default LogoutPage;