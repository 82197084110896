import {useForm} from "react-hook-form";
import InputFormComponent from "../../form/InputFormComponent";
import {emailPattern} from "../../utils/Patterns";
import {useLocation} from "react-router-dom";
import {
  FETCH_IN_PROGRESS,
  FETCH_OK,
  useApiCall
} from "../../../hooks/useApiCall";
import {signupRequest} from "../../../api/api";
import {useTranslation} from "react-i18next";
import {organizationTypes} from "./OrganizationTypes";

const SignUpRequestPage = () => {
  const location = useLocation();
  const {t} = useTranslation("global");

  const ORGANIZATION_TYPES_AS_SELECT_OPTIONS = organizationTypes.map(
      (type) => ({
        value: type,
        label: t("app.form.organization." + type)
      }))

  const [, fetchStatus, signupRequestCall] = useApiCall(
      (data) => signupRequest(data), {
        executeImmediately: false,
      });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSubmit = (data) => signupRequestCall(data);

  const defaultFormFieldProperties = {
    errors: errors,
    register: register,
    required: true,
    defaultValue: "",
  };

  return (
      <>
        <div className="form-content">
          {fetchStatus === FETCH_OK && (<div>
                <h2>Congratulations!</h2>
                <p>We've just sent you an email with instructions to create your
                  password.
                  Head over to your inbox, follow the link provided, and set up
                  your
                  password
                  to get started.
                </p>
                <p><strong>Welcome to our community!</strong></p>
              </div>
          )}

          {fetchStatus !== FETCH_OK && (<div>
                <h2>Welcome!</h2>
                <p>
                  Discover the power of seamless financial management with
                  PagoNxt,
                  where transactions are effortless and security is paramount.
                </p>
                <div className="form-content">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="input-wrapper">
                      < InputFormComponent
                          {...defaultFormFieldProperties}
                          inputType="email"
                          placeholder="Email"
                          className="dv-user"
                          field="email"
                          defaultValue={location.state}
                          required={true}
                          pattern={emailPattern}
                          patternErrorFieldPrefix={"app.form.field."}
                      />
                    </div>
                    <div className="input-wrapper">
                      < InputFormComponent
                          {...defaultFormFieldProperties}
                          inputType="select"
                          field="organizationType"
                          selectOptions={ORGANIZATION_TYPES_AS_SELECT_OPTIONS}
                          required={true}
                      />
                    </div>
                    <div className="buttons">
                      <button
                          type="submit"
                          disabled={fetchStatus === FETCH_IN_PROGRESS}
                      >
                        {"Continue"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
          )}
        </div>
      </>

  );
};

export default SignUpRequestPage;


