import React from "react";

const ModalComponent = ({ show, onClose, children, size, hideCross }) => {
	
	if (!show) {
		return null;
	}

	return (
		<div className="modal-component__backdrop">
			<div className={`modal-component ${size}`}>
				{
					hideCross ? null : 
					<button className="close-button" onClick={onClose}>
						<div className="cross"></div>
					</button>
				}
				
				<div className="modal-component__content">{children}</div>
			</div>
		</div>
	);
};

export default ModalComponent;
