export const SIGNUP_REQUEST = "/signup-request";
export const SIGNUP = "/signup/:token";
export const LOGIN = "/login";
export const ADD_PASSWORD = "/add-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const REGISTER = "/register-page";
export const HOME = "/home";
export const INDEX = "/";
export const RESET_PASSWORD = "/reset";

export const FORM_REQUEST_SUBSCRIPTION = "/request-subscription";
export const USER_SETTINGS = "/user-settings";
export const MANAGE_USERS = "/manage-team-users";
export const ADD_USERS = "/add-user-team";
export const EDIT_USERS_TEAM = "/edit-user-team";
export const REQUESTS_MANAGEMENT = "/requests-management";
export const REQUEST_DETAIL = "/request-detail";
export const REQUEST_MODIFICATION = "/request-modification";
export const REQUEST_MODIFICATION_ADMIN = "/request-modification-admin";
export const MANAGE_ORGANIZATIONS = "/manage-organizations";
export const CREATE_ORGANIZATION = "/create-organization";
export const DETAILS_ORGANIZATION = "/details-organization";
export const EDIT_ORGANIZATION = "/edit-organization";
export const EDIT_USERS = "/edit-users";
export const AUDIT_DETAILS = "/audit-detail";
export const CREATE_ADMINISTRATOR = "/create-administrator";
export const ADMIN_USER_SETTINGS = "/admin-user-settings";

// URLS HEADER
export const REQUEST = "/requests-management";
export const ORGANIZATIONS = "/manage-organizations";
export const USERS = "/users-managements";
export const AUDIT = "/audit-management";
export const SETTINGS = "/settings-management";
export const CONTACT = "/";
export const BECOMECUSTOMER = "/";

// ESTERNAL URLS
export const DEVHUB = "https://developer.pagonxtpayments.com/";
export const PRIVACY_POLICY =
	"https://developer.pagonxtpayments.com/v1/docs/privacy-notice";
export const SANDBOXDOCUMENTATION =
	"https://developer.pagonxtpayments.com/docs/sandbox-environment";
export const PREDOCUMENTATION =
	"https://developer.pagonxtpayments.com/docs/subscribing-to-an-api";
export const LIVEDOCUMENTATION =
	"https://developer.pagonxtpayments.com/docs/subscribing-to-an-api";

// LEGAL URLS
export const PRIVACY_NOTICE = "/privacy-notice";
export const LEGAL_NOTICE = "/legal-notice";
export const COOKIE_POLICY = "/cookie-policy";
export const CODE_CONDUCT = "/code-of-conduct-and-canal-abierto";
