import React, { useEffect, useState } from "react";

import { WITHOUT, PENDING, SUBSCRIBED, UNSUBCRIPTION } from "../../utils/GlobalConsts";

const ApiSubscriptionStateComponent = ({ state }) => {
	const [textState, setTextState] = useState("Without subscription");

	useEffect(() => {
		if (state === SUBSCRIBED) setTextState("Subscribed");
		else if (state === PENDING) setTextState("Pending");
		else if (state === UNSUBCRIPTION) setTextState("Unsubscription");
		else if (state === WITHOUT) setTextState("Without subscription");
	}, [state]);

	return (
		<>
			<div
				className={`api-subscription-state ${
					state ? state.toLowerCase() : ""
				}`}
			>
				<div className="api-subscription-state__circle"></div>
				<div className="api-subscription-state__text">{textState}</div>
			</div>
		</>
	);
};

export default ApiSubscriptionStateComponent;
