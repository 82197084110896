import React, { useState, useEffect } from "react";
import { useGlobalVar } from "../../utils/GlobalContext";

import {
	error as showError,
	success as showSuccess,
} from "../../utils/NotificationsContainer";

import DividerComponent from "../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import InputComponent from "../../atoms/input-component/InputComponent";
import { getOrganizationsTypes } from "../../../services/ApiOrganization";

import { sendBecomeCustomerRequest } from "../../../services/ApiUsers";

// PLACEHOLDERS PARA INPUTS TEXT Y TEXTAREAS
const placeholders = {
	firstName: "Insert your phone number name",
	lastName: "Insert your last name",
	email: "Insert your Email",
	phone: "Insert your phone number with the country code",
	companyName: "Insert your company name",
	nif: "Insert your fiscal identity number",
};

const FormBecomeCustomer = ({ onClose }) => {
	const { accessToken, userEmail, nameOrganization, userData, fetchBecomeCustomerSended } =
		useGlobalVar();

	const [loading, setLoading] = useState(true);
	const [organizationType, setOrganizationType] = useState([]);

	const [formData, setFormData] = useState({
		firstName: userData.firstname,
		lastName: userData.lastname,
		email: userEmail,
		phone: userData.phone,
		companyName: nameOrganization || "",
		nif: userData.cif || userData.organizationCif,
		organizationCode: "",
		country: "",
		countryIso: "",
		alreadyCustomer: false,
		nameOrganization: nameOrganization || "",
		mail: userEmail,
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				setOrganizationType(await getOrganizationsTypes(accessToken));
			} catch (error) {
				throw new Error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [accessToken]);

	const [files, setFiles] = useState([]);

	const handleInputChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setFormData({
					...formData,
					[name]: value,
				});
			}
		} else {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value,
			}));
		}
	};

	const handleChange = (e) => {
		const { name, type, checked, value } = e.target;

		if (type === "checkbox" && name === "alreadyCustomer") {
			setFormData({
				...formData,
				[name]: checked, // Actualiza `alreadyCustomer` con el valor del checkbox
			});
		} else if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				setFormData({
					...formData,
					[name]: value,
				});
			}
		} else if (name === "countryIso") {
			const selectedCountryName =
				e.target.options[e.target.selectedIndex].text;
			setFormData({
				...formData,
				countryIso: value,
				country: selectedCountryName,
			});
		} else {
			setFormData((prevFormData) => {
				const keys = name.split(".");
				let newFormData = { ...prevFormData };
				let nested = newFormData;

				for (let i = 0; i < keys.length - 1; i++) {
					nested = nested[keys[i]];
				}

				nested[keys[keys.length - 1]] = value;

				return newFormData;
			});
		}
	};

	const handleSelector = (name, value) => {
		setFormData((prevUpdateData) => {
			if (name in prevUpdateData) {
				return { ...prevUpdateData, [name]: value };
			}
			return prevUpdateData;
		});
	};

	const handleRemoveFile = (index) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		const validFiles = selectedFiles.filter(
			(file) => file.type === "application/pdf"
		);

		setFiles((prevFiles) => [...prevFiles, ...validFiles]);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
	
		// Validar el formulario
		if (formData.nif === "") {
			showError("Please, add your VAT number");
			return;
		} else if (files.length === 0) {
			showError("Please, attach your documents");
			return;
		}
	
		try {
			// Llamar a la función desde apisubscription.api
			await sendBecomeCustomerRequest(formData, files, accessToken);
	
			fetchBecomeCustomerSended();
			showSuccess("Your upgrade plan request has been sent successfully");
			onClose();
		} catch (error) {
			if (error.response?.data?.statusCode === 429) {
				showError("Request Upgrade plan already sent");
			} else {
				showError("There was a problem, please check input fields");
			}
			console.error("Error al enviar el formulario:", error);
		}
	};

	const organizationOptions = organizationType.map((org) => ({
		label: org.description,
		value: org.codOrganization,
	}));

	if (loading) return <div>Loading...</div>;

	return (
		<>
			<div className="form-become-customer">
				<div className="form-become-customer__content">
					<div className="form-become-customer__content__header">
						<div className="form-become-customer__content__header__title">
							Upgrade your plan
						</div>
						<div className="form-become-customer__content__header__description">
							Boost your business by upgrading your PagoNxt
							Payments plan for enhanced features and continued
							growth
						</div>
					</div>

					<form onSubmit={handleSubmit}>
						<div className="form-become-customer__content__form">
							<div className="form-become-customer__content__form__contact">
								<div className="form-become-customer__content__form__contact__title form-title">
									Contact information
								</div>
								<div className="form-become-customer__content__form__contact__input">
									<div className="input-content">
										<div className="input-content__title">
											<span>First Name</span>
										</div>
										<label>
											<input
												type="text"
												name="firstName"
												value={formData.firstName}
												placeholder={
													placeholders.firstName
												}
												autoComplete="off"
												onChange={handleChange}
												disabled
											/>
										</label>
									</div>
								</div>
								<div className="form-become-customer__content__form__contact__input">
									<div className="input-content">
										<div className="input-content__title">
											<span>Last Name</span>
										</div>
										<label>
											<input
												type="text"
												name="lastName"
												value={formData.lastName}
												placeholder={
													placeholders.lastName
												}
												autoComplete="off"
												onChange={handleChange}
												disabled
											/>
										</label>
									</div>
								</div>
								<div className="form-become-customer__content__form__contact__input">
									<div className="input-content">
										<div className="input-content__title">
											<span>Email</span>
										</div>
										<label>
											<input
												type="text"
												name="email"
												value={formData.email}
												placeholder={placeholders.email}
												autoComplete="off"
												onChange={handleChange}
												disabled
											/>
										</label>
									</div>
								</div>
								<div className="form-become-customer__content__form__contact__input">
									<InputComponent
										type="tel"
										name="phone"
										label="Phone and country code"
										value={formData.phone}
										placeholder={placeholders.phone}
										onChange={handleInputChange}
									/>
									{/* {
										<div className="input-content">
											<div className="input-content__title">
												<span>Phone</span>
											</div>
											<label>
												<input
													type="tel"
													name="phone"
													value={formData.phone}
													placeholder={
														placeholders.phone
													}
													autoComplete="off"
													onChange={handleChange}
												/>
											</label>
										</div>
									} */}
								</div>
							</div>
							<div className="form-become-customer__content__form__company">
								<div className="form-become-customer__content__form__company__title form-title">
									Company information
								</div>
								<div className="form-become-customer__content__form__company__input">
									<div className="input-content">
										<div className="input-content__title">
											<span>Company name</span>
										</div>
										<label>
											<input
												type="text"
												name="companyName"
												value={formData.companyName}
												placeholder={
													placeholders.companyName
												}
												autoComplete="off"
												onChange={handleChange}
											/>
										</label>
									</div>
								</div>
								<div className="form-become-customer__content__form__contact__input">
									<div className="input-content">
										<div className="input-content__title">
											<span>VAT number</span>
										</div>
										<label>
											<input
												type="text"
												name="nif"
												value={formData.nif}
												placeholder={placeholders.nif}
												autoComplete="off"
												onChange={handleChange}
											/>
										</label>
									</div>
								</div>

								<div className="form-become-customer__content__form__contact__input">
									<InputComponent
										type="select"
										name="organizationCode"
										label="Type of Organization"
										options={organizationOptions}
										value={formData.organizationCode}
										onChange={handleSelector}
										disabled={false}
										placeholder="Select type"
									/>
								</div>
								<div className="form-become-customer__content__form__contact__input">
									<div className="input-content">
										<div className="input-content__selector">
											<label htmlFor="countryIso">
												Country
											</label>
											<select
												name="countryIso"
												id="countryIso"
												onChange={handleChange}
												value={formData.countryIso}
											>
												<option value="" disabled>
													Location
												</option>
												<option value="AF">
													Afghanistan
												</option>
												<option value="AL">
													Albania
												</option>
												<option value="DZ">
													Algeria
												</option>
												<option value="AS">
													American Samoa
												</option>
												<option value="AD">
													Andorra
												</option>
												<option value="AG">
													Angola
												</option>
												<option value="AI">
													Anguilla
												</option>
												<option value="AG">
													Antigua &amp; Barbuda
												</option>
												<option value="AR">
													Argentina
												</option>
												<option value="AA">
													Armenia
												</option>
												<option value="AW">
													Aruba
												</option>
												<option value="AU">
													Australia
												</option>
												<option value="AT">
													Austria
												</option>
												<option value="AZ">
													Azerbaijan
												</option>
												<option value="BS">
													Bahamas
												</option>
												<option value="BH">
													Bahrain
												</option>
												<option value="BD">
													Bangladesh
												</option>
												<option value="BB">
													Barbados
												</option>
												<option value="BY">
													Belarus
												</option>
												<option value="BE">
													Belgium
												</option>
												<option value="BZ">
													Belize
												</option>
												<option value="BJ">
													Benin
												</option>
												<option value="BM">
													Bermuda
												</option>
												<option value="BT">
													Bhutan
												</option>
												<option value="BO">
													Bolivia
												</option>
												<option value="BL">
													Bonaire
												</option>
												<option value="BA">
													Bosnia &amp; Herzegovina
												</option>
												<option value="BW">
													Botswana
												</option>
												<option value="BR">
													Brazil
												</option>
												<option value="BC">
													British Indian Ocean Ter
												</option>
												<option value="BN">
													Brunei
												</option>
												<option value="BG">
													Bulgaria
												</option>
												<option value="BF">
													Burkina Faso
												</option>
												<option value="BI">
													Burundi
												</option>
												<option value="KH">
													Cambodia
												</option>
												<option value="CM">
													Cameroon
												</option>
												<option value="CA">
													Canada
												</option>
												<option value="IC">
													Canary Islands
												</option>
												<option value="CV">
													Cape Verde
												</option>
												<option value="KY">
													Cayman Islands
												</option>
												<option value="CF">
													Central African Republic
												</option>
												<option value="TD">Chad</option>
												<option value="CD">
													Channel Islands
												</option>
												<option value="CL">
													Chile
												</option>
												<option value="CN">
													China
												</option>
												<option value="CI">
													Christmas Island
												</option>
												<option value="CS">
													Cocos Island
												</option>
												<option value="CO">
													Colombia
												</option>
												<option value="CC">
													Comoros
												</option>
												<option value="CG">
													Congo
												</option>
												<option value="CK">
													Cook Islands
												</option>
												<option value="CR">
													Costa Rica
												</option>
												<option value="CT">
													Cote D'Ivoire
												</option>
												<option value="HR">
													Croatia
												</option>
												<option value="CU">Cuba</option>
												<option value="CB">
													Curacao
												</option>
												<option value="CY">
													Cyprus
												</option>
												<option value="CZ">
													Czech Republic
												</option>
												<option value="DK">
													Denmark
												</option>
												<option value="DJ">
													Djibouti
												</option>
												<option value="DM">
													Dominica
												</option>
												<option value="DO">
													Dominican Republic
												</option>
												<option value="TM">
													East Timor
												</option>
												<option value="EC">
													Ecuador
												</option>
												<option value="EG">
													Egypt
												</option>
												<option value="SV">
													El Salvador
												</option>
												<option value="GQ">
													Equatorial Guinea
												</option>
												<option value="ER">
													Eritrea
												</option>
												<option value="EE">
													Estonia
												</option>
												<option value="ET">
													Ethiopia
												</option>
												<option value="FA">
													Falkland Islands
												</option>
												<option value="FO">
													Faroe Islands
												</option>
												<option value="FJ">Fiji</option>
												<option value="FI">
													Finland
												</option>
												<option value="FR">
													France
												</option>
												<option value="GF">
													French Guiana
												</option>
												<option value="PF">
													French Polynesia
												</option>
												<option value="FS">
													French Southern Ter
												</option>
												<option value="GA">
													Gabon
												</option>
												<option value="GM">
													Gambia
												</option>
												<option value="GE">
													Georgia
												</option>
												<option value="DE">
													Germany
												</option>
												<option value="GH">
													Ghana
												</option>
												<option value="GI">
													Gibraltar
												</option>
												<option value="GB">
													Great Britain
												</option>
												<option value="GR">
													Greece
												</option>
												<option value="GL">
													Greenland
												</option>
												<option value="GD">
													Grenada
												</option>
												<option value="GP">
													Guadeloupe
												</option>
												<option value="GU">Guam</option>
												<option value="GT">
													Guatemala
												</option>
												<option value="GN">
													Guinea
												</option>
												<option value="GY">
													Guyana
												</option>
												<option value="HT">
													Haiti
												</option>
												<option value="HW">
													Hawaii
												</option>
												<option value="HN">
													Honduras
												</option>
												<option value="HK">
													Hong Kong
												</option>
												<option value="HU">
													Hungary
												</option>
												<option value="IS">
													Iceland
												</option>
												<option value="IN">
													India
												</option>
												<option value="ID">
													Indonesia
												</option>
												<option value="IA">Iran</option>
												<option value="IQ">Iraq</option>
												<option value="IR">
													Ireland
												</option>
												<option value="IM">
													Isle of Man
												</option>
												<option value="IL">
													Israel
												</option>
												<option value="IT">
													Italy
												</option>
												<option value="JM">
													Jamaica
												</option>
												<option value="JP">
													Japan
												</option>
												<option value="JO">
													Jordan
												</option>
												<option value="KZ">
													Kazakhstan
												</option>
												<option value="KE">
													Kenya
												</option>
												<option value="KI">
													Kiribati
												</option>
												<option value="NK">
													Korea North
												</option>
												<option value="KS">
													Korea South
												</option>
												<option value="KW">
													Kuwait
												</option>
												<option value="KG">
													Kyrgyzstan
												</option>
												<option value="LA">Laos</option>
												<option value="LV">
													Latvia
												</option>
												<option value="LB">
													Lebanon
												</option>
												<option value="LS">
													Lesotho
												</option>
												<option value="LR">
													Liberia
												</option>
												<option value="LY">
													Libya
												</option>
												<option value="LI">
													Liechtenstein
												</option>
												<option value="LT">
													Lithuania
												</option>
												<option value="LU">
													Luxembourg
												</option>
												<option value="MO">
													Macau
												</option>
												<option value="MK">
													Macedonia
												</option>
												<option value="MG">
													Madagascar
												</option>
												<option value="MY">
													Malaysia
												</option>
												<option value="MW">
													Malawi
												</option>
												<option value="MV">
													Maldives
												</option>
												<option value="ML">Mali</option>
												<option value="MT">
													Malta
												</option>
												<option value="MH">
													Marshall Islands
												</option>
												<option value="MQ">
													Martinique
												</option>
												<option value="MR">
													Mauritania
												</option>
												<option value="MU">
													Mauritius
												</option>
												<option value="ME">
													Mayotte
												</option>
												<option value="MX">
													Mexico
												</option>
												<option value="MI">
													Midway Islands
												</option>
												<option value="MD">
													Moldova
												</option>
												<option value="MC">
													Monaco
												</option>
												<option value="MN">
													Mongolia
												</option>
												<option value="MS">
													Montserrat
												</option>
												<option value="MA">
													Morocco
												</option>
												<option value="MZ">
													Mozambique
												</option>
												<option value="MM">
													Myanmar
												</option>
												<option value="NA">
													Nambia
												</option>
												<option value="NU">
													Nauru
												</option>
												<option value="NP">
													Nepal
												</option>
												<option value="AN">
													Netherland Antilles
												</option>
												<option value="NL">
													Netherlands (Holland,
													Europe)
												</option>
												<option value="NV">
													Nevis
												</option>
												<option value="NC">
													New Caledonia
												</option>
												<option value="NZ">
													New Zealand
												</option>
												<option value="NI">
													Nicaragua
												</option>
												<option value="NE">
													Niger
												</option>
												<option value="NG">
													Nigeria
												</option>
												<option value="NW">Niue</option>
												<option value="NF">
													Norfolk Island
												</option>
												<option value="NO">
													Norway
												</option>
												<option value="OM">Oman</option>
												<option value="PK">
													Pakistan
												</option>
												<option value="PW">
													Palau Island
												</option>
												<option value="PS">
													Palestine
												</option>
												<option value="PA">
													Panama
												</option>
												<option value="PG">
													Papua New Guinea
												</option>
												<option value="PY">
													Paraguay
												</option>
												<option value="PE">Peru</option>
												<option value="PH">
													Philippines
												</option>
												<option value="PO">
													Pitcairn Island
												</option>
												<option value="PL">
													Poland
												</option>
												<option value="PT">
													Portugal
												</option>
												<option value="PR">
													Puerto Rico
												</option>
												<option value="QA">
													Qatar
												</option>
												<option value="ME">
													Republic of Montenegro
												</option>
												<option value="RS">
													Republic of Serbia
												</option>
												<option value="RE">
													Reunion
												</option>
												<option value="RO">
													Romania
												</option>
												<option value="RU">
													Russia
												</option>
												<option value="RW">
													Rwanda
												</option>
												<option value="NT">
													St Barthelemy
												</option>
												<option value="EU">
													St Eustatius
												</option>
												<option value="HE">
													St Helena
												</option>
												<option value="KN">
													St Kitts-Nevis
												</option>
												<option value="LC">
													St Lucia
												</option>
												<option value="MB">
													St Maarten
												</option>
												<option value="PM">
													St Pierre &amp; Miquelon
												</option>
												<option value="VC">
													St Vincent &amp; Grenadines
												</option>
												<option value="SP">
													Saipan
												</option>
												<option value="SO">
													Samoa
												</option>
												<option value="AS">
													Samoa American
												</option>
												<option value="SM">
													San Marino
												</option>
												<option value="ST">
													Sao Tome &amp; Principe
												</option>
												<option value="SA">
													Saudi Arabia
												</option>
												<option value="SN">
													Senegal
												</option>
												<option value="RS">
													Serbia
												</option>
												<option value="SC">
													Seychelles
												</option>
												<option value="SL">
													Sierra Leone
												</option>
												<option value="SG">
													Singapore
												</option>
												<option value="SK">
													Slovakia
												</option>
												<option value="SI">
													Slovenia
												</option>
												<option value="SB">
													Solomon Islands
												</option>
												<option value="OI">
													Somalia
												</option>
												<option value="ZA">
													South Africa
												</option>
												<option value="ES">
													Spain
												</option>
												<option value="LK">
													Sri Lanka
												</option>
												<option value="SD">
													Sudan
												</option>
												<option value="SR">
													Suriname
												</option>
												<option value="SZ">
													Swaziland
												</option>
												<option value="SE">
													Sweden
												</option>
												<option value="CH">
													Switzerland
												</option>
												<option value="SY">
													Syria
												</option>
												<option value="TA">
													Tahiti
												</option>
												<option value="TW">
													Taiwan
												</option>
												<option value="TJ">
													Tajikistan
												</option>
												<option value="TZ">
													Tanzania
												</option>
												<option value="TH">
													Thailand
												</option>
												<option value="TG">Togo</option>
												<option value="TK">
													Tokelau
												</option>
												<option value="TO">
													Tonga
												</option>
												<option value="TT">
													Trinidad &amp; Tobago
												</option>
												<option value="TN">
													Tunisia
												</option>
												<option value="TR">
													Turkey
												</option>
												<option value="TU">
													Turkmenistan
												</option>
												<option value="TC">
													Turks &amp; Caicos Is
												</option>
												<option value="TV">
													Tuvalu
												</option>
												<option value="UG">
													Uganda
												</option>
												<option value="UA">
													Ukraine
												</option>
												<option value="AE">
													United Arab Emirates
												</option>
												<option value="GB">
													United Kingdom
												</option>
												<option value="US">
													United States of America
												</option>
												<option value="UY">
													Uruguay
												</option>
												<option value="UZ">
													Uzbekistan
												</option>
												<option value="VU">
													Vanuatu
												</option>
												<option value="VS">
													Vatican City State
												</option>
												<option value="VE">
													Venezuela
												</option>
												<option value="VN">
													Vietnam
												</option>
												<option value="VB">
													Virgin Islands (Brit)
												</option>
												<option value="VA">
													Virgin Islands (USA)
												</option>
												<option value="WK">
													Wake Island
												</option>
												<option value="WF">
													Wallis &amp; Futana Is
												</option>
												<option value="YE">
													Yemen
												</option>
												<option value="ZR">
													Zaire
												</option>
												<option value="ZM">
													Zambia
												</option>
												<option value="ZW">
													Zimbabwe
												</option>
											</select>
										</div>
									</div>
								</div>
								<div className="form-become-customer__content__form__contact__input">
									<div className="input-content">
										<div className="input-content__checkbox">
											<label>
												<input
													type="checkbox"
													name="alreadyCustomer"
													onChange={handleChange}
													checked={
														formData.alreadyCustomer
													}
												/>
												<div className="checkmark">
													<div className="check"></div>
												</div>
												<p>
													My company is already a
													PagoNxt Payments client
												</p>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<DividerComponent />

						<div className="form-become-customer__content__docs">
							<div className="form-become-customer__content__docs__title">
								To process your request we need you to attach
								the following documents:
							</div>
							<div className="form-become-customer__content__docs__description">
								<span>• Non-client</span>: Company incorporation
								deed, Ownership title certificate <br />
								<span>• Already client</span>: Contract with
								PagoNxt Payments
							</div>
							<div className="form-become-customer__content__docs__bottom">
								<div className="form-become-customer__content__docs__bottom__add-button">
									<div className="input-content">
										<div className="input-content__files">
											<label htmlFor="file">
												<span>
													Attach your documents
												</span>{" "}
												(only PDF)
											</label>
											<input
												type="file"
												id="file"
												name="file"
												onChange={handleFileChange}
												accept=".pdf"
											/>
										</div>
									</div>
								</div>
								{files.length > 0 ? (
									<div className="form-become-customer__content__docs__bottom__files">
										<ul>
											{files.map((file, index) => (
												<li key={index}>
													{file.name}{" "}
													<span
														onClick={() =>
															handleRemoveFile(
																index
															)
														}
														className="cross"
													></span>
												</li>
											))}
										</ul>
									</div>
								) : null}
							</div>
						</div>

						<DividerComponent />

						<div className="form-become-customer__content__submit">
							<div className="form-support__form__submit__button">
								<ButtonComponent type="submit" text={"Send"} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default FormBecomeCustomer;
