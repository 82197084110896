import React, { useState } from "react";

import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import CreateNewAPI from "../admin-area-new-api/CreateNewAPI";
import EditAPI from "../admin-area-api-details/EditAPI";
import APITable from "../admin-area-api-table/APITable";

const APIsManagement = () => {
	const [showFormAPI, setShowFormAPI] = useState(false);
	const [showAPIDetails, setShowAPIDetails] = useState(null);

	const handleFormAPI = () => {
		setShowFormAPI(true);
	};

	const handleAPI = (API) => {
		setShowAPIDetails(API);
	};

	return (
		<div className="team-management__form">
			{showFormAPI ? (
				<CreateNewAPI />
			) : showAPIDetails ? (
				<div>
					<EditAPI API={showAPIDetails} />
				</div>
			) : (
				<>
					<APITable onRowClick={handleAPI} />

					<ButtonComponent
						text={"Add new API"}
						onClick={handleFormAPI}
					/>
				</>
			)}
		</div>
	);
};

export default APIsManagement;
