import { useState } from "react";
import InputFormComponent from "../form/InputFormComponent";

function PasswordInput({
  defaultFormFieldProperties,
  name,
  placeholder,
  validatePassword,
  pattern,
  patternErrorMessage,
  onChange,
}) {
  const [showPasswordInClearText, setShowPasswordInClearText] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPasswordInClearText(!showPasswordInClearText);
  };

  const [capsLockOn, setCapsLockOn] = useState(false);

  const handleKeyPress = (e) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLockOn(true);
    } else {
      setCapsLockOn(false);
    }
  };

  return (
    <>
      <div className="input-password">
        <InputFormComponent
          {...defaultFormFieldProperties}
          inputType={showPasswordInClearText ? "text" : "password"}
          placeholder={placeholder || "Contraseña"}
          field={name || "password"}
          validate={validatePassword}
          pattern={pattern}
          patternErrorMessage={patternErrorMessage}
          onChange={onChange}
          onKeyPress={handleKeyPress}
        />
        <span className="icon-eye" onClick={togglePasswordVisibility}></span>
      </div>
      {capsLockOn && <p>La tecla de bloqueo de mayúsculas está activada.</p>}
    </>
  );
}

export default PasswordInput;
