import axios from "axios";


const API_URL = process.env.REACT_APP_BACKEND_API_URL;


export const getRequestsList = async (accessToken) => {
	try {
		const response = await axios.get(`${API_URL}/api/v1/request/list`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `${accessToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const requestModification = async (
	nameOrganization,
	appName,
	accessToken
) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/subscriptions?nameOrganization=${nameOrganization}&nameSubscriptions=${appName}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};
