import React from "react";

import InputComponent from "../../../atoms/input-component/InputComponent";

const SupportAuditDetails = ({ jsonData }) => {

	// const [files, setFiles] = useState([]);

	// const downloadFile = (fileContent, fileName) => {
	// 	// Decodificar la cadena Base64
	// 	const byteCharacters = atob(fileContent);
	// 	const byteNumbers = new Array(byteCharacters.length);
	// 	for (let i = 0; i < byteCharacters.length; i++) {
	// 		byteNumbers[i] = byteCharacters.charCodeAt(i);
	// 	}
	// 	const byteArray = new Uint8Array(byteNumbers);

	// 	const blob = new Blob([byteArray], { type: "image/png" });

	// 	const link = document.createElement("a");
	// 	link.href = window.URL.createObjectURL(blob);
	// 	link.download = fileName;
	// 	link.click();
	// };

	return (
		<div className="audit-details__info-full">
			<h2 className="mg mg--b-4">Support request</h2>
			<p className="mg mg--b-2">Reason for the contact</p>
			<InputComponent
				type="textarea"
				name="supportRequest"
				value={jsonData?.contactReason || ""}
				rows={4}
				disabled={true}
				className={"input--full"}
			/>
			{/* <div>
				<p className="fw600 mg mg--b-2">Attached files</p>
				<div className="request-management__filters mg mg--b-4">
					{files.length > 0 ? (
						<ul>
							{files.map((file) => (
								<li key={file.id}>
									<button
										onClick={() =>
											downloadFile(
												file.fileContent,
												file.fileName
											)
										}
									>
										{file.fileName}
									</button>
								</li>
							))}
						</ul>
					) : (
						<p>No files attached</p>
					)}
				</div>
			</div> */}
		</div>
	);
};

export default SupportAuditDetails;
