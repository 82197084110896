// ENVIRONMENTS
export const SANDBOX = "SANDBOX";
export const PRE = "PRE";
export const LIVE = "LIVE";

// APIS STATUS
export const WITHOUT = "WITHOUT";
export const PENDING = "PENDING";
export const SUBSCRIBED = "SUBSCRIBED";
export const UNSUBCRIPTION = "UNSUBCRIPTION";
export const SUBSCRIPTION_REQUESTED = "SUBSCRIPTION_REQUESTED"
export const UNSUBSCRIPTION_REQUESTED = "UNSUBSCRIPTION_REQUESTED"