import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../utils/GlobalContext";
import { getRequestsList } from "../../services/ApiRequest";

// Crea el contexto
export const RequestContext = createContext();

const RequestProvider = ({ children }) => {
	const navigate = useNavigate();
	const { accessToken, setLoggedOut, isAuth, role } = useGlobalVar();
	const [requestLength, setRequestLength] = useState(0);

	const logout = useCallback(() => {
		setLoggedOut();
		navigate("/logout");
	}, [setLoggedOut, navigate]);

	useEffect(() => {
		if(isAuth && role === 'MASTER'){
			const fetchData = async () => {
				try {
					const response = await getRequestsList(accessToken);
					setRequestLength(response.length);
				} catch (error) {
					if(error.response && error.response.status === 401){
						logout();
					}
					else if(error.response && error.response.status === 403){
						logout();
					}
					console.error("Error al obtener los datos:", error);
				}
			};
			fetchData();
		}
	}, [accessToken, isAuth, logout, role]);

	return (
		<RequestContext.Provider value={requestLength}>
			{children}
		</RequestContext.Provider>
	);
};

export default RequestProvider;
