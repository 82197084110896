import { useNavigate } from "react-router-dom";

function BackButtonComponent({text, url ,reload}) {

  let navigate = useNavigate();
  const navigateTo = () => {
    reload ? window.location.reload() : navigate(url ? url : -1);
  }

  return (
    <>
      <div onClick={navigateTo}>
        <div className="back-button">
          <div className="back-button__icon"></div>
          <div className="back-button__text">
            <p>{text ? text : 'Back'}</p> 
          </div>
        </div>
      </div>
      
    </>
  );
}

export default BackButtonComponent;