import { React, useEffect, useState } from "react";
import { useGlobalVar } from "../../../utils/GlobalContext";
import { userPrivateAreaData } from "../../../data/userPrivateAreaData";

import { getMasterList } from "../../../../services/ApiUsers";

import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";

const AdministratorsTable = ({ onRowClick }) => {
	const { accessToken } = useGlobalVar();

	const requesUserHeader = ["Name", "Lastname", "Email"];
	const headerToFieldMap = {
		Name: "name",
		Last_Name: "lastname",
		Email: "email",
	};

	const columnWidths = ["30%", "30%", "30%"];

	const [requestData, setRequestData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [sortedData, setSortedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	// const [filterType, setFilterType] = useState("");
	// const totalPages = Math.ceil(sortedData.length / itemsPerPage);

	useEffect(() => {
		setRequestData(userPrivateAreaData);

		const fetchData = async () => {
			try {
				const usersListData = await getMasterList(accessToken);

				// Asegúrate de que mapeas las propiedades correctamente
				const mappedData = usersListData.map((item) => ({
					organization: item.organization,
					name: item?.firstname || "", // Comprueba si users existe
					lastname: item?.lastname || "", // Comprueba si users existe
					email: item?.email || "", // Comprueba si users existe
					date: item?.date || "", // Comprueba si users existe
					role: item?.roles[0]?.name || "", // Comprueba si roles existe
					phone: item?.phone || "", // Comprueba si phone existe
					id: item?.id || "", // Comprueba si phone existe
				}));

				setRequestData(mappedData);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [accessToken]);

	const handleSort = (header) => {
		const field = requesUserHeader[header] || header;
		const newSortOrder =
			sortColumn === header && sortOrder === "asc" ? "desc" : "asc";
		const sorted = [...sortedData].sort((a, b) => {
			if (a[field] < b[field]) return newSortOrder === "asc" ? -1 : 1;
			if (a[field] > b[field]) return newSortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setSortColumn(header);
		setSortOrder(newSortOrder);
		setSortedData(sorted);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleItemsPerPageChange = (newItemsPerPage) => {
		setItemsPerPage(newItemsPerPage);
		setCurrentPage(1);
	};

	// const handleFilterTypeChange = (type) => {
	// 	setFilterType(type);
	// };

	const currentData = requestData.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const handleRowClick = (rowIndex) => {
		const user = currentData[rowIndex];

		if (user) {
			onRowClick(user);
		}
	};

	return (
		<div>
			<div>
				<h2 className="mg mg--b-2 mg--t-4">Administrators list</h2>
				<TableComponent
					headers={requesUserHeader}
					headerToFieldMap={headerToFieldMap}
					data={currentData}
					onSort={handleSort}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
					combineName={true}
					onRowClick={handleRowClick}
					showBtn={false}
					columnWidths={columnWidths}
				/>
				<PaginationComponent
					currentPage={currentPage}
					totalPages={Math.ceil(requestData.length / itemsPerPage)}
					onPageChange={handlePageChange}
					itemsPerPage={itemsPerPage}
					onItemsPerPageChange={handleItemsPerPageChange}
				/>
			</div>
		</div>
	);
};

export default AdministratorsTable;
