import React from "react";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";

const AdviceModal = ({children, del, delFunction, conf, confFunction, onClose}) => {

    const childrenArray = React.Children.toArray(children);
    const title = childrenArray.find(child => child.props['data-type'] === 'title');
    const advice = childrenArray.find(child => child.props['data-type'] === 'advice');
   
    return (
        <>
            <div className='advice-modal'>
                <div className='advice-modal__header'>
                    <div className='advice-modal__header__title'>
                        <h3>{title}</h3>
                    </div>
                </div>
                <div className='advice-modal__advice'>
                    <div className="advice-modal__advice__content">
                        {advice}
                    </div>
                </div>
                <div className="advice-modal__buttons">
                    <ButtonComponent
                        onClick={onClose}
                        text={"Cancel"}
                        color={"button-component--neutral"}
                    />
                    {   del ? 
                         <ButtonComponent 
                            type="submit" 
                            onClick={delFunction}
                            text={'Delete APP'}
                            bgColor={'button-component--bg-danger-500'}
                        />
                        : null
                    }
                    {   conf ?
                        <ButtonComponent 
                            type="submit" 
                            text={'Confirm'}
                            bgColor={'button-component--bg--warning-500'}
                        />
                        : null
                    }
                    
                </div>
            </div>
        </>
    )
}

export default AdviceModal;
