const serverUrl =
	process.env.REACT_APP_BACKEND_API_URL || "http://localhost:8101";

export const loginUser = (loginData) =>
	callAPI({
		url: `${serverUrl}/api/v1/login`,
		method: "POST",
		mode: "no-cors",
		data: loginData,
	});

export const validateLoginUser = (sessionId) =>
	callAPI({
		url: `${serverUrl}/session/validate`,
		method: "GET",
		withCredentials: false,
		mode: "no-cors",
		headers: {
			auth: `JSESSIONID=${sessionId}`,
		},
	});

export const callAPI = ({
	url,
	method = "GET",
	mode = "same-origin",
	withCredentials = false,
	headers,
	data,
}) => {
	const defaultHeaders = {
		"Content-Type": "application/json",
	};
	return {
		url,
		method, // *GET, POST, PUT, DELETE, etc.
		mode, // no-cors, *cors, same-origin
		withCredentials, // true, false
		headers: { ...defaultHeaders, ...headers },
		data: data && JSON.stringify(data), // body data type must match "Content-Type" header
	};
};
