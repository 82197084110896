import React from 'react';


const AlertComponent = ({text, icon, bgColor}) => {

    return (
            <>
                <div className={`alert-component ${bgColor ? bgColor : ''}`}>
                    <span className={`alert-component__icon ${icon ? 'icon ' + icon : ''}`}></span>
                    <p className='alert-component__text'>{text}</p>
                </div>
            </>
    )
}

export default AlertComponent;