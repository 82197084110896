import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useGlobalVar } from "../../utils/GlobalContext";

import { getOrganizationsDetails } from "../../../services/ApiOrganization";

import TableComponent from "../../molecules/table-component/TableComponent";
import PaginationComponent from "../../molecules/pagination-component/PaginationComponent";
import BackButtonComponent from "../../atoms/back-button-component/BackButtonComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import { ADD_USERS, EDIT_USERS_TEAM, HOME } from "../../utils/Routes";

const ManageTeamUsers = () => {
	const { accessToken, userOrganization, setLoggedOut } = useGlobalVar();
	const [organizationTeam, setOrganizationTeam] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [sortedData, setSortedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const searchTerm = "";
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	// const totalPages = Math.ceil(sortedData.length / itemsPerPage);

	// const [showModal, setShowModal] = useState(false);
	// Función para manejar la ordenación en función del header

	const columnWidths = ["20%", "20%", "25%", "10%", "15%"];

	const headerOrganizationData = ["Name", "Lastname", "Email", "Rol"];

	const headerToFieldMap = {
		Name: "firstname",
		Lastname: "lastname",
		Email: "email",
		Registration: "active", // Asumiendo que el campo 'contract' no está en el JSON, lo sustituimos por 'active'
		Rol: "roles", // El campo de rol es una lista, así que lo mapeamos aquí
	};

	const logout = useCallback(() => {
		setLoggedOut();
		navigate("/logout");
	}, [setLoggedOut, navigate]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			try {
				const organizationDetailsData = await getOrganizationsDetails(
					userOrganization,
					accessToken
				);

				const organizationTeamData = organizationDetailsData.users.map(
					(user) => ({
						...user,
						roles: user.roles[0]?.name || "Unknown", // Extraer el nombre del primer rol
					})
				);
				setOrganizationTeam(organizationTeamData);
			} catch (error) {
				if (error.response.status === 401) {
					logout();
				}
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		if (userOrganization) {
			fetchData();
		}
	}, [userOrganization, logout, accessToken]);

	useEffect(() => {
		setSortedData(organizationTeam);
	}, [organizationTeam]);

	const handleSort = (header) => {
		const field = headerToFieldMap[header] || header;
		const newSortOrder =
			sortColumn === header && sortOrder === "asc" ? "desc" : "asc";
		const sorted = [...sortedData].sort((a, b) => {
			if (a[field] < b[field]) return newSortOrder === "asc" ? -1 : 1;
			if (a[field] > b[field]) return newSortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setSortColumn(header);
		setSortOrder(newSortOrder);
		setSortedData(sorted);
	};

	const handleButtonClick = (rowIndex) => {
		alert(`Button clicked in row ${rowIndex}`);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleItemsPerPageChange = (newItemsPerPage) => {
		setItemsPerPage(newItemsPerPage);
		setCurrentPage(1);
	};

	// const handleSearch = (term) => {
	// 	setSearchTerm(term);
	// };

	// Filtrar los datos
	const filteredData = sortedData.filter((item) =>
		item.firstname.toLowerCase().includes(searchTerm.toLowerCase())
	);

	// Calcular el índice inicial y final de los elementos que deben mostrarse en la página actual
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	// Obtener solo los elementos de la página actual
	const currentData = filteredData.slice(startIndex, endIndex);

	if (loading) {
		return <div>Loading...</div>;
	}

	const handleRowClick = (rowIndex) => {
		const globalIndex = (currentPage - 1) * itemsPerPage + rowIndex;
		const rowData = filteredData[globalIndex];
		navigate(EDIT_USERS_TEAM, { state: { rowData } });
	};

	const handleClick = () => {
		navigate(ADD_USERS);
	};

	return (
		<>
			<div className="request-subscription">
				<div className="request-subscription__heading">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent text={"Back to Apps"} url={HOME} />
					</div>
					<h1>Team management</h1>
				</div>
				<div className="request-subscription__form">
					<TableComponent
						headers={headerOrganizationData}
						data={currentData} // Cambiado de filteredData a currentData
						onSort={handleSort}
						sortColumn={sortColumn}
						sortOrder={sortOrder}
						onButtonClick={handleButtonClick}
						onRowClick={handleRowClick}
						showBtn={false}
						headerToFieldMap={headerToFieldMap}
						columnWidths={columnWidths}
					/>

					<PaginationComponent
						currentPage={currentPage}
						totalPages={Math.ceil(sortedData.length / itemsPerPage)}
						onPageChange={handlePageChange}
						itemsPerPage={itemsPerPage}
						onItemsPerPageChange={handleItemsPerPageChange}
					/>
					<ButtonComponent
						text={"Add new user"}
						onClick={handleClick}
					/>
				</div>
			</div>
		</>
	);
};

export default ManageTeamUsers;
