import React, { useState, useEffect } from "react";
import { useGlobalVar } from "../../utils/GlobalContext";
import { useNavigate } from "react-router-dom";

import { error as showError, success as showSuccess } from "../../utils/NotificationsContainer";

import LinkComponent from "../../atoms/link-component/LinkComponent";
import BackButtonComponent from "../../atoms/back-button-component/BackButtonComponent";
import DividerComponent from "../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import AlertComponent from "../../atoms/alert-component/alertComponent";
import ModalComponent from "../../molecules/modal-component/ModalComponent";
import FormBecomeCustomer from "../../organisms/form-become-customer/FormBecomeCustomer";

import { HOME, REQUEST_MODIFICATION } from "../../utils/Routes";
import { SANDBOX, PRE, LIVE } from "../../utils/GlobalConsts";
import { WITHOUT, PENDING } from "../../utils/GlobalConsts";

import { subscribeApp } from "../../../services/ApiSubscriptions";

// PLACEHOLDERS PARA INPUTS TEXT Y TEXTAREAS
const placeholders = {
	name: "Type name of APP",
	publicKey: "Insert your public Key",
	keyId: "Insert your Key ID",
	insertIssuer: "Insert your Key ID",
};

// const userClient = false; // EL USUARIO ES CLIENTE
// const nameOrganization = "DRAFT"; //NOMBRE DE LA ORGANIZACIÓM

const FormRequestSubscriptionPage = () => {
	const {
		accessToken,
		userEmail,
		nameOrganization,
		fetchOrganizationDetails,
		userData,
		becomeCustomerSended
	} = useGlobalVar();

	const [showBecomeCustomerModal, setShowBecomeCustomerModal] = useState(false);

	const navigate = useNavigate();

	const showPendingError = () => {
		showError("Request Upgrade plan already sended");
	}

	useEffect(() => {
		fetchOrganizationDetails(); // Proporciona el ID de la organización
	}, [fetchOrganizationDetails]);

	// OBJETO QUE RECOGE LOS DATOS
	const [formData, setFormData] = useState({
		nameOrganization: nameOrganization,
		name: "",
		environment: SANDBOX, //SANDBOX, PRE, LIVE
		subscribeAPI: {
			apiIso: PENDING, //WITHOUT, PENDING
			apiRTran: WITHOUT,
			apiCustomer: WITHOUT,
		},
		publicKey: "",
		keyId: "",
		insertIssuer: "",
		statusReport: false,
		webhoot: false,
		nameSecurity: "",
		basicSecurity: false,
		basicSecurityParam: {
			user: "",
			pass: "",
			notificationUrl: "",
		},
		oauthSecurity: false,
		oauthParam: {
			clientSecret: "",
			clientID: "",
			notificationUrl: "",
			tokenUrl: "",
		},
		notSecurity: false,
		notSecurityParam: {
			tokenUrl: "",
		},
	});

	// EVENTO QUE RECOGE LOS DATOS Y LOS ASIGNA A FormData
	const handleChange = (e) => {
		// CAMBIAMOS EL VALOR DEL ENTORNO
		if (e.target.name === "environment") {
			const selectedEnv = e.target.value;

			// CREAMOS OBJETO PARA API BASANDONOS EN LA ELECCIÓN ACTUAL
			let newAPI = formData.subscribeAPI;

			// SI ELEGIMOS SANDBOX TODAS LAS APIS SON SELECCIONADAS
			if (selectedEnv === SANDBOX) {
				newAPI = {
					apiIso: PENDING,
					apiRTran: PENDING,
					apiCustomer: PENDING,
				};
			}

			// SI ES LIVE SETEAMOS EL ENTORNO A LIVE, SINO LIMPIAMOS LOS CAMPOS DE NOTIFICACIÓN
			if (selectedEnv === LIVE)
				setFormData({
					...formData,
					environment: selectedEnv,
					subscribeAPI: newAPI,
				});
			else {
				setFormData({
					...formData,
					environment: selectedEnv,
					subscribeAPI: newAPI,
					statusReport: false,
					webhoot: false,
					notSecurity: false,
					oauthSecurity: false,
					basicSecurity: false,
					nameSecurity: "",
					oauthParam: {
						clientSecret: "",
						clientID: "",
						notificationUrl: "",
						tokenUrl: "",
					},
					basicSecurityParam: {
						notificationUrl: "",
						pass: "",
						user: "",
					},
					notSecurityParam: {
						tokenUrl: "",
					},
				});
			}
		} else if (
			e.target.name === "notifications" ||
			e.target.name === "notifications_sec"
		) {
			const selectedNotification = e.target.value;

			switch (selectedNotification) {
				case "statusReport":
					setFormData({
						...formData,
						statusReport: true,
						webhoot: false,
					});
					break;
				case "webhoot":
					setFormData({
						...formData,
						statusReport: false,
						webhoot: true,
					});
					break;
				case "notSecurity":
					setFormData({
						...formData,
						notSecurity: true,
						oauthSecurity: false,
						basicSecurity: false,
						nameSecurity: selectedNotification,
						oauthParam: {
							clientSecret: "",
							clientID: "",
							notificationUrl: "",
							tokenUrl: "",
						},
						basicSecurityParam: {
							notificationUrl: "",
							pass: "",
							user: "",
						},
					});
					break;
				case "basicSecurity":
					setFormData({
						...formData,
						notSecurity: false,
						oauthSecurity: false,
						basicSecurity: true,
						nameSecurity: selectedNotification,
						oauthParam: {
							clientSecret: "",
							clientID: "",
							notificationUrl: "",
							tokenUrl: "",
						},
						notSecurityParam: {
							tokenUrl: "",
						},
					});
					break;
				case "oauthSecurity":
					setFormData({
						...formData,
						notSecurity: false,
						oauthSecurity: true,
						basicSecurity: false,
						nameSecurity: selectedNotification,
						notSecurityParam: {
							tokenUrl: "",
						},
						basicSecurityParam: {
							notificationUrl: "",
							pass: "",
							user: "",
						},
					});
					break;
				default:
					break;
			}
		} else {
			if (e.target.type === "checkbox") {
				const { name, checked } = e.target;

				let checkedStatus = checked ? PENDING : WITHOUT;

				setFormData({
					...formData,
					subscribeAPI: {
						...formData.subscribeAPI,
						[name]: checkedStatus,
					},
				});
			} else {
				const { name, value } = e.target;
				setFormData((prevFormData) => {
					const keys = name.split(".");
					let newFormData = { ...prevFormData };
					let nested = newFormData;

					for (let i = 0; i < keys.length - 1; i++) {
						nested = nested[keys[i]];
					}

					nested[keys[keys.length - 1]] = value;

					return newFormData;
				});
			}
		}
	};

	/* useEffect(() => {
		if (nameOrganization) {
			setFormData((prevFormData) => ({
				...prevFormData,
				nameOrganization: nameOrganization,
			}));
		}
	}, [nameOrganization]); */

	// ENVIAR LOS DATOS A ENDPOINT
	const handleSubmit = async (e) => {
		e.preventDefault();

		const payload = {
			nameOrganization: formData.nameOrganization,
			name: formData.name,
			environment: formData.environment,
			subscribeAPI: formData.subscribeAPI,
			publicKey:
				formData.environment !== SANDBOX
					? formData.publicKey
					: undefined,
			keyId:
				formData.environment !== SANDBOX ? formData.keyId : undefined,
			insertIssuer:
				formData.environment !== SANDBOX
					? formData.insertIssuer
					: undefined,
			statusReport:
				formData.environment === LIVE
					? formData.statusReport
					: undefined,
			webhoot:
				formData.environment === LIVE ? formData.webhoot : undefined,
			nameSecurity:
				formData.environment === LIVE
					? formData.nameSecurity
					: undefined,
			basicSecurity:
				formData.environment === LIVE
					? formData.basicSecurity
						? formData.basicSecurity
						: undefined
					: undefined,
			basicSecurityParam:
				formData.environment === LIVE
					? formData.basicSecurity
						? formData.basicSecurityParam
						: undefined
					: undefined,
			oauthSecurity:
				formData.environment === LIVE
					? formData.oauthSecurity
						? formData.oauthSecurity
						: undefined
					: undefined,
			oauthParam:
				formData.environment === LIVE
					? formData.oauthSecurity
						? formData.oauthParam
						: undefined
					: undefined,
			notSecurity:
				formData.environment === LIVE
					? formData.notSecurity
						? formData.notSecurity
						: undefined
					: undefined,
			notSecurityParam:
				formData.environment === LIVE
					? formData.notSecurity
						? formData.notSecurityParam
						: undefined
					: undefined,
		};

		const cleanedPayload = JSON.parse(JSON.stringify(payload));

		// CONFIGURACIÓN DEL HEADER
		// const config = {
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		email: userEmail,
		// 		Authorization: accessToken,
		// 	},
		// };

		if (cleanedPayload.name === "") {
			showError("App name missing");
		} else {
			subscribeApp(cleanedPayload, accessToken, userEmail)
			.then((response) => {
			// localStorage.setItem('subscriptionAPIResponse', JSON.stringify(formData));

			// SUSCRIBIMOS TAMBIÉN A SANDBOX SI SE SUSCRIBE A PRE O LIVE
			if (formData.environment === PRE || formData.environment === LIVE) {
				const secondPayload = {
				nameOrganization: formData.nameOrganization,
				name: formData.name,
				environment: "SANDBOX",
				subscribeAPI: {
					apiIso: "PENDING",
					apiRTran: "PENDING",
					apiCustomer: "PENDING",
				},
				};

				// Realiza la segunda petición con los nuevos datos
				return subscribeApp(secondPayload, accessToken, userEmail)
				.then((secondResponse) => {
					console.log("Segunda petición exitosa:", secondResponse);
					showSuccess(`Your subscription in ${payload.environment} has been registered`);
					// Navega a la siguiente página
					navigate(REQUEST_MODIFICATION, {
						state: {
						env: formData.environment,
						appName: formData.name,
						subscriptionAPIResponse: formData,
						},
					});
				});
			}

			// Este then se ejecuta cuando no es necesario hacer la segunda petición
			showSuccess(`Your subscription in ${payload.environment} has been registered`);

			// Navega a la siguiente página
			navigate(REQUEST_MODIFICATION, {
				state: {
				env: formData.environment,
				appName: formData.name,
				subscriptionAPIResponse: formData,
				},
			});
			})
			.catch((error) => {
			showError("Check empty form fields");
			console.error("Hubo un error al enviar el formulario:", error);
			});
		}
		

		
	};

	return (
		<>
			<div className="request-subscription">
				<div className="request-subscription__heading">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent text="Back to APP list"  url={HOME}/>
					</div>
					<div className="request-subscription__heading__title">
						<h1>Request subscription</h1>
					</div>
					<div className="request-subscription__heading__description">
						<p>
							You can subscribe to a sandbox to test the API in
							practice, and when you're ready, change your
							subscription to the live environment and let your
							app conquer the world.
						</p>
					</div>
				</div>

				{/* FORMULARIO */}
				<div className="request-subscription__form">
					<form onSubmit={handleSubmit}>
						{/* REGISTRO DE NOMBRE DE APP */}
						<div className="request-subscription__form__input request-subscription__form__input--name">
							<div className="request-subscription__form__input__title">
								<span className="request-subscription__form__input__title__number">
									1
								</span>
								<h4>Register your App</h4>
							</div>
							<div className="request-subscription__form__input__content">
								<div className="input-content">
									<div className="input-content__title">
										<span>App Name</span>
									</div>
									<label>
										<input
											type="text"
											name="name"
											value={formData.name}
											placeholder={placeholders.name}
											autoComplete="off"
											onChange={handleChange}
										/>
									</label>
								</div>
							</div>
						</div>

						<DividerComponent />

						{/*SELECCIÓN DE ENTORNO */}
						<div className="request-subscription__form__input">
							<div className="request-subscription__form__input__title">
								<span className="request-subscription__form__input__title__number">
									2
								</span>
								<h4>Select environment</h4>
							</div>
							<div className="request-subscription__form__input__content">
								<div className="input-content">
									<div className="input-content__radios">
										{/* INPUT DE ENTORNO SANDBOX PRESELECCIONADO */}
										<input
											type="radio"
											value="SANDBOX"
											name="environment"
											id="SANDBOX"
											checked={
												formData.environment ===
												"SANDBOX"
											}
											onChange={handleChange}
										/>
										<label htmlFor="SANDBOX">
											<div className="input-content__radios__radio">
												<div className="input-content__radios__radio__content">
													<span className="input-content__radios__radio__title">
														SANDBOX
													</span>
													<span className="input-content__radios__radio__description">
														Use this environment to
														know our platform
													</span>

													<div className="input-content__radios__radio__button">
														{!userData?.client ||
														formData.environment ===
															SANDBOX ? (
															<LinkComponent
																text={
																	"Selected"
																}
																icon={
																	"icon-check"
																}
																bgColor={
																	"tertiary-100"
																}
																filled
															/>
														) : (
															<LinkComponent
																text={
																	"Avaliable"
																}
																bgColor={
																	"tertiary-100"
																}
																color={
																	"tertiary-500"
																}
																borderColor={
																	"tertiary-500"
																}
															/>
														)}
													</div>
												</div>
											</div>
										</label>

										{/* HABILITAMOS SELECCIÓN DE INPUT SI EL USUARIO ES CLIENTE */}
										<input
											type="radio"
											value="PRE"
											name="environment"
											id={userData?.client ? "PRE" : SANDBOX}
											checked={
												formData.environment === PRE
											}
											onChange={handleChange}
										/>
										<label htmlFor="PRE">
											<div className="input-content__radios__radio">
												<div className="input-content__radios__radio__content">
													<span className="input-content__radios__radio__title">
														PRE
													</span>
													<span className="input-content__radios__radio__description">
														Use all the features in
														your test environment
													</span>
													<div className="input-content__radios__radio__button">
														{!userData?.client ? (
															 <>
																{ becomeCustomerSended ? 
										
																<div onClick={() => showPendingError()}>
																	<LinkComponent text={'Upgrade pending'} icon={'icon-pending'} bgColor={'bg-pending'}/>
																</div> 
										
																:
																<div onClick={() => setShowBecomeCustomerModal(true)}>
																	<LinkComponent text={'Upgrade your plan'} icon={'icon-spark'} filled/>
																</div> 
																}
														 	</>
														) : userData?.client &&
														  formData.environment ===
																"PRE" ? (
															<LinkComponent
																text={
																	"Selected"
																}
																icon={
																	"icon-check"
																}
																bgColor={
																	"tertiary-100"
																}
																filled
															/>
														) : (
															<LinkComponent
																text={
																	"Avaliable"
																}
																bgColor={
																	"tertiary-100"
																}
																color={
																	"tertiary-500"
																}
																borderColor={
																	"tertiary-500"
																}
															/>
														)}
													</div>
												</div>
											</div>
										</label>

										{/* HABILITAMOS SELECCIÓN DE INPUT SI EL USUARIO ES CLIENTE */}
										<input
											type="radio"
											value={LIVE}
											name="environment"
											id={userData?.client ? LIVE : SANDBOX}
											checked={
												formData.environment === LIVE
											}
											onChange={handleChange}
										/>
										<label htmlFor={LIVE}>
											<div className="input-content__radios__radio">
												<div className="input-content__radios__radio__content">
													<span className="input-content__radios__radio__title">
														LIVE
													</span>
													<span className="input-content__radios__radio__description">
														You’re ready to conquer
														the world
													</span>
													<div className="input-content__radios__radio__button">
														{!userData?.client ? (
															<>
															{ becomeCustomerSended ? 
									
																<div onClick={() => showPendingError()}>
																	<LinkComponent text={'Upgrade pending'} icon={'icon-pending'} bgColor={'bg-pending'}/>
																</div> 
										
																:
																<div onClick={() => setShowBecomeCustomerModal(true)}>
																	<LinkComponent text={'Upgrade your plan'} icon={'icon-spark'} filled/>
																</div> 
																}
															</>
														) : userData?.client &&
														  formData.environment ===
																LIVE ? (
															<LinkComponent
																text={
																	"Selected"
																}
																icon={
																	"icon-check"
																}
																bgColor={
																	"tertiary-100"
																}
																filled
															/>
														) : (
															<LinkComponent
																text={
																	"Avaliable"
																}
																bgColor={
																	"tertiary-100"
																}
																color={
																	"tertiary-500"
																}
																borderColor={
																	"tertiary-500"
																}
															/>
														)}
													</div>
												</div>
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>

						<DividerComponent />

						{/* SELECCIÓN DE API */}
						<div className="request-subscription__form__input">
							<div className="request-subscription__form__input__title">
								<span className="request-subscription__form__input__title__number">
									3
								</span>
								<h4>Select API to subscribe</h4>
							</div>
							{formData.environment === SANDBOX ? (
								<div className="request-subscription__form__alert">
									<AlertComponent
										text={
											"In SandBox all of our APIs are available for you to try. Use the ones you want to test"
										}
										icon={"icon-info"}
										bgColor={"bg--brand-neutral-100"}
									/>
								</div>
							) : (
								""
							)}
							<div className="request-subscription__form__input__content">
								<div className="input-content">
									<div className="input-content__checkboxes">
										<div className="input-content__checkboxes__checkbox">
											<input
												type="checkbox"
												name="apiIso"
												id="apiIso"
												checked={
													formData.subscribeAPI
														.apiIso === PENDING
												}
												value={
													formData.subscribeAPI.apiIso
												}
												onChange={handleChange}
												disabled={
													formData.environment ===
													SANDBOX
												}
											/>
											<label htmlFor="apiIso">
												<div className="input-content__checkboxes__checkbox__content">
													<div className="input-content__checkboxes__checkbox__box"></div>
													<div className="input-content__checkboxes__checkbox__text">
														<div className="input-content__checkboxes__checkbox__text__title">
															<span>
																API ISO20022
															</span>
														</div>
														<div className="input-content__checkboxes__checkbox__text__description">
															<span>
																Brief
																description of
																the API utility
															</span>
														</div>
													</div>
												</div>
											</label>
										</div>

										{/* <div className="input-content__checkboxes__checkbox">
											<input
												type="checkbox"
												name="apiRTran"
												id="apiRTran"
												checked={
													formData.subscribeAPI
														.apiRTran === PENDING
												}
												value={
													formData.subscribeAPI
														.apiRTran
												}
												onChange={handleChange}
												disabled={
													formData.environment ===
													SANDBOX
												}
											/>
											<label htmlFor="apiRTran">
												<div className="input-content__checkboxes__checkbox__content">
													<div className="input-content__checkboxes__checkbox__box"></div>
													<div className="input-content__checkboxes__checkbox__text">
														<div className="input-content__checkboxes__checkbox__text__title">
															<span>
																API
																R-Transactions
															</span>
														</div>
														<div className="input-content__checkboxes__checkbox__text__description">
															<span>
																Brief
																description of
																the API utility
															</span>
														</div>
													</div>
												</div>
											</label>
										</div> */}

										{/* <div className="input-content__checkboxes__checkbox">
											<input
												type="checkbox"
												name="apiCustomer"
												id="apiCustomer"
												checked={
													formData.subscribeAPI
														.apiCustomer === PENDING
												}
												value={
													formData.subscribeAPI
														.apiCustomer
												}
												onChange={handleChange}
												disabled={
													formData.environment ===
													SANDBOX
												}
											/>
											<label htmlFor="apiCustomer">
												<div className="input-content__checkboxes__checkbox__content">
													<div className="input-content__checkboxes__checkbox__box"></div>
													<div className="input-content__checkboxes__checkbox__text">
														<div className="input-content__checkboxes__checkbox__text__title">
															<span>
																API Customer
																Portal
															</span>
														</div>
														<div className="input-content__checkboxes__checkbox__text__description">
															<span>
																Brief
																description of
																the API utility
															</span>
														</div>
													</div>
												</div>
											</label>
										</div> */}
									</div>
								</div>
							</div>
						</div>

						{/* DATOS DEL ENTORNO - SOLO HABILITADO SI EL ENTORNO ES PRE O LIVE*/}
						{formData.environment === PRE ||
						formData.environment === LIVE ? (
							<>
								<DividerComponent />
								<div className="request-subscription__form__input">
									<div className="request-subscription__form__input__title">
										<span className="request-subscription__form__input__title__number">
											4
										</span>
										<h4>Provide us your data</h4>
									</div>
									<div className="request-subscription__form__input__content">
										<div className="input-content">
											<div className="input-content__title">
												<span>Public Key</span>
											</div>
											<label>
												<textarea
													name="publicKey"
													value={formData.publicKey}
													placeholder={
														placeholders.publicKey
													}
													autoComplete="off"
													onChange={handleChange}
												/>
											</label>
										</div>
										<div className="input-content">
											<div className="input-content__title">
												<span>Key ID</span>
											</div>
											<label>
												<input
													type="text"
													name="keyId"
													value={formData.keyId}
													placeholder={
														placeholders.keyId
													}
													autoComplete="off"
													onChange={handleChange}
												/>
											</label>
										</div>
										<div className="input-content">
											<div className="input-content__title">
												<span>Issuer</span>
											</div>
											<label>
												<input
													type="text"
													name="insertIssuer"
													value={
														formData.insertIssuer
													}
													placeholder={
														placeholders.insertIssuer
													}
													autoComplete="off"
													onChange={handleChange}
												/>
											</label>
										</div>
									</div>
								</div>
							</>
						) : (
							""
						)}

						{/*SELECCIÓN DE NOTIFICACIONES - SOLO HABILITADO SI EL ENTORNO ES LIVE */}
						{formData.environment === LIVE ? (
							<>
								<DividerComponent />

								<div className="request-subscription__form__input">
									<div className="request-subscription__form__input__title">
										<span className="request-subscription__form__input__title__number">
											5
										</span>
										<h4>Subscribe to Notifier endpoint</h4>
									</div>
									<div className="request-subscription__form__input__content">
										<div className="input-content">
											<div className="input-content__title">
												<span>Notifications type</span>
											</div>
											<div className="input-content__radios input-content__radios--secondary">
												<input
													type="radio"
													value="statusReport"
													name="notifications"
													id="statusReport"
													checked={
														formData.statusReport
													}
													onChange={handleChange}
												/>
												<label htmlFor="statusReport">
													<div className="input-content__radios__radio">
														<div className="input-content__radios__radio__content">
															<span className="input-content__radios__radio__title">
																Status report
																hub messages
															</span>
															<span className="input-content__radios__radio__description">
																Brief
																description
															</span>
														</div>
													</div>
												</label>
												<input
													type="radio"
													value="webhoot"
													name="notifications"
													id="webhoot"
													checked={formData.webhoot}
													onChange={handleChange}
												/>
												<label htmlFor="webhoot">
													<div className="input-content__radios__radio">
														<div className="input-content__radios__radio__content">
															<span className="input-content__radios__radio__title">
																Webhook
															</span>
															<span className="input-content__radios__radio__description">
																Brief
																description
															</span>
														</div>
													</div>
												</label>
											</div>
										</div>
										<div className="input-content">
											<div className="input-content__title">
												<span>Notifier security</span>
											</div>
											<div className="input-content__radios input-content__radios--secondary">
												<input
													type="radio"
													value="oauthSecurity"
													name="notifications_sec"
													id="oauthSecurity"
													onChange={handleChange}
													checked={
														formData.oauthSecurity
													}
												/>
												<label htmlFor="oauthSecurity">
													<div className="input-content__radios__radio">
														<div className="input-content__radios__radio__content">
															<span className="input-content__radios__radio__title">
																OAuth 2.0
															</span>
															<span className="input-content__radios__radio__description">
																Brief
																description
															</span>
														</div>
													</div>
												</label>
												<input
													type="radio"
													value="basicSecurity"
													name="notifications_sec"
													id="basicSecurity"
													onChange={handleChange}
													checked={
														formData.basicSecurity
													}
												/>
												<label htmlFor="basicSecurity">
													<div className="input-content__radios__radio">
														<div className="input-content__radios__radio__content">
															<span className="input-content__radios__radio__title">
																HTTP Basic
															</span>
															<span className="input-content__radios__radio__description">
																Brief
																description
															</span>
														</div>
													</div>
												</label>
												{/* <input
													type="radio"
													value="notSecurity"
													name="notifications_sec"
													id="notSecurity"
													onChange={handleChange}
													checked={
														formData.notSecurity
													}
												/>
												<label htmlFor="notSecurity">
													<div className="input-content__radios__radio">
														<div className="input-content__radios__radio__content">
															<span className="input-content__radios__radio__title">
																Without security
															</span>
															<span className="input-content__radios__radio__description">
																Brief
																description
															</span>
														</div>
													</div>
												</label> */}
											</div>
										</div>

										{/* CAMPOS DE TEXTO PARA SEGURIDAD OAUTH */}
										{formData.oauthSecurity ? (
											<>
												<div className="input-content">
													<div className="input-content__title">
														<span>
															Functional endpoint
														</span>
													</div>
													<label>
														<input
															type="text"
															name="oauthParam.notificationUrl"
															value={
																formData
																	.oauthParam
																	.notificationUrl
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
												<div className="input-content">
													<div className="input-content__title">
														<span>
															Webhook Client ID
														</span>
													</div>
													<label>
														<input
															type="text"
															name="oauthParam.clientID"
															value={
																formData
																	.oauthParam
																	.clientID
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
												<div className="input-content">
													<div className="input-content__title">
														<span>
															Client Secret
														</span>
													</div>
													<label>
														<input
															type="text"
															name="oauthParam.clientSecret"
															value={
																formData
																	.oauthParam
																	.clientSecret
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
												<div className="input-content">
													<div className="input-content__title">
														<span>Token URI</span>
													</div>
													<label>
														<input
															type="text"
															name="oauthParam.tokenUrl"
															value={
																formData
																	.oauthParam
																	.tokenUrl
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
											</>
										) : (
											""
										)}

										{/* CAMPOS DE TEXTO PARA SEGURIDAD BÁSICA */}
										{formData.basicSecurity ? (
											<>
												<div className="input-content">
													<div className="input-content__title">
														<span>
															Functional endpoint
														</span>
													</div>
													<label>
														<input
															type="text"
															name="basicSecurityParam.notificationUrl"
															value={
																formData
																	.basicSecurityParam
																	.notificationUrl
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
												<div className="input-content">
													<div className="input-content__title">
														<span>User</span>
													</div>
													<label>
														<input
															type="text"
															name="basicSecurityParam.user"
															value={
																formData
																	.basicSecurityParam
																	.user
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
												<div className="input-content">
													<div className="input-content__title">
														<span>Password</span>
													</div>
													<label>
														<input
															type="text"
															name="basicSecurityParam.pass"
															value={
																formData
																	.basicSecurityParam
																	.pass
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
											</>
										) : (
											""
										)}

										{/* CAMPOS DE TEXTO PARA NO SEGURIDAD */}
										{formData.notSecurity ? (
											<>
												<div className="input-content">
													<div className="input-content__title">
														<span>Token URI</span>
													</div>
													<label>
														<input
															type="text"
															name="notSecurityParam.tokenUrl"
															value={
																formData
																	.notSecurityParam
																	.tokenUrl
															}
															autoComplete="off"
															onChange={
																handleChange
															}
														/>
													</label>
												</div>
											</>
										) : (
											""
										)}
									</div>
								</div>
							</>
						) : (
							""
						)}

						<DividerComponent />
						<div className="request-subscription__form__submit-buttons">
							<div className="request-subscription__form__submit-buttons__cancel">
								<ButtonComponent
									text={"Cancel"}
									color={"button-component--neutral"}
									url={HOME}
								/>
							</div>
							<div className="request-subscription__form__submit-buttons__confirm">
								<ButtonComponent
									type="submit"
									text={"Subscription API"}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>

			<ModalComponent show={showBecomeCustomerModal} onClose={() => setShowBecomeCustomerModal(false)} size={'xl'}>
				<FormBecomeCustomer onClose={() => setShowBecomeCustomerModal(false)}/>
			</ModalComponent>
		</>
	);
};

export default FormRequestSubscriptionPage;
