import React from "react";

const PaginationComponent = ({
	currentPage,
	totalPages,
	onPageChange,
	itemsPerPage,
	onItemsPerPageChange,
}) => {
	const handlePrevClick = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	const handleNextClick = () => {
		if (currentPage < totalPages) {
			onPageChange(currentPage + 1);
		}
	};

	const handleItemsPerPageChange = (event) => {
		onItemsPerPageChange(parseInt(event.target.value));
	};

	return (
		<div className="pagination-component">
			<span>Rows per page:</span>
			<select
				value={itemsPerPage}
				onChange={handleItemsPerPageChange}
				className="pagination-component__select mg mg--r-6"
			>
				<option value={10}>10</option>
				<option value={25}>25</option>
				<option value={50}>50</option>
			</select>
			<span>
				{currentPage} of {totalPages}
			</span>
			<button
				onClick={handlePrevClick}
				disabled={currentPage === 1}
				className="pagination-component__button"
			>
				<span className="icon-chevron chevron-left"></span>
			</button>
			<button
				onClick={handleNextClick}
				disabled={currentPage === totalPages}
				className="pagination-component__button"
			>
				<span className="icon-chevron chevron-right"></span>
			</button>
		</div>
	);
};

export default PaginationComponent;
