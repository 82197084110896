import React, { useEffect } from "react";

import { success as showSuccess } from "../../../utils/NotificationsContainer";

import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import TabsComponent from "../../../molecules/tabs-component/TabsComponent";
import AdministratorsManagement from "../admin-area-administrators-management/AdministratorsManagement";
import EmailConfiguration from "../admin-area-email-configuration/EmailConfiguration";
import APIsManagement from "../admin-area-apis-management/APIsManagement";

const SettingsManagement = () => {
	const tabs = [
		{ label: "ADMINISTRATORS", component: AdministratorsManagement },
		{ label: "EMAIL CONFIGURATION", component: EmailConfiguration },
		{ label: "APIs", component: APIsManagement },
	];

	useEffect(() => {
        const reloadDeleted = localStorage.getItem('reloadDeleted');
		const reloadMasterAdded = localStorage.getItem('reloadMasterAdded');
		const reloadAPIDeleted = localStorage.getItem('reloadAPIDeleted');
		const reloadAPIAdded = localStorage.getItem('reloadAPIAdded');
        
        if (reloadDeleted) {
            showSuccess(`User successfully deleted`);
            localStorage.removeItem('reloadDeleted');
        } else if (reloadMasterAdded) {
			showSuccess('New user successfully added');
            localStorage.removeItem('reloadMasterAdded');
        } else if (reloadAPIDeleted) {
			showSuccess(`API successfully deleted`);
			localStorage.removeItem('reloadAPIDeleted');
		} else if (reloadAPIAdded) {
			showSuccess(`New API successfully added`);
			localStorage.removeItem('reloadAPIAdded');
		}
        
      }, []);

	return (
		<div>
			<div className="request-subscription">
				<div className="request-subscription__heading">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent />
					</div>
					<h1 className="mg mg--b-2">Settings</h1>
				</div>
				<TabsComponent tabs={tabs} />
			</div>
		</div>
	);
};

export default SettingsManagement;
