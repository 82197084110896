import React, { useState, useEffect } from "react";
import AppList from "../../../organisms/app-list/AppList";
import { getSubscriptionsByOrganization } from "../../../../services/ApiSubscriptions";
import { getOrganizationsDetails } from "../../../../services/ApiOrganization";
import { useGlobalVar } from "../../../utils/GlobalContext";

const SubscriptionOrganization = () => {
	const { accessToken } = useGlobalVar();
	const [organizationDetails, setOrganizationDetails] = useState(null);
	const [subscriptionList, setSubscriptionList] = useState([]);
	const [loading, setLoading] = useState(true);

	const currentOrganizationId = localStorage.getItem('currentOrganizationId');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const organizationDetailData = await getOrganizationsDetails(
					currentOrganizationId,
					accessToken
				);
				setOrganizationDetails(organizationDetailData);

				const nameOrganization =
					organizationDetailData.organization.name;
				const subscriptionData = await getSubscriptionsByOrganization(
					nameOrganization,
					accessToken
				);

				setSubscriptionList(subscriptionData.subscriptionStateList);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [currentOrganizationId, accessToken]); // Este efecto se ejecuta cuando organizationId cambia

	if (loading) {
		return <div className="mg mg--t-4">Loading...</div>;
	}

	return (
		<div className="team-management__form">
			<div className="mg mg--t-2">
				{subscriptionList ? (
					<AppList
						appListContent={subscriptionList}
						onTab={true}
						organization={organizationDetails.organization.name}
					/>
				) : (
					<div className="mg mg--t-4">
						There are no subscriptions associated with this
						organization
					</div>
				)}
			</div>
		</div>
	);
};

export default SubscriptionOrganization;
