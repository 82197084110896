import { useTranslation } from "react-i18next";
import _ from "lodash";

function InputFormComponent({
  inputType,
  fieldLabel,
  fieldLabelPrefix,
  id,
  field,
  register,
  required,
  defaultValue,
  placeholder = "Seleccionar",
  pattern,
  patternErrorMessage,
  patternErrorFieldPrefix,
  selectOptions,
  radioOptions,
  onChange,
  validate,
  errors,
  multiple,
  defaultChecked,
  onKeyPress,
  className = "",
  ...rest
}) {

  function selectValue(object, selector) {
    return _.get(object, selector);
  }

  const { t } = useTranslation("global");

  const hasErrors = !!selectValue(errors, field);

  const chooseClass = () => {
    if (hasErrors) {
      return "error";
    } else if (className) {
      return className;
    }
  };

  function buildFieldConfig(required, pattern) {
    const fieldConfig = {};

    if (required) {
      fieldConfig.required = t("app.form.error.required");
    }

    if (pattern) {
      fieldConfig.pattern = {
        value: pattern,
         message: patternErrorMessage || t("app.form.error.pattern", {
           name: patternErrorFieldPrefix
               ? t(patternErrorFieldPrefix + field)
               : field,
         }),
      };
    }

    if (onChange) {
      fieldConfig.onChange = onChange;
    }

    if (validate) {
      fieldConfig.validate = validate;
    }

    return fieldConfig;
  }

  const fieldConfig = buildFieldConfig(required, pattern);

  const inputProps = {
    id: id ? id : field,
    type: inputType,
    defaultValue: defaultValue,
    onChange: onChange,
    onKeyPress: onKeyPress,
    placeholder: placeholder,
    className: chooseClass(),
    ...register(field, { ...fieldConfig, ...rest }),
  };
  const requiredLabel = required ? " *" : "";
  const label = (
    <>
      {fieldLabel ? (
        <label htmlFor={id ? id : field}>
          {fieldLabel}
          {requiredLabel}
        </label>
      ) : (
        fieldLabelPrefix && (
          <label htmlFor={id ? id : field}>
            {t(`${fieldLabelPrefix}.${field}`)}
            {requiredLabel}
          </label>
        )
      )}
    </>
  );

  if (inputType === "radio") {
    return (
      <div className="options-flex">
        {radioOptions.map((radioOption, index) => (
          <div className="radio" key={field + index}>
            <label htmlFor={field + index}>{radioOption.label}</label>
            <input
              {...inputProps}
              type="radio"
              value={radioOption.value}
              id={field + index}
            />
            <span></span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      {inputType === "checkbox" ? (
        <div className="input-wrapper">
          <input {...inputProps} defaultChecked={defaultChecked} />
          {label}
        </div>
      ) : (
        <div className="input-wrapper">
          {label}
          {inputType === "select" ? (
            <select
              {...inputProps}
              multiple={multiple}
              defaultValue={defaultValue || inputProps.placeholder}
            >
              {inputProps.placeholder && (
                <option value="" disabled>
                  {inputProps.placeholder}
                </option>
              )}

              {selectOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : inputType === "textArea" ? (
            <textarea {...inputProps} {...rest} />
          ) : (
            <input {...inputProps} />
          )}
          {errors[field] && (
            <div className="error-text">
              <span></span>
              {errors[field].message ||
                t(`app.form.error.${errors[field].type}`)}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default InputFormComponent;
