import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../../utils/GlobalContext";

import { postApiData } from "../../../../services/ApiApis";

import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";

const CreateNewAPI = () => {

	const navigate = useNavigate();

	const { accessToken, userEmail, setLoggedOut } = useGlobalVar();

	const [apiData, setApiData] = useState({
		nameApis: "",
		description: "",
		enabled: true,
	});

	const handleCancel = () => {
		window.location.reload();
	};

	const handleChange = (name, value) => {
		setApiData((prevApiData) => ({
			...prevApiData,
			[name]: value,
		}));
	};

	const handleToggleChange = () => {
		if (apiData.enabled) {
			handleChange("enabled", true);
		} else {
			handleChange("enabled", false);
		}
	};

	const logout = () => {
		setLoggedOut();
		navigate("/logout");
	}

	const handleAddAPI = async (e) => {
		if (e) e.preventDefault();

		try {
			const result = await postApiData(apiData, accessToken, userEmail);

			localStorage.setItem('reloadAPIAdded', 'true');

			window.location.reload();
			return result;
		} catch (error) {
			if(error.response.status === 403){
				logout();
			}
			console.error("Error:", error);
		}
	};

	return (
		<div>
			<div className="api-management">
				<div className="request-subscription__heading mg mg--t-4">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent text={"Back to APIs list"} />
					</div>
					<h1 className="mg mg--b-4">Add new API</h1>
					<div className="input-content p p--b-2">
						<div className="api-management__form">
							<InputComponent
								type="text"
								name="nameApis"
								label="Name"
								placeholder={"Insert API name"}
								value={apiData.nameApis}
								className={"input--large"}
								onChange={handleChange}
							/>
							<InputComponent
								type="text"
								name="description"
								label="Brief description"
								placeholder={
									"Insert a short description about API"
								}
								value={apiData.description}
								className={"input--large"}
								onChange={handleChange}
							/>
							<InputComponent
								type="toggle"
								name="enabled"
								label="Active API to use it"
								value={apiData.enabled}
								onChange={handleToggleChange}
							/>
						</div>
					</div>
					<DividerComponent />
					<div className="pair-buttons mg mg--t-4">
						<ButtonComponent
							text={"Cancel"}
							onClick={handleCancel}
							color={"button-component--reject"}
						/>
						<ButtonComponent
							text={"Add API"}
							onClick={handleAddAPI}
							color={"button-component"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateNewAPI;
