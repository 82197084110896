import React from "react";
import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ApiSubscriptionStateComponentAdmin from "../../../atoms/api-subscription-state-component-admin/ApiSubscriptionStateComponentAdmin";


const DeleteAuditDetails = ({ jsonData }) => {

	return (
		<div className="audit-details__data">
			<div>

				{jsonData
					? jsonData?.subscriptions.map(
							(item, index) => (
								<div key={index}>
									<div>
										<h2 className="mg mg--b-4">
											Request for {item.environment}{" "}
											environment
										</h2>
										<p>APIs subscription</p>
										<div className="request-detail__information">
											<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
												<div className="input-content__checkboxes__checkbox__text">
													<div className="input-content__checkboxes__checkbox__text__title">
														<span>
														API ISO20022
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__text__description">
														<span>
														Brief description of the API utility
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__subscription">
														<ApiSubscriptionStateComponentAdmin state={item?.subscribeAPI?.apiIso}/>
													</div>
												</div>
											</div>  
											{/* <div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
												<div className="input-content__checkboxes__checkbox__text">
													<div className="input-content__checkboxes__checkbox__text__title">
														<span>
														API R-Transactions
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__text__description">
														<span>
														Brief description of the API utility
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__subscription">
														<ApiSubscriptionStateComponentAdmin state={item?.subscribeAPI?.apiRTran}/>
													</div>
												</div>
											</div> 
											<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
												<div className="input-content__checkboxes__checkbox__text">
													<div className="input-content__checkboxes__checkbox__text__title">
														<span>
														API Customer Portal
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__text__description">
														<span>
														Brief description of the API utility
														</span>
													</div>
													<div className="input-content__checkboxes__checkbox__subscription">
														<ApiSubscriptionStateComponentAdmin state={item?.subscribeAPI?.apiCustomer}/>
													</div>
												</div>
											</div>   */}
										</div>
										{item.environment === "PRE" ? 
											<> 
												{item?.publicKey ? (
													<InputComponent
														type="textarea"
														name="publicKey"
														label="Public Key"
														value={`-----BEGIN PUBLIC KEY-----\n${item?.publicKey}\n-----END PUBLIC KEY-----`}
														disabled={true}
														className={"input--extralarge"}
													/>
												) : null}
											</> : null										
										}
										
										{item?.keyId ? (
											<InputComponent
												type="text"
												name="keyID"
												label="Key ID"
												value={item?.keyId}
												disabled={true}
												className={"input--medium"}
											/>
										) : null}

										{item?.insertIssuer ? (
											<InputComponent
												type="text"
												name="issuer"
												label="Issuer"
												value={item?.insertIssuer}
												disabled={true}
												className={"input--medium"}
											/>
										) : null}
									</div>

									<DividerComponent />

									{item.environment === "LIVE" ? (
										<>
											<div>
												<h3 className="mg mg--b-4">
													Notifier endpoint for LIVE
												</h3>
												{/* NOTIFICATIONS TYPE */}
												{item?.webhoot ? (
													<InputComponent
														type="text"
														name="notificationsType"
														label="Notifications type"
														value={"Webhook"}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.statusReport ? (
													<InputComponent
														type="text"
														name="notificationsType"
														label="Notifications type"
														value={
															"Status report hub messages"
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}

												{/* NOTIFIER SECURITY */}
												{item?.oauthSecurity ? (
													<InputComponent
														type="text"
														name="notifierSecurity"
														label="Notifier security"
														value={"OAuth 2.0"}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.basicSecurity ? (
													<InputComponent
														type="text"
														name="notifierSecurity"
														label="Notifier security"
														value={"HTTP Basic"}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{/* {item?.notSecurity ? (
													<InputComponent
														type="text"
														name="notifierSecurity"
														label="Notifier security"
														value={
															"Without security"
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null} */}

												{/* OAUTH SECURITY PARAMS */}
												{item?.oauthParam
													?.notificationUrl ? (
													<InputComponent
														type="text"
														name="funtionalEndpoint"
														label="Funtional endpoint"
														value={
															item?.oauthParam
																?.notificationUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.oauthParam?.clientID ? (
													<InputComponent
														type="text"
														name="webhook"
														label="Webhook Client ID"
														value={
															item?.oauthParam
																?.clientID
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.oauthParam
													?.clientSecret ? (
													<InputComponent
														type="text"
														name="clientSecret"
														label="Client Secret"
														value={
															item?.oauthParam
																?.clientSecret
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.oauthParam?.tokenUrl ? (
													<InputComponent
														type="text"
														name="tokenURI"
														label="Token URI"
														value={
															item?.oauthParam
																?.tokenUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}

												{/* BASIC SECURITY PARAMS */}
												{item?.basicSecurityParam
													?.notificationUrl ? (
													<InputComponent
														type="text"
														name="funtionalEndpoint"
														label="Funtional endpoint"
														value={
															item
																?.basicSecurityParam
																?.notificationUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.basicSecurityParam
													?.user ? (
													<InputComponent
														type="text"
														name="user"
														label="User"
														value={
															item
																?.basicSecurityParam
																?.user
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}
												{item?.basicSecurityParam
													?.pass ? (
													<InputComponent
														type="text"
														name="password"
														label="Password"
														value={
															item
																?.basicSecurityParam
																?.pass
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null}

												{/* WITHOUT SECURITY PARAMS */}
												{/* {item?.notSecurityParam
													?.tokenUrl ? (
													<InputComponent
														type="text"
														name="tokenURI"
														label="Token URI"
														value={
															item
																?.notSecurityParam
																?.tokenUrl
														}
														disabled={true}
														className={
															"input--medium"
														}
													/>
												) : null} */}
											</div>
											<DividerComponent />
										</>
									) : null}

									
										
									<div>
										<h3 className="mg mg--b-4">
											Confidential information
											from PagoNxt (
											{item.environment})
										</h3>
										<div className="subscribe-request-details__information-inputs">
											<div className="input-content">
												<div className="input-content__title">
													<span>
														Client ID
													</span>
												</div>
												<label>
													<input
														type="text"
														name="clientAppId"
														value={
															item.clientAppId
														}
														placeholder={""}
														autoComplete="off"
														disabled={true}
													/>
												</label>
											</div>
											<div className="input-content">
												<div className="input-content__title">
													<span>Secret</span>
												</div>
												<label>
													<input
														type="text"
														name="secret"
														value={
															item.secret
														}
														placeholder={""}
														autoComplete="off"
														disabled={true}
													/>
												</label>
											</div>

											{
												item.environment === 'SANDBOX' ?
												<>
													<div className="input-content">
														<div className="input-content__title">
															<span>URL</span>
														</div>
														<label>
															<input
																type="text"
																name="url"
																value={
																	item.url
																}
																placeholder={""}
																autoComplete="off"
																disabled={true}
															/>
														</label>
													</div>
													<div className="input-content">
														<div className="input-content__title">
															<span>Predefined Bearer token</span>
														</div>
														<label>
															<input
																type="text"
																name="url"
																value="7f697073-cddf-4b51-995f-aab611fe8ae9"
																placeholder={""}
																autoComplete="off"
																disabled={true}
															/>
														</label>
													</div>
												</>
												 : null
											}
										</div>
									</div>

									{
										index !== jsonData.subscriptions.length - 1 ? 

											<DividerComponent />

										: null
									}
									
										
								</div>
							)
					  )
					: null
				}
				
			</div>
		</div>
	);
};

export default DeleteAuditDetails;
