import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const getUsersList = async (accessToken) => {
	try {
		const response = await axios.get(`${API_URL}/api/v1/getAllUser`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `${accessToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const getMasterList = async (accessToken) => {
	try {
		const response = await axios.get(`${API_URL}/users?type=MASTER`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `${accessToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const getAdminEmails = async (accessToken) => {
	try {
		const response = await axios.get(`${API_URL}/api/v1/organizations/mail/0`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `${accessToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const updateAdminEmails = async (userData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/organizations/mail/0/list`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const sendSupportForm = async (formData, files, userEmail, accessToken) => {
    const data = new FormData();
    data.append("support", JSON.stringify(formData));

    // Agregar archivos adjuntos
    files.forEach((file) => {
        data.append("attach_files", file);
    });

    try {
        const response = await axios.post(
            `${API_URL}/api/v1/support`,
            data,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    email: userEmail,
                    Authorization: accessToken,
                },
            }
        );

        if (response.status !== 200 && response.status !== 201) {
            throw new Error("Network response was not ok");
        }

        return response; // Devuelve la respuesta para que pueda ser utilizada en handleSubmit
    } catch (error) {
        console.error("Error al enviar el formulario:", error);
        throw error; // Propagar el error para manejarlo en el componente
    }
};

export const sendBecomeCustomerRequest = async (formData, files, accessToken) => {
    const updatedFormData = {
        ...formData,
        nameOrganization: formData.companyName,
    };

    const data = new FormData();
    
    // Agregar todos los campos del formulario
    const jsonBlob = new Blob([JSON.stringify(updatedFormData)], {
        type: "application/json",
    });
    data.append("data", jsonBlob);

    // Agregar archivos adjuntos
    files.forEach((file) => {
        data.append("files", file);
    });

    try {
        const response = await axios.post(`${API_URL}/api/v1/plan/upgrade`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: accessToken,
            },
        });

        if (response.status !== 200 && response.status !== 201) {
            throw new Error("Network response was not ok");
        }

        return response; // Devuelve la respuesta para que pueda ser utilizada en handleSubmit
    } catch (error) {
        console.error("Error al enviar el formulario:", error);
        throw error; // Propagar el error para manejarlo en el componente
    }
};

// Función para obtener los detalles del cliente por ID
export const getCustomerDetails = async (idNewCustomer, email, accessToken) => {
    try {
        const config = {
            headers: {
                email: email,
                Authorization: accessToken,
            },
        };

        const response = await axios.get(
            `${API_URL}/api/v1/plan/upgrade/${idNewCustomer}`,
            config
        );

        if (response.status !== 200 && response.status !== 201) {
            throw new Error("Error fetching customer details");
        }

        return response.data; // Devolvemos los datos del cliente
    } catch (error) {
        console.error("Error al obtener los detalles del cliente:", error);
        throw error; // Propagar el error para manejarlo donde se llame la función
    }
};

// Función para descargar archivos por organizationCode y fileId
export const downloadCustomerFiles = async (organizationCode, filesUploaded, email, accessToken) => {
    try {
        const config = {
            headers: {
                email: email,
                Authorization: accessToken,
            },
        };

        // Realiza múltiples llamadas GET para cada archivo en filesUploaded
        const fileDownloadPromises = filesUploaded.map((file) =>
            axios.get(
                `${API_URL}/api/v1/plan/upgrade/${organizationCode}/${file.id}?download`,
                config
            )
        );

        // Espera a que todas las llamadas GET se completen
        const fileResponses = await Promise.all(fileDownloadPromises);

        // Mapea las respuestas para obtener solo el contenido necesario
        const downloadedFiles = fileResponses.map((fileResponse, index) => ({
            fileContent: fileResponse.data.file,
            fileName: filesUploaded[index].fileName,
        }));

        return downloadedFiles; // Devuelve los archivos descargados
    } catch (error) {
        console.error("Error al descargar los archivos:", error);
        throw error; // Propagar el error para manejarlo donde se llame la función
    }
};

export const getBecomeCustomerSended = async (data, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/plan/upgrade?validate`,
			data,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const getUserData = async (userId, accessToken) => {
	try {
		const response = await axios.get(`${API_URL}/users/${userId}?userActiveInfo`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `${accessToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};
