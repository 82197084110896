import React, { useEffect, useState } from "react";
import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";
import SearchComponent from "../../../molecules/search-component/SearchComponent";
import DateFilterComponent from "../../../molecules/date-filter-component/DateFilterComponent";
import { getAuditList } from "../../../../services/ApiAudit";
import { useNavigate } from "react-router-dom";
import { AUDIT_DETAILS } from "../../../utils/Routes";
import { useGlobalVar } from "../../../utils/GlobalContext";

const AuditManagement = () => {
	const { accessToken } = useGlobalVar();
	const requesUserHeader = [
		"Type",
		"Status",
		"Organization",
		"Name",
		"Lastname",
		"Email",
		"Date",
	];
	const headerToFieldMap = {
		Type: "typeAudit",
		Status: "status",
		Organization: "organizations",
		Name: "firstname",
		Lastname: "lastname",
		Email: "email",
		Date: "date_created",
	};

	const columnWidths = ["15%", "15%", "20%", "15%", "15%", "15%", "20%"];

	const [auditData, setAuditData] = useState([]);
	const [sortedData, setSortedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [searchTerm, setSearchTerm] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [sortColumn, setSortColumn] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const filterType = "";

	useEffect(() => {
		const fetchData = async () => {
			try {
				const requestListData = await getAuditList(accessToken);

				// const reverseData = requestListData.reverse();
				const reverseData = requestListData;

				setAuditData(reverseData);

			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [accessToken]);

	useEffect(() => {
		setSortedData(auditData);
	}, [auditData]);

	const getTypeColor = (type) => {
		switch (type) {
			case "SUBSCRIBE":
				return "#D6E0FF"; // Morado
			case "SUPPORT":
				return "#DDE7EE"; // Gris
			case "DELETE":
				return "#F7C5C5"; // Rojo
			case "MODIFY":
				return "#FCE1C2"; // Amarillo
			case "NEW_CUSTOMER":
				return "#D9F4EB"; // Verde
			default:
				return "#fff"; // Color por defecto
		}
	};

	const handleSort = (header) => {
		const field = headerToFieldMap[header] || header;
		const newSortOrder =
			sortColumn === header && sortOrder === "asc" ? "desc" : "asc";
		const sorted = [...sortedData].sort((a, b) => {
			if (a[field] < b[field]) return newSortOrder === "asc" ? -1 : 1;
			if (a[field] > b[field]) return newSortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setSortColumn(header);
		setSortOrder(newSortOrder);
		setSortedData(sorted);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleItemsPerPageChange = (newItemsPerPage) => {
		setItemsPerPage(newItemsPerPage);
		setCurrentPage(1);
	};

	const handleSearch = (term) => {
		setSearchTerm(term);
	};

	const handleDateRangeChange = (start, end) => {
		setStartDate(start);
		setEndDate(end);
	};

	const navigate = useNavigate();
	const handleRowClick = (rowIndex) => {
		const globalIndex = (currentPage - 1) * itemsPerPage + rowIndex;
		const rowData = filteredData[globalIndex];
		navigate(AUDIT_DETAILS, { state: { rowData } });
	};

	// Filtrado manteniendo los valores originales
	const filteredData = sortedData.filter((item) => {
		const itemDate = new Date(item.date);
		const start = startDate ? new Date(startDate) : null;
		const end = endDate ? new Date(endDate) : null;
		return (
			(!filterType || item.typeAudit === filterType) &&
			(item.typeAudit?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				item.firstname
					?.toLowerCase()
					.includes(searchTerm.toLowerCase()) ||
				item.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				item.organizations
					?.toLowerCase()
					.includes(searchTerm.toLowerCase())) &&
			(!start || itemDate >= start) &&
			(!end || itemDate <= end)
		);
	});

	const currentData = filteredData.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	// Renderizado de las filas con los estilos aplicados
	const renderTableData = currentData.map((item) => ({
		...item,
		date_created: item.date_created.split("T")[0], // Tomar solo la fecha antes de la T
		status: (
			<span>
				<span
					style={{
						display: "inline-block",
						width: "10px",
						height: "10px",
						borderRadius: "50%",
						backgroundColor:
							item.status === "Done" ? "#47D7AC" : "#C41C1C",
						marginRight: "8px",
					}}
				></span>
				{item.status}
			</span>
		),
		typeAudit: (
			<span
				style={{
					display: "inline-block",
					padding: "4px 8px",
					borderRadius: "16px",
					backgroundColor: getTypeColor(item.typeAudit),
					color: "black",
				}}
			>
				{item.typeAudit}
			</span>
		),
	}));

	return (
		<div className="request-subscription request-management admin-management">
			<div className="request-subscription__heading mg mg--t-4">
				<h1>Audit Log</h1>
			</div>
			<div className="request-subscription__form">
				<div className="request-management__actions">
					<SearchComponent onSearch={handleSearch} />
					<div className="request-management__filters">
						<DateFilterComponent
							onDateRangeChange={handleDateRangeChange}
						/>
					</div>
				</div>

				<div className="mg mg--t-2">
					<TableComponent
						headers={requesUserHeader}
						headerToFieldMap={headerToFieldMap}
						data={renderTableData} // Pasamos los datos transformados aquí
						onSort={handleSort}
						sortColumn={sortColumn}
						sortOrder={sortOrder}
						onRowClick={handleRowClick}
						combineName={true}
						showBtn={false}
						columnWidths={columnWidths}
					/>
					<PaginationComponent
						currentPage={currentPage}
						totalPages={Math.ceil(filteredData.length / itemsPerPage)}
						onPageChange={handlePageChange}
						itemsPerPage={itemsPerPage}
						onItemsPerPageChange={handleItemsPerPageChange}
					/>
				</div>
				
			</div>
		</div>
	);
};

export default AuditManagement;
