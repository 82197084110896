import React from "react";

// Función para igualar headers con atributos del data
const normalizeKey = (key) => key.replace(/\s+/g, "").toLowerCase();

const getRoleClass = (role) => {
	switch (role.toLowerCase()) {
		case "admin":
			return "role-admin";
		case "developer":
			return "role-developer";
		default:
			return "";
	}
};

const TableComponent = ({
	headers,
	data,
	onSort,
	sortColumn,
	sortOrder,
	onRowClick,
	onButtonClick,
	showBtn = false,
	headerToFieldMap,
	organizationTypeMap,
	columnWidths = [], // Anchos de columna dinámicos
}) => {
	return (
		<div className="table-container">
			<table className="table-component">
				<thead>
					<tr>
						{headers.map((header, index) => (
							<th
								key={index}
								onClick={() => onSort(header)}
								style={{
									flexBasis: columnWidths[index] || "auto",
								}}
							>
								{header}{" "}
								{sortColumn === header &&
									(sortOrder === "asc" ? "▲" : "▼")}
							</th>
						))}
						{showBtn && <th></th>}
					</tr>
				</thead>
				<tbody>
					{data.map((row, rowIndex) => (
						<tr
							key={rowIndex}
							onClick={
								onRowClick ? () => onRowClick(rowIndex) : null
							}
						>
							{headers.map((header, colIndex) => {
								const field =
									headerToFieldMap[header] ||
									normalizeKey(header);
								let content = row[field];

								// Manejar el caso de Status
								if (header === "Status") {
									content = content ? "Active" : "Inactive";
								}

								// Si el campo es codigoOrganization, obtén la descripción
								if (
									field === "codOrganization" &&
									organizationTypeMap[content]
								) {
									content = organizationTypeMap[content];
								}

								return (
									<td
										key={colIndex}
										style={{
											flexBasis:
												columnWidths[colIndex] ||
												"auto",
										}}
										className={colIndex === 0 ? "type" : ""}
									>
										<span
											className={
												(header.toLowerCase() === "role" || header.toLowerCase() === "rol")
													? getRoleClass(row[field])
													: ""
											}
										>
											{content}
										</span>
									</td>
								);
							})}
							{showBtn && (
								<td>
									<button
										onClick={(e) => {
											e.stopPropagation();
											onButtonClick(rowIndex);
										}}
										className="table-component__button"
									>
										<span className="icon-more"></span>
									</button>
								</td>
							)}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TableComponent;
