import React, { useEffect, useState } from "react";
import { useGlobalVar } from "../../../utils/GlobalContext";
import { getOrganizationsDetails } from "../../../../services/ApiOrganization";
import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";

const TeamOrganizationTable = ({ onRowClick }) => {
	const { accessToken } = useGlobalVar();
	const [organizationTeam, setOrganizationTeam] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [sortedData, setSortedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const searchTerm = "";
	const [loading, setLoading] = useState(true);

	const currentOrganizationId = localStorage.getItem('currentOrganizationId');

	const headerOrganizationData = [
		"Name",
		"Lastname",
		"Email",
		"Registration",
		"Rol",
	];

	const headerToFieldMap = {
		Name: "firstname",
		Lastname: "lastname",
		Email: "email",
		Registration: "active", // Asumiendo que el campo 'contract' no está en el JSON, lo sustituimos por 'active'
		Rol: "roles", // El campo de rol es una lista, así que lo mapeamos aquí
	};

	const columnWidths = ["20%", "20%", "25%", "20%", "15%"];

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const organizationDetailsData = await getOrganizationsDetails(
					currentOrganizationId,
					accessToken
				);

				const organizationTeamData = organizationDetailsData.users.map(
					(user) => ({
						...user,
						roles: user.roles[0]?.name || "Unknown", // Extraer el nombre del primer rol
					})
				);
				setOrganizationTeam(organizationTeamData);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		if (currentOrganizationId) {
			fetchData();
		}
	}, [currentOrganizationId, accessToken]);

	useEffect(() => {
		setSortedData(organizationTeam);
	}, [organizationTeam]);

	const handleSort = (header) => {
		const field = headerToFieldMap[header] || header;
		const newSortOrder =
			sortColumn === header && sortOrder === "asc" ? "desc" : "asc";
		const sorted = [...sortedData].sort((a, b) => {
			if (a[field] < b[field]) return newSortOrder === "asc" ? -1 : 1;
			if (a[field] > b[field]) return newSortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setSortColumn(header);
		setSortOrder(newSortOrder);
		setSortedData(sorted);
	};

	const handleButtonClick = (rowIndex) => {
		alert(`Button clicked in row ${rowIndex}`);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleItemsPerPageChange = (newItemsPerPage) => {
		setItemsPerPage(newItemsPerPage);
		setCurrentPage(1);
	};

	// const handleSearch = (term) => {
	// 	setSearchTerm(term);
	// };

	const filteredData = sortedData.filter((item) =>
		item.firstname.toLowerCase().includes(searchTerm.toLowerCase())
	);

	if (loading) {
		return <div>Loading...</div>;
	}

	const handleRowClick = (rowIndex) => {
		const user = organizationTeam[rowIndex];
		if (user) {
			onRowClick(user);
		}
	};

	return (
		<div>
			<TableComponent
				headers={headerOrganizationData}
				data={filteredData}
				onSort={handleSort}
				sortColumn={sortColumn}
				sortOrder={sortOrder}
				onButtonClick={handleButtonClick}
				onRowClick={handleRowClick}
				showBtn={false}
				headerToFieldMap={headerToFieldMap}
				columnWidths={columnWidths}
			/>
			<PaginationComponent
				currentPage={currentPage}
				totalPages={Math.ceil(sortedData.length / itemsPerPage)}
				onPageChange={handlePageChange}
				itemsPerPage={itemsPerPage}
				onItemsPerPageChange={handleItemsPerPageChange}
			/>
		</div>
	);
};

export default TeamOrganizationTable;
