import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../../utils/GlobalContext";

import {
	getSubscriptionByEnviroment,
	updateSuscription,
} from "../../../../services/ApiSubscriptions";

import { error as showError, success as showSuccess } from "../../../utils/NotificationsContainer";

import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import RejectRequest from "../../../organisms/reject-request/RejectRequest";
import ApiSubscriptionStateComponentAdmin from "../../../atoms/api-subscription-state-component-admin/ApiSubscriptionStateComponentAdmin";

import { REQUESTS_MANAGEMENT } from "../../../utils/Routes";

const SubscribeRequestDetails = ({ currentData }) => {
	const { accessToken, userEmail } = useGlobalVar();

	const navigate = useNavigate();

	const [requestResponse, setRequestResponse] = useState({});

	const [informationInputs, setInformationInputs] = useState(false);

	const [confidentialData, setConfidentialData] = useState({
		clientAppId: "",
		secret: "",
	});

	const [showModal, setShowModal] = useState(false);
	const [rejectMessage, setRejectMessage] = useState("");

	// const [apiISO, setApiISO] = useState("");
	// const [apiRTran, setApiRTran] = useState("");
	// const [apiCustomer, setApiCustomer] = useState("");

	const [loading, setLoading] = useState(true);

	const closeModal = () => setShowModal(false);
	const openModal = () => setShowModal(true);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setConfidentialData((prevFormData) => {
			const keys = name.split(".");
			let newFormData = { ...prevFormData };
			let nested = newFormData;

			for (let i = 0; i < keys.length - 1; i++) {
				nested = nested[keys[i]];
			}

			nested[keys[keys.length - 1]] = value;

			return newFormData;
		});
	};

	const handleInformation = () => {
		setInformationInputs(true);
	};

	const handleAprobar = async (e) => {
		e.preventDefault();

		// Combina los datos de requestResponse con confidentialData
		const updatedData = {
			...requestResponse,
			...confidentialData,
			nameOrganization: currentData.organization,
			type: "SUBSCRIBED",
			done: true,
			rejected: false
		};

		if (updatedData.clientAppId !== "" && updatedData.secret !== "") {
			try {
				await updateSuscription(updatedData, userEmail, accessToken);
				showSuccess('Updated request');
				navigate(REQUESTS_MANAGEMENT);
			} catch (error) {
				showError('There was a problem, please try again');
				console.error(error);
			}
		}
		else{
			showError('Add confidential information first');
		}
	};

	const handleReject = async (e) => {
		e.preventDefault();

		const updatedData = {
			...requestResponse,
			...confidentialData,
			nameOrganization: currentData.organization,
			type: "SUBSCRIBED",
			rejected: true,
			done: false
		};

		updatedData.mensaje = rejectMessage;
		try {
			await updateSuscription(updatedData, userEmail, accessToken);
			showSuccess('Updated request');
			navigate(REQUESTS_MANAGEMENT);
		} catch (error) {
			showError('There was a problem, please try again');
			console.error(error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true); // Iniciar el estado de loading

			try {
				const organization = currentData.organization;
				const nameApp = currentData.nameApp;
				const environment = currentData.environments;
				const type = currentData.type;

				const response = await getSubscriptionByEnviroment(
					organization,
					nameApp,
					environment,
					type,
					userEmail,
					accessToken
				);

				setRequestResponse(response.subscriptionStateList[0]);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false); // Finalizar el estado de loading
			}
		};

		fetchData();
	}, [currentData, userEmail, accessToken]);

	useEffect(() => {
		if (requestResponse.subscribeAPI) {
			const handleApiStatus = () => {
				// let statusApiIso = requestResponse.subscribeAPI.apiIso;
				// let statusApiRTran = requestResponse.subscribeAPI.apiRTran;
				// let statusApiCustomer =
				// 	requestResponse.subscribeAPI.apiCustomer;

				// if (statusApiIso === "PENDING") {
				// 	setApiISO("Request to subscribe");
				// } else if (statusApiIso === "SUBSCRIBED") {
				// 	setApiISO("Subscribed");
				// }

				// if (statusApiRTran === "PENDING") {
				// 	setApiRTran("Request to subscribe");
				// } else if (statusApiRTran === "SUBSCRIBED") {
				// 	setApiRTran("Subscribed");
				// }

				// if (statusApiCustomer === "PENDING") {
				// 	setApiCustomer("Request to subscribe");
				// } else if (statusApiRTran === "SUBSCRIBED") {
				// 	setApiCustomer("Subscribed");
				// }
			};
			handleApiStatus();
		}
	}, [requestResponse]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div className="subscribe-request-details">
			<div>
				<h4 className="mg mg--b-4">
					Request for {requestResponse.environment} environment
				</h4>

				<div className="subscribe-request-details__apis">
					<div className="subscribe-request-details__apis__title">
						APIs subscription
					</div>
					<div className="subscribe-request-details__apis__content">
						{requestResponse?.subscribeAPI?.apiIso ===
							"PENDING" && (
							<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
								<div className="input-content__checkboxes__checkbox__text">
									<div className="input-content__checkboxes__checkbox__text__title">
										<span>
										API ISO20022
										</span>
									</div>
									<div className="input-content__checkboxes__checkbox__text__description">
										<span>
										Brief description of the API utility
										</span>
									</div>
									<div className="input-content__checkboxes__checkbox__subscription">
										<ApiSubscriptionStateComponentAdmin state={requestResponse?.subscribeAPI?.apiIso}/>
									</div>
								</div>
							</div>  
						)}
						{/* {requestResponse?.subscribeAPI?.apiRTran ===
						"PENDING" ? (
							<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
								<div className="input-content__checkboxes__checkbox__text">
									<div className="input-content__checkboxes__checkbox__text__title">
										<span>
										API R-Transactions
										</span>
									</div>
									<div className="input-content__checkboxes__checkbox__text__description">
										<span>
										Brief description of the API utility
										</span>
									</div>
									<div className="input-content__checkboxes__checkbox__subscription">
										<ApiSubscriptionStateComponentAdmin state={requestResponse?.subscribeAPI?.apiRTran}/>
									</div>
								</div>
							</div>  
						) : null} */}
						{/* {requestResponse?.subscribeAPI?.apiCustomer ===
						"PENDING" ? (
							<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
								<div className="input-content__checkboxes__checkbox__text">
									<div className="input-content__checkboxes__checkbox__text__title">
										<span>
										API Customer Portal
										</span>
									</div>
									<div className="input-content__checkboxes__checkbox__text__description">
										<span>
										Brief description of the API utility
										</span>
									</div>
									<div className="input-content__checkboxes__checkbox__subscription">
										<ApiSubscriptionStateComponentAdmin state={requestResponse?.subscribeAPI?.apiCustomer}/>
									</div>
								</div>
							</div>  
						) : null} */}
					</div>
				</div>

				{requestResponse?.publicKey ? (
					<InputComponent
						type="textarea"
						name="publicKey"
						label="Public Key"
						value={`-----BEGIN PUBLIC KEY-----\n${requestResponse?.publicKey}\n-----END PUBLIC KEY-----`}
						disabled={true}
						className={"input--extralarge"}
					/>
				) : null}

				{requestResponse?.keyId ? (
					<InputComponent
						type="text"
						name="keyID"
						label="Key ID"
						value={requestResponse?.keyId}
						disabled={true}
						className={"input--medium"}
					/>
				) : null}

				{requestResponse?.insertIssuer ? (
					<InputComponent
						type="text"
						name="issuer"
						label="Issuer"
						value={requestResponse?.insertIssuer}
						disabled={true}
						className={"input--medium"}
					/>
				) : null}
			</div>
			<DividerComponent />
			{requestResponse.environment === "LIVE" ? (
				<>
					<div>
						<h3 className="mg mg--b-4">Notifier endpoint</h3>
						{/* NOTIFICATIONS TYPE */}
						{requestResponse?.webhoot ? (
							<InputComponent
								type="text"
								name="notificationsType"
								label="Notifications type"
								value={"Webhook"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.statusReport ? (
							<InputComponent
								type="text"
								name="notificationsType"
								label="Notifications type"
								value={"Status report hub messages"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* NOTIFIER SECURITY */}
						{requestResponse?.oauthSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"OAuth 2.0"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.basicSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"HTTP Basic"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{/* {requestResponse?.notSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"Without security"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null} */}

						{/* OAUTH SECURITY PARAMS */}
						{requestResponse?.oauthParam?.notificationUrl ? (
							<InputComponent
								type="text"
								name="funtionalEndpoint"
								label="Funtional endpoint"
								value={
									requestResponse?.oauthParam?.notificationUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.oauthParam?.clientID ? (
							<InputComponent
								type="text"
								name="webhook"
								label="Webhook Client ID"
								value={requestResponse?.oauthParam?.clientID}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.oauthParam?.clientSecret ? (
							<InputComponent
								type="text"
								name="clientSecret"
								label="Client Secret"
								value={
									requestResponse?.oauthParam?.clientSecret
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.oauthParam?.tokenUrl ? (
							<InputComponent
								type="text"
								name="tokenURI"
								label="Token URI"
								value={requestResponse?.oauthParam?.tokenUrl}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* BASIC SECURITY PARAMS */}
						{requestResponse?.basicSecurityParam
							?.notificationUrl ? (
							<InputComponent
								type="text"
								name="funtionalEndpoint"
								label="Funtional endpoint"
								value={
									requestResponse?.basicSecurityParam
										?.notificationUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.basicSecurityParam?.user ? (
							<InputComponent
								type="text"
								name="user"
								label="User"
								value={
									requestResponse?.basicSecurityParam?.user
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{requestResponse?.basicSecurityParam?.pass ? (
							<InputComponent
								type="text"
								name="password"
								label="Password"
								value={
									requestResponse?.basicSecurityParam?.pass
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* WITHOUT SECURITY PARAMS */}
						{/* {requestResponse?.notSecurityParam?.tokenUrl ? (
							<InputComponent
								type="text"
								name="tokenURI"
								label="Token URI"
								value={
									requestResponse?.notSecurityParam?.tokenUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null} */}
					</div>
					<DividerComponent />
				</>
			) : null}
			<div>
				<h3 className="mg mg--b-4">
					Add confidential information from PagoNxt
				</h3>
				{informationInputs ? (
					<div className="subscribe-request-details__information-inputs">
						<div className="input-content">
							<div className="input-content__title">
								<span>Client ID</span>
							</div>
							<label>
								<input
									type="text"
									name="clientAppId"
									value={confidentialData.clientAppId}
									placeholder={""}
									autoComplete="off"
									onChange={handleChange}
								/>
							</label>
						</div>
						<div className="input-content">
							<div className="input-content__title">
								<span>Secret</span>
							</div>
							<label>
								<input
									type="text"
									name="secret"
									value={confidentialData.secret}
									placeholder={""}
									autoComplete="off"
									onChange={handleChange}
								/>
							</label>
						</div>
					</div>
				) : (
					<ButtonComponent
						text={"Add PagoNxt information"}
						onClick={handleInformation}
						color={"button-component--neutral"}
					/>
				)}
			</div>
			<DividerComponent />
			<div className="pair-buttons">
				<ButtonComponent
					text={"Cancel"}
					onClick={openModal}
					color={"button-component--reject"}
				/>
				<ButtonComponent
					text={"Done"}
					onClick={handleAprobar}
					color={"button-component"}
				/>
			</div>

			<ModalComponent show={showModal} onClose={closeModal} size={"lg"}>
				<RejectRequest
					onMessageChange={setRejectMessage}
					rejectRequest={handleReject}
					onClose={closeModal}
				/>
			</ModalComponent>
		</div>
	);
};

export default SubscribeRequestDetails;
