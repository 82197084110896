import React from "react";
import { useNavigate } from "react-router-dom";

function ButtonComponent({ text, color, bgColor, url, onClick, icon }) {
	let navigate = useNavigate();

	const handleClick = (e) => {
		if (onClick) {
			e.preventDefault();
			onClick(e);
		} else if (url) {
			navigate(url);
		}
	};

	return (
		<button onClick={handleClick}>
			<div
				className={`button-component ${color ? color : ""} ${
					bgColor ? bgColor : ""
				}`}
			>
				{icon && (
					<img
						src={icon}
						alt="icon"
						className="button-component__icon"
					/>
				)}
				{text}
			</div>
		</button>
	);
}

export default ButtonComponent;
