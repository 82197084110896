import React, { useState, useRef } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";

const DateRangeFilter = ({ onDateRangeChange }) => {
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});
	const [open, setOpen] = useState(false);
	const ref = useRef(null);

	const handleSelect = (ranges) => {
		const selectedRange = ranges.selection;
		if (selectedRange) {
			setSelectionRange(selectedRange);
			onDateRangeChange(selectedRange.startDate, selectedRange.endDate);
			setOpen(false);
		} else {
			console.error("No selection found in ranges:", ranges);
		}
	};

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			setOpen(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div ref={ref} className="input-daterange">
			<input
				type="text"
				readOnly
				value={`${format(
					selectionRange.startDate,
					"MM/dd/yyyy"
				)} - ${format(selectionRange.endDate, "MM/dd/yyyy")}`}
				onClick={() => setOpen(!open)}
				style={{ cursor: "pointer" }}
			/>
			{open && (
				<div style={{ position: "absolute", zIndex: 1 }}>
					<DateRange
						ranges={[selectionRange]}
						onChange={handleSelect}
						moveRangeOnFirstSelection={false}
					/>
				</div>
			)}
		</div>
	);
};

export default DateRangeFilter;
