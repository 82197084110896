import React from "react";
import logo from "../assests/logo.svg";
import { Outlet } from "react-router";
import "./FullScreenLayout.css";

import LinkComponent from '../atoms/link-component/LinkComponent';

import {
  LEGAL_NOTICE,
  COOKIE_POLICY,
  CODE_CONDUCT,
  PRIVACY_NOTICE
} from "../utils/Routes";

function LegalFullScreenLayout() {

  return (
    <>
      {/* HEADER   */}
      <header className="header--logged">
        <div className="header--logged__container">
          <div className="header--logged__logo">
              <img src={logo} alt="" />
          </div>
          {/* <div className="header--logged__buttons">
          
              <div className="header--logged__buttons__links">
                <div>
                  <LinkComponent text={'Developer portal'} icon={'icon-dev'} url={DEVHUB} externalURL={true}/>
                </div>
                <div onClick={openModal}>
                  <LinkComponent text={'Support'} icon={'icon-support'}/>
                </div>
                {
                  !userData?.client ?
                  <div onClick={() => setShowBecomeCustomerModal(true)}>
                    <LinkComponent text={'Upgrade your plan'} icon={'icon-spark'} filled/>
                  </div> : null
                }
                
              </div>
              <div className="header--logged__buttons__menu-logged">
                <MenuLoggedComponent name={'Name Lastname'} email={'name.lastname@domain.com'}/>
              </div>
          
          </div> */}
       </div>
      </header>

      <main>
        <div className="body-container">
          <div className="wrapper">
            <Outlet/>
          </div>
        </div>
      </main>

      {/* <ModalComponent show={showModal} onClose={closeModal} size={'lg'}>
        <FormSupport onClose={closeModal}/>
      </ModalComponent>

      <ModalComponent show={showBecomeCustomerModal} onClose={() => setShowBecomeCustomerModal(false)} size={'xl'}>
        <FormBecomeCustomer onClose={() => setShowBecomeCustomerModal(false)}/>
      </ModalComponent> */}

      {/* FOOTER */}
      <footer>
        <div className="footer">
          <div className="footer__top">
            <div className="footer__top__copyright">
              <p>© 2024 PagoNxt Payments</p>
            </div>
            <div className="footer__top__links">
              <LinkComponent text={'Privacy policy'} url={PRIVACY_NOTICE} externalURL={true}/>
              <LinkComponent text={'Legal notice'} url={LEGAL_NOTICE} externalURL={true}/>
              <LinkComponent text={'Cookie Policy'} url={COOKIE_POLICY} externalURL={true}/>
              <LinkComponent text={'Code of Conduct and Canal Abierto'} url={CODE_CONDUCT} externalURL={true}/>
            </div>
          </div>
          <div className="footer__bottom">
              <p className="footer__bottom__disclaimer">
                DISCLAIMER - PagoNxt Trade Services, S.L. is a Spanish company domiciled in Avda. de Cantabria s/n, Ciudad Grupo Santander, 28660, Boadilla del Monte (Madrid) and with tax identification number B-87959326. PagoNxt Trade Services, S.L. is registered in the Corporate Registry of Madrid, volume 36500, sheet 204, page M-655666.
              </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default LegalFullScreenLayout;
