import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../utils/GlobalContext";

import ApiSubscriptionStateComponent from "../../atoms/api-subscription-state-component/ApiSubscriptionStateComponent";
import DividerComponent from "../../atoms/divider-component/DividerComponent";

import { REQUEST_MODIFICATION, REQUEST_MODIFICATION_ADMIN } from "../../utils/Routes";

function AppList({ appListContent, onTab, organization }) {

	const { role } = useGlobalVar();

	const navigate = useNavigate();

	const navigateToDetails = (currentEnv, appName) => {
		navigate((role === 'DEVELOPER' || role === 'ADMIN') ? REQUEST_MODIFICATION : REQUEST_MODIFICATION_ADMIN, {
			state: { env: currentEnv, appName: appName, organization: organization },
		});
	};

	if (!Array.isArray(appListContent)) {
		return <div>Cargando...</div>; // Puedes manejarlo de la forma que quieras
	}

	return (
		<>
			<div className={`app-list ${onTab ? 'tab-container' : null}`}>
				<div className="app-list__content">
					{appListContent
						? appListContent.map((item, index) => (
								<div key={index}>
									<div className="app-list__content__item">
										<h3 className="app-list__content__item__name">
											{item.name}
										</h3>

										<div className="app-list__content__item__envs">
											{item.subscriptions.map(
												(subscription, envIndex) => (
													<div
														className="app-list__content__item__envs__sub"
														key={envIndex}
														onClick={() =>
															navigateToDetails(
																subscription.environment,
																item.name
															)
														}
													>
														<div className="app-list__content__item__envs__sub__content">
															<div className="app-list__content__item__envs__sub__content__title">
																{
																	subscription.environment
																}{" "}
																{/* Asegúrate de que subscription.environment es un string */}
															</div>
															<ul className="app-list__content__item__envs__sub__content__apis">
																{Object.keys(
																	subscription
																		.subscriptions[0]
																		.subscribeAPI
																).map(
																	(
																		apiKey,
																		apiIndex
																	) => (
																			apiKey === 'apiIso' ? 
																				<li
																					className="app-list__content__item__envs__sub__content__apis__api"
																					key={
																						apiIndex
																					}
																				>
																				<div className="app-list__content__item__envs__sub__content__apis__api__name">
																					{
																						apiKey
																					}

																					:
																				</div>
																				<ApiSubscriptionStateComponent
																					state={
																						subscription
																							.subscriptions[0]
																							.subscribeAPI[
																							apiKey
																						]
																					}
																				/>
																			</li> : null
																	)
																)}
															</ul>
														</div>
													</div>
												)
											)}
										</div>
									</div>
									{index < appListContent.length - 1 ? (
										<DividerComponent />
									) : null}
								</div>
						  ))
						: "nada"}
				</div>
			</div>
		</>
	);
}

export default AppList;
