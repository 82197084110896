import React, { useState } from "react";
import { error as showError } from "../../utils/NotificationsContainer";
import { resetPassword } from "../../../services/ApiAuth";

const placeholders = {
	email: "Insert your email",
};

const ForgotPasswordPage = () => {
	const [passwordCreated, setPasswordCreated] = useState(false);
	const [error, setError] = useState(false);

	const [formData, setFormData] = useState({
		email: "",
	});

	const handleChange = (e) => {
		const { name, type, checked, value } = e.target;

		if (type === "checkbox") {
			setFormData({
				...formData,
				[name]: checked,
			});
		} else if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				setFormData({
					...formData,
					[name]: value,
				});
			}
		} else if (name === "countryIso") {
			const selectedCountryName =
				e.target.options[e.target.selectedIndex].text;
			setFormData({
				...formData,
				countryIso: value,
				country: selectedCountryName,
			});
		} else {
			setFormData((prevFormData) => {
				const keys = name.split(".");
				let newFormData = { ...prevFormData };
				let nested = newFormData;

				for (let i = 0; i < keys.length - 1; i++) {
					nested = nested[keys[i]];
				}

				nested[keys[keys.length - 1]] = value;

				return newFormData;
			});
		}
	};

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	  };

	const handleSubmit = async (e) => {
		e.preventDefault();

		const updatedData = {
			email: formData.email,
		};

		 // Validar el email antes de proceder
		 if (!validateEmail(updatedData.email)) {
			showError("Invalid email format");
			setError(true);
			return;
		}

		try {
			const response = await resetPassword(updatedData);
				setPasswordCreated(true);
			return response;
		} catch (error) {
			showError("There was a problem, please check input fields");
			console.error("Error:", error);
		}
	};

	if (passwordCreated) {
		return (
			<>
				<div className="congrats-message">
					<div className="congrats-message__title">
						Congratulations
					</div>
					<div className="congrats-message__subtitle">
						We've just sent you an email with instructions to create
						your password.
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="login-form login-form__forgot-password">
			<div className="login-full-screen-layout__form__logo"></div>
				<div className="login-form__title">Forgot your password?</div>
				<div className="login-form__subtitle">
					Please enter your email address to receive instructions for
					reseting your password
				</div>
				<div className="form-content">
					<form onSubmit={handleSubmit}>
						<div className="input-content">
							<div className="input-content__title">
								<span>Email</span>
							</div>
							<label>
								<input
									type="text"
									name="email"
									value={formData.email}
									placeholder={placeholders.email}
									autoComplete="off"
									onChange={handleChange}
								/>
							</label>
							{
								error ? 

								<div className="login-form__error">
									<span></span>
									Please, use a valid email address
								</div> : null
							}
						</div>

						<button
							type="submit"
							className="form-content__send send"
						>
							Send
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default ForgotPasswordPage;
