export const DEFAULT_FEED_PAGE_SIZE = 16;

const serverUrl =
	process.env.REACT_APP_BACKEND_API_URL || "http://127.0.0.1:8101";

export const signupRequest = (signupData) =>
	callAPI({
		url: `${serverUrl}/api/v1/signup-request/`,
		method: "POST",
		data: signupData,
		credentials: "same-origin",
	});

export const validateToken = (token) =>
	callAPI({
		url: `${serverUrl}/api/v1/signup-request/validate/${token}`,
		method: "GET",
		credentials: "same-origin",
	});

export const signup = (signupData) =>
	callAPI({
		url: `${serverUrl}/api/v1/signup`,
		method: "POST",
		data: signupData,
		credentials: "same-origin",
	});

export const login = (token) =>
	callAPI({
		url: `${serverUrl}/api/v1/login`,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});

export const logoutUser = (sessionId) =>
	callAPI({
		url: `${serverUrl}/api/v1/logout`,
		method: "POST",
		headers: {
			auth: `JSESSIONID=${sessionId}`,
		},
	});

export const callAPI = ({
	url,
	method = "GET",
	mode = "cors",
	cache = "no-cache",
	credentials = "include",
	headers,
	redirect = "manual",
	referrerPolicy = "no-referrer",
	data,
	isFile = false,
}) => {
	const defaultHeaders = {
		"Content-Type": "application/json",
		// 'Content-Type': 'application/x-www-form-urlencoded',
	};
	return fetch(url, {
		method, // *GET, POST, PUT, DELETE, etc.
		mode, // no-cors, *cors, same-origin
		cache, // default, *no-cache, reload, force-cache, only-if-cached
		credentials, // include, *same-origin, omit
		headers: isFile ? { ...headers } : { ...defaultHeaders, ...headers },

		body: isFile ? data : data && JSON.stringify(data), // body data type must match "Content-Type" header
	}).then((response) => {
		if (response?.status === 500) {
			throw new Error("Something went wrong.");
		}
		if (response?.status === 401) {
			throw new Error("Something went wrong.");
		}
		return response;
	});
};
