import { React, useState } from "react";

const TabsComponent = ({ tabs }) => {
	const [activeTab, setActiveTab] = useState(tabs[0].label);

	const handleTabClick = (label) => {
		setActiveTab(label);
	};

	return (
		<div className="tabs mg mg--t-1">
			<div className="tabs-header">
				{tabs.map((tab) => (
					<div
						key={tab.label}
						className={`tab ${
							tab.label === activeTab ? "active" : ""
						}`}
						onClick={() => handleTabClick(tab.label)}
					>
						{tab.label}
					</div>
				))}
			</div>
			<div className="tabs-content">
				{tabs.map((tab) => (
					<div
						key={tab.label}
						className={`tab-content ${
							tab.label === activeTab ? "active" : ""
						}`}
					>
						{tab.label === activeTab && <tab.component />}
					</div>
				))}
			</div>
		</div>
	);
};

export default TabsComponent;
