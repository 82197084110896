import React, { useState } from "react";

import TeamOrganizationTable from "../admin-area-team-table/TeamOrganizationTable";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import CreateUserOrganization from "../admin-area-create-user/CreateUserOrganization";
import UserOrganizationDetail from "../admin-area-team-detail/EditUserOrganization";

const TeamOrganization = () => {
	const [showFormUser, setShowFormUser] = useState(false);
	const [showUserDetail, setShowUserDetail] = useState(null);

	const handleClick = () => {
		setShowFormUser(true);
	};

	const handleUser = (user) => {
		setShowUserDetail(user);
	};

	return (
		<div>
			<div className="team-management">
				<div className="team-management__form">
					{showFormUser ? (
						<CreateUserOrganization />
					) : showUserDetail ? (
						<UserOrganizationDetail user={showUserDetail} />
					) : (
						<>
							<div className="team-management__heading mg mg--t-4">
								<h3>Team management</h3>
							</div>
							<div className="mg mg--t-2">
								<TeamOrganizationTable onRowClick={handleUser} />
							</div>
							<ButtonComponent
								text={"Add new user"}
								onClick={handleClick}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TeamOrganization;
