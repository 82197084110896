import React, { useState, useEffect } from "react";

// Componente individual para cada paso
const Step = ({ step, isActive, isCompleted, onClick }) => {
	return (
		<button
			className={`step ${isActive ? "active" : ""} ${
				isCompleted ? "completed" : ""
			}`}
			onClick={onClick}
		>
			<div className="step-step text-xl">{step.step}</div>
			<div className="step-content">
				<div className="step-title">
					<h4>{step.title}</h4>
					{isActive && <div className="icon-stepper"></div>}
				</div>
				<p className="step-description">{step.description}</p>
			</div>
		</button>
	);
};

// Componente Stepper principal
const StepperComponent = ({ title, steps }) => {
	const [showStepper, setShowStepper] = useState(true);

	useEffect(() => {
		// Al montar el componente, verificar si el stepper ya ha sido cerrado
		const stepperClosed = localStorage.getItem("stepperClosed");
		if (stepperClosed === "true") {
			setShowStepper(false);
		}
	}, []);

	const closeStep = () => {
		setShowStepper(false);
		// Guardar en localStorage que el stepper fue cerrado
		localStorage.setItem("stepperClosed", "true");
	};

	return (
		<>
			{showStepper ? (
				<div className="stepper">
					<div className="stepper__content">
						<div className="stepper-title">
							<h3>{title}</h3>
							<div
								onClick={closeStep}
								className="icon-close"
							></div>
						</div>

						<div className="steps">
							{steps.map((step, index) => (
								<Step
									key={index}
									step={step}
									isActive={index === 0}
									isCompleted={index < 0}
								/>
							))}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default StepperComponent;
