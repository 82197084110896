import React, { useState } from "react";

import { useLocation } from "react-router-dom";

import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import InputComponent from "../../../atoms/input-component/InputComponent";
import SubscribeRequestDetails from "../../admin-area-requests/admin-area-subscribe-details/SubscribeRequestDetails";
import SupportRequestDetails from "../../admin-area-requests/admin-area-support-details/SupportRequestDetails";
import ModifyRequestDetails from "../../admin-area-requests/admin-area-modify-details/ModifyRequestDetails";
import DeleteRequestDetails from "../../admin-area-requests/admin-area-delete-details/DeleteRequestDetails";
import NewCostumerRequestDetails from "../../admin-area-requests/admin-area-new-costumer-details/NewCostumerRequestDetails";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";

const RequestDetails = () => {
	const location = useLocation();
	const { rowData } = location.state;

	const [phone, setPhone] = useState("");

	const updatePhone = (newPhone) => {
		setPhone(newPhone); // Actualiza el estado de phone
	};

	const removeTime = (date) => {
		return date?.split(" ")[0];
	};

	const type = rowData.type;

	const renderRequest = (type) => {
		switch (type) {
			case "SUBSCRIBE":
				return <SubscribeRequestDetails currentData={rowData} />;

			case "SUPPORT":
				return (
					<SupportRequestDetails
						currentData={rowData}
						updatePhone={updatePhone}
					/>
				);

			case "MODIFY":
				return <ModifyRequestDetails currentData={rowData} />;

			case "DELETE":
				return <DeleteRequestDetails currentData={rowData} />;

			case "NEW_CUSTOMER":
				return (
					<NewCostumerRequestDetails
						currentData={rowData}
						updatePhone={updatePhone}
					/>
				);

			default:
				return <div>Error</div>;
		}
	};

	const getTypeColor = (type) => {
		switch (type) {
			case "SUBSCRIBE":
				return "#D6E0FF"; // Morado
			case "SUPPORT":
				return "#DDE7EE"; // Gris
			case "DELETE":
				return "#F7C5C5"; // Rojo
			case "MODIFY":
				return "#FCE1C2"; // Amarillo
			case "NEW_CUSTOMER":
				return "#D9F4EB"; // Verde
			default:
				return "#fff"; // Color por defecto
		}
	};

	return (
		<>
			{" "}
			{rowData ? (
				<div className="request-subscription">
					<div className="request-subscription__heading">
						<div className="request-subscription__heading__back-button">
							<BackButtonComponent
								text={"Back to user requests"}
							/>
						</div>
						<h1>Request from {rowData.organization}</h1>
						<div className="request-subscription__form">
							<div className="request-subscription__form__input__title p p--b-2">
								<h4>Information</h4>
							</div>
							<div className="input-content p p--b-2">
								<div className="request-detail__information">
									<div className="request-detail__type">
										<p className="mg mg--b-2">Type</p>
										<span
											style={{
												display: "inline-block",
												padding: "4px 8px",
												borderRadius: "16px",
												backgroundColor: getTypeColor(
													rowData.type
												),
												color: "black",
											}}
										>
											{rowData.type}
										</span>
									</div>

									<InputComponent
										type="text"
										name="date"
										label="Date"
										value={removeTime(rowData.date)}
										disabled={true}
										className={"input--medium"}
									/>
									{type !== "NEW_CUSTOMER" ? (
										<InputComponent
											type="text"
											name="organization"
											label="Organization"
											value={rowData.organization}
											disabled={true}
											className={"input--medium"}
										/>
									) : null}

									{type === "SUBSCRIBE" ||
									type === "DELETE" ||
									type === "MODIFY" ? (
										<InputComponent
											type="text"
											name="appName"
											label="App Name"
											value={rowData.nameApp}
											disabled={true}
											className={"input--medium"}
										/>
									) : (
										<></>
									)}

									<InputComponent
										type="text"
										name="user"
										label="User"
										value={rowData.userName}
										disabled={true}
										className={"input--medium"}
									/>

									<InputComponent
										type="text"
										name="email"
										label="Email"
										value={rowData.email}
										disabled={true}
										className={"input--medium"}
									/>

									{(type === "SUPPORT" ||
										type === "NEW_CUSTOMER") && (
										<div className="request-subscription__items">
											<InputComponent
												type="tel"
												name="phone"
												label="Phone"
												value={
													rowData.phone || phone || ""
												}
												disabled={true}
												className={"input--medium"}
											/>
										</div>
									)}
								</div>
							</div>
							<DividerComponent />
							<div>{renderRequest(rowData.type)}</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default RequestDetails;
