import React from "react";
import DividerComponent from "../divider-component/DividerComponent";

function DropdownComponent({ emails, onAddEmail }) {
	return (
		<div className="dropdown">
			<ul>
				{emails.map((emailObj, index) => (
					<li key={index}>
						{emailObj.email}
					</li>
				))}
				<DividerComponent />
				<li>
					<input
						type="email"
						placeholder="Add email"
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								onAddEmail(e.target.value);
								e.target.value = "";
							}
						}}
					/>
				</li>
			</ul>
		</div>
	);
}

export default DropdownComponent;
