import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const getApisList = async (userEmail, accessToken) => {
	try {
		const response = await axios.get(`${API_URL}/api/v1/apis/list`, {
			headers: {
				"Content-Type": "application/json",
				email: `${userEmail}`,
				Authorization: `${accessToken}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const postApiData = async (apiData, accessToken, userEmail) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/apis/create`,
			apiData,
			{
				headers: {
					Authorization: `${accessToken}`,
					Email: userEmail,
					"Content-Type": "application/json",
				},
			}
		);
		return response.data;
	} catch (error) {
		if(error.response.status === 403){
			// REDIRECCIÓN
		}
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const updateApi = async (apiData, accessToken, userEmail) => {
	try {
		const response = await axios.put(
			`${API_URL}/api/v1/apis/edit`,
			apiData,
			{
				headers: {
					Authorization: `${accessToken}`,
					Email: userEmail,
				},
			}
		);
		return response.data;
	} catch (error) {
		if(error.response.status === 403){
			// REDIRECCIÓN
		}
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const deleteApi = async (apiData, accessToken, userEmail) => {
	try {
		const response = await axios.delete(`${API_URL}/api/v1/apis/delete`, {
			headers: {
				Authorization: `${accessToken}`,
				Email: userEmail,
			},
			data: apiData,
		});

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};
