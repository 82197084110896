import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const registerUser = async (userData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const registerUserAdmin = async (userData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/admin`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const registerUserMaster = async (userData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/master`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const resetPassword = async (userData) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/reset`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const createPassword = async (userData) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/confirm`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const registerPassword = async (userData) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/reset`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const updatePassword = async (userData) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/reset/confirm`,
			userData
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const registerMasterUser = async (userData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/master`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};



export const deleteUser = async (userData, accessToken) => {
	try {
		const response = await axios.delete(
			`${API_URL}/api/v1/users_register`, 
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
				data: userData // Aquí pasamos el cuerpo de la petición DELETE
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};
