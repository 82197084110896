import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { useGlobalVar } from "../utils/GlobalContext";

import { HOME, REQUESTS_MANAGEMENT } from "../utils/Routes";



function LoginFullScreenLayout() {

	const { isAuth, role } = useGlobalVar();

	const navigate = useNavigate();

	useEffect(() => {
		if (isAuth) {
		  navigate(role === 'DEVELOPER' || role === 'ADMIN' ? HOME : REQUESTS_MANAGEMENT); // Navega a /home si isAuth es true
		}
	  }, [isAuth, navigate, role]);


  return (
    <>
		<div className="login-full-screen-layout">
			<div className="login-full-screen-layout__content">
				<div className="login-full-screen-layout__form">
					<div className="login-full-screen-layout__form__content">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
    </>
  );
}

export default LoginFullScreenLayout;
