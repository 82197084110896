import { useNavigate } from "react-router-dom";

function LinkComponent({
	text,
	icon,
	filled,
	bgColor,
	color,
	borderColor,
	url,
	counter,
	download,
	className,
	onClick,
	externalURL
}) {
	let navigate = useNavigate();

	const handleClick = (e) => {
		if(!externalURL)
			if (download) {
				// Si hay una URL de descarga, prevenimos el comportamiento por defecto del enlace
				e.preventDefault();
				// Puedes usar `window.open` para forzar la descarga
				window.open(download, "_blank");
			} else {
				if (url !== undefined) navigate(url);
			}

			if (onClick) {
				onClick();
			}
	};

	return (
		<a 	
			rel={externalURL ? 'noreferrer' : ''}
			target={externalURL ? '_blank' : ''}
			href={download || url} // La URL de descarga o la URL de navegación
			onClick={handleClick} // Manejo del clic
			download={download ? text : undefined} // Establece el atributo download solo si hay URL de descarga
			className={`link-component ${filled ? "filled" : ""} ${
				bgColor ? bgColor : ""
			} ${color ? "text-" + color : ""} ${
				borderColor ? "border-" + borderColor : ""
			} ${className}`}
		>
			<div
				className={`link-component__icon ${icon ? "icon " + icon : ""}`}
			></div>
			{counter && (
				<div className="link-component__counter">
					<span className="counter">{counter}</span>
				</div>
			)}
			<div className="link-component__text">
				<p>{text}</p>
			</div>
		</a>
	);
}

export default LinkComponent;
