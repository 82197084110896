import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../utils/GlobalContext";

import ButtonComponent from "../../atoms/button-component/ButtonComponent";
import ModalComponent from "../../molecules/modal-component/ModalComponent";

import { FORM_REQUEST_SUBSCRIPTION } from "../../utils/Routes";
import StepperComponent from "../../molecules/stepper-component/StepperComponent";
import AppList from "../../organisms/app-list/AppList";

import { getSubscriptionsByOrganization } from "../../../services/ApiSubscriptions";

import WelcomeModal from "../../organisms/welcome-modal/WelcomeModal";
import stepsData from "../../data/stepsData";

//const nameOrganization = "DRAFT";

const HomePage = () => {
	const navigate = useNavigate();

	const { accessToken, setLoggedOut, nameOrganization, fetchOrganizationDetails } =
		useGlobalVar();

	useEffect(() => {
		fetchOrganizationDetails(); // Proporciona el ID de la organización
	}, [fetchOrganizationDetails]);

	const titleSteper = "Set up your first APP";

	const [subscriptionList, setSubscriptionList] = useState([]);
	const [loading, setLoading] = useState(true);

	const [showModal, setShowModal] = useState(true);

	useEffect(() => {
        // Al montar el componente, verificar si el stepper ya ha sido cerrado
        const welcomeModalClosed = localStorage.getItem("welcomeModalClosed");
        if (welcomeModalClosed === "true") {
            setShowModal(false);
        }
    }, []);

	const closeWelcomeModal = () => {
        setShowModal(false);
        // Guardar en localStorage que el stepper fue cerrado
        localStorage.setItem("welcomeModalClosed", "true");
    };

	const logout = useCallback(() => {
		setLoggedOut();
		navigate("/logout");
	}, [setLoggedOut, navigate]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const subscriptionData = await getSubscriptionsByOrganization(
					nameOrganization,
					accessToken
				);
				setSubscriptionList(subscriptionData.subscriptionStateList);
			} catch (error) {
				if(error.response.status === 401){
					logout();
				}
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [nameOrganization, accessToken, logout]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<div className="homepage homepage--app-subscriptions">
				<StepperComponent title={titleSteper} steps={stepsData} />

				{subscriptionList === undefined ? (
					<>
						<h1 className="homepage--app-subscriptions__title">
							APP Subscriptions
						</h1>
						<div className="homepage--app-subscriptions__subtitle">
							<p>
								There is no subscribed app, please do this to
								manage it.
							</p>
						</div>
						<div className="homepage--app-subscriptions__button">
							<ButtonComponent
								text={"Request subscription"}
								url={FORM_REQUEST_SUBSCRIPTION}
							/>
						</div>
					</>
				) : (
					<>
						<div className="homepage--app-subscriptions__app-list">
							<div className="homepage--app-subscriptions__app-list__content">
								<div className="homepage--app-subscriptions__app-list__content__text">
									<h1 className="homepage--app-subscriptions__app-list__content__text__title">
										APP Subscriptions
									</h1>
									<div className="homepage--app-subscriptions__app-list__content__text__description">
										Manage your app subscriptions and their
										environments
									</div>
								</div>
								<div className="homepage--app-subscriptions__app-list__content__button">
									<ButtonComponent
										text={"Register your App"}
										url={FORM_REQUEST_SUBSCRIPTION}
									/>
								</div>
							</div>
							<div className="homepage--app-subscriptions__app-list__list">
								{loading ? (
									<div>Loading...</div>
								) : (
									<AppList
										appListContent={subscriptionList}
									/>
								)}
							</div>
						</div>
					</>
				)}
			</div>

			<ModalComponent show={showModal} hideCross={true} onClose={() => closeWelcomeModal} size={'lg2'}>
                <WelcomeModal onClose={closeWelcomeModal}/>
            </ModalComponent>
		</>
	);
};

export default HomePage;
