import React, { useState, useEffect } from "react";

import {
	error as showError,
	success as showSuccess,
} from "../../../utils/NotificationsContainer";

import { useGlobalVar } from "../../../utils/GlobalContext";
import { registerUser, registerUserAdmin } from "../../../../services/ApiAuth";
import InputComponent from "../../../atoms/input-component/InputComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";

import { getOrganizationsTypes } from "../../../../services/ApiOrganization";

const CreateUserOrganization = () => {
	const { accessToken } = useGlobalVar();

	const currentOrganizationId = localStorage.getItem("currentOrganizationId");
	const currentOrganizationName = localStorage.getItem(
		"currentOrganizationName"
	);
	const currentOrganizationCod = localStorage.getItem(
		"currentOrganizationCod"
	);

	const [userRol, setUserRol] = useState("DEVELOPER");

	const [organizationType, setOrganizationType] = useState("");

	const [userData, setUserData] = useState({
		username: "",
		firstname: "",
		lastname: "",
		phone: "",
		active: true,
		email: "",
		password: "",
		organizationName: currentOrganizationName,
		organizationCif: currentOrganizationId,
		organizationType: organizationType,
		userType: "",
		invited: true
	});

	useEffect(() => {
		setUserRol(userData.userType);
	}, [userData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getOrganizationsTypes(accessToken);

				const foundOrganization = response.find(
					(org) =>
						org.codOrganization === Number(currentOrganizationCod)
				);

				setOrganizationType(foundOrganization.codOrganization);
			} catch (error) {
				throw new Error(error);
			}
		};

		fetchData();
	}, [accessToken, currentOrganizationCod]);

	useEffect(() => {
		setUserData((prevUserData) => ({
			...prevUserData,
			username: prevUserData.email,
		}));
	}, [userData.email]);

	const handleChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setUserData({
					...userData,
					[name]: value,
				});
			}
		} else {
			setUserData((prevUserData) => ({
				...prevUserData,
				[name]: value,
			}));
		}
	};

	const handleSelectorChange = (event) => {
		const { name, value } = event.target;
		setUserData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();

		try {
			const dataToSubmit = {
				...userData,
				userRol,
			};

			const cleanedPayload = JSON.parse(JSON.stringify(dataToSubmit));

			cleanedPayload.client = true;
			cleanedPayload.organizationType = organizationType;

			delete cleanedPayload.userType;

			if (userRol === "DEVELOPER") {
				try {
					const result = await registerUser(
						cleanedPayload,
						accessToken
					);
					localStorage.setItem("reloadUserAdded", "true");
					window.location.reload();
					// navigate(MANAGE_USERS);
					showSuccess("New user successfully added");
					return result;
				} catch (error) {
					showError("Check the form fields");
					console.error("Error:", error);
				}
			} else if (userRol === "ADMIN") {
				try {
					const result = await registerUserAdmin(
						cleanedPayload,
						accessToken
					);
					localStorage.setItem("reloadUserAdded", "true");
					window.location.reload();
					// navigate(MANAGE_USERS);
					showSuccess("New user successfully added");
					return result;
				} catch (error) {
					showError("Check the form fields");
					console.error("Error:", error);
				}
			} else {
				showError("Add user rol");
				console.error("User rol invalid");
			}
		} catch (error) {
			showError("There was a problem, please resubmit your request");
			console.error("Error fetching organization types:", error);
		}
	};

	return (
		<div className="request-subscription add-user-organization">
			<div className="add-user-form__form__content">
				<div className="add-user-form__form__content__title">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent
							text={"Back to User list"}
							reload
						/>
					</div>
					<h3>User information</h3>
				</div>
				<div className="add-user-form__form__content__row">
					<InputComponent
						type="text"
						name="firstname"
						value={userData.firstname}
						label="Name"
						placeholder="Insert name"
						onChange={handleChange}
					/>
					<InputComponent
						type="text"
						name="lastname"
						value={userData.lastname}
						label="Lastname"
						placeholder="Insert lastname"
						onChange={handleChange}
					/>
				</div>
				<div className="add-user-form__form__content__row">
					<InputComponent
						type="email"
						name="email"
						value={userData.email}
						label="Email"
						placeholder="Insert email"
						onChange={handleChange}
					/>
					<InputComponent
						type="tel"
						name="phone"
						value={userData.phone}
						label="Phone and country code (Optional)"
						placeholder="Insert phone"
						onChange={handleChange}
					/>
				</div>

				<div className="add-user-form__form__content__title">
					<h3>User rol</h3>
				</div>

				<div className="add-user-form__form__content__checkbox">
					<div className="input-content">
						<div className="input-content__radios">
							<input
								type="radio"
								value="DEVELOPER"
								name="userType"
								id="DEVELOPER"
								checked={userData.userType === "DEVELOPER"}
								onChange={handleSelectorChange}
							/>
							<label htmlFor="DEVELOPER">
								<div className="input-content__radios__radio">
									<div className="input-content__radios__radio__content">
										<span className="input-content__radios__radio__title developer">
											DEVELOPER
										</span>
										<span className="input-content__radios__radio__description">
											Role for whoever needs to manage the
											APIs
										</span>
									</div>
								</div>
							</label>

							<input
								type="radio"
								value="ADMIN"
								name="userType"
								id="ADMIN"
								checked={userData.userType === "ADMIN"}
								onChange={handleSelectorChange}
							/>
							<label htmlFor="ADMIN">
								<div className="input-content__radios__radio">
									<div className="input-content__radios__radio__content">
										<span className="input-content__radios__radio__title admin">
											ADMIN
										</span>
										<span className="input-content__radios__radio__description">
											With this role you can manage the
											company's users
										</span>
									</div>
								</div>
							</label>
						</div>
					</div>
				</div>
			</div>
			<DividerComponent />
			<div>
				<div className="add-user__buttons pair-buttons mg mg--t-4 mg--l-4">
					<ButtonComponent
						text={"Cancel"}
						onClick={handleChange}
						color={"button-component--neutral"}
					/>
					<ButtonComponent text={"Add user"} onClick={handleSubmit} />
				</div>
			</div>
		</div>
	);
};

export default CreateUserOrganization;
