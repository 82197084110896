import { useNavigate } from "react-router-dom";
import { useGlobalVar } from "../../utils/GlobalContext";
import { useState, useEffect } from "react";
import { useApiCall } from "../../../hooks/useApiCall";
import { logoutUser } from "../../../api/api";

import {
	USER_SETTINGS,
	MANAGE_USERS,
	ADMIN_USER_SETTINGS,
} from "../../utils/Routes";

function MenuLoggedComponent({ name, email }) {
	const { sessionId, setLoggedOut, role, userEmail, userData } =
		useGlobalVar();

	const [isActive, toggleActive] = useState(false);

	const [userRole, setUserRole] = useState("");

	const userCharacter = userData.firstname.charAt(0).toUpperCase();

	useEffect(() => {
		setUserRole(role);
	}, [role]);

	const handleClick = () => {
		toggleActive(!isActive);
	};

	let navigate = useNavigate();
	const navigateTo = (url) => {
		navigate(url);
	};

	const [, , logoutCall] = useApiCall((data) => logoutUser(data), {
		executeImmediately: false,
		onSuccess: () => {
			setLoggedOut();
			navigate("/logout");
		},
	});

	function logout() {
		logoutCall(sessionId);
	}

	return (
		<>
			<div className="menu-logged">
				{userRole === "DEVELOPER" || userRole === "ADMIN" ? (
					<div className="menu-logged__avatar" onClick={handleClick}>
						<div className="menu-logged__avatar__letter">
							{userCharacter}
						</div>
					</div>
				) : (
					<div
						className="menu-logged__avatar menu-logged__avatar--admin"
						onClick={handleClick}
					>
						<div className="menu-logged__avatar__letter menu-logged__avatar__letter--admin">
							{userCharacter}
						</div>
					</div>
				)}

				<ul className={`menu-logged__menu ${isActive ? "active" : ""}`}>
					<li className="menu-logged__menu__item menu-logged__menu__user">
						<div className="menu-logged__menu__user__name">
							{userData.firstname} {userData.lastname}
						</div>
						<div className="menu-logged__menu__user__email">
							{userEmail}
						</div>
					</li>

					{userRole === "DEVELOPER" || userRole === "ADMIN" ? (
						<>
							<li onClick={() => navigateTo(USER_SETTINGS)} className="menu-logged__menu__item menu-logged__menu__settings">
								<div>
									User Settings
								</div>
							</li>
						</>
					) : (
						<li onClick={() => navigateTo(ADMIN_USER_SETTINGS)} className="menu-logged__menu__item menu-logged__menu__settings">
							<div>
								User Settings
							</div>
						</li>
					)}

					{userRole === "ADMIN" ? (
						<li onClick={() => navigateTo(MANAGE_USERS)} className="menu-logged__menu__item menu-logged__menu__settings">
							<div >
								Team management
							</div>
						</li>
					) : (
						""
					)}

					<li onClick={logout} className="menu-logged__menu__item menu-logged__menu__sign-out">
						<div>Sign Out</div>
					</li>
				</ul>
				<div
					className={`menu-logged__bg ${isActive ? "active" : ""}`}
					onClick={handleClick}
				></div>
			</div>
		</>
	);
}

export default MenuLoggedComponent;
