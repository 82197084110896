import React, { useState, useEffect } from "react";

import { useGlobalVar } from "../../../utils/GlobalContext";

import { getAdminEmails, updateAdminEmails } from "../../../../services/ApiUsers";

import { error as showError, success as showSuccess } from "../../../utils/NotificationsContainer";

import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import DropdownComponent from "../../../atoms/dropdown-component/DropdownComponent";

import iconPlus from "../../../../assets/images/icons/icon-plus.svg";
import iconDelete from "../../../../assets/images/icons/icon_close.svg";

const EmailConfiguration = () => {

	const { accessToken } = useGlobalVar();

	const [initialEmails, setInitialEmails] = useState([]);
	const [emails, setEmails] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [visibleDropdowns, setVisibleDropdowns] = useState({});
	const [error, setError] = useState('');

	const [loading, setLoading] = useState(true); 

	useEffect(() => {

		const fetchData = async () => {
			try {
				const response = await getAdminEmails(accessToken);

				setEmails(response);
				setInitialEmails(response);
				setLoading(false); 
			} catch (error) {
				console.error("Error fetching data:", error);
				setLoading(false); 
			}
		};

		fetchData();
	}, [accessToken]);
  
	const toggleEdit = () => {
	  setIsEditing(true);
	};
  
	const handleCancel = () => {
	  setIsEditing(false);
	  setEmails(initialEmails);
	  setVisibleDropdowns({});
	  setError('');
	};
  
	const toggleDropdown = (key) => {
		setVisibleDropdowns(prevState => ({
		  // Solo mantenemos el dropdown correspondiente al `key` actual en true
		  [key]: !prevState[key]
		}));
	  };
  
	const handleAddEmail = (email, type) => {
		// Regex para validar el formato de email
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	  
		// Validar que el email sea válido y que no exista ya en la lista
		if (email && emailRegex.test(email)) {
		  setEmails([...emails, { email, type }]); // Agrega el email con el type específico
		  setError('');
		} else {
			showError('Please enter a valid email address.')
		}
	  };
  
	  const handleDeleteEmail = (emailToDelete) => {
		if (isEditing) {
		  const newEmails = emails.filter(
			(email) => !(email.email === emailToDelete.email && email.type === emailToDelete.type)
		  );
		  setEmails(newEmails);
	  
		  if (newEmails.length === 0) {
			setError('This list cannot be empty');
		  }
		}
	  };

	// Función para filtrar emails por tipo
	const filterEmailsByType = (type) => {
		return emails.filter(email => email.type === type);
	  };
  
	const handleSaveChanges = async () => {


		const payload = emails.map(({ id, ...rest }) => rest);

	  try {
		// POST al endpoint
		const response = await updateAdminEmails(payload, accessToken)
  
		if (response.code === 200) {
		  showSuccess('Emails saved successfully');
		  setIsEditing(false);
		}
	  } catch (error) {

		showError('There was a problem, please resubmit your request')
		setError('Error saving emails');
	  }
	};

	if (loading)
		return <div>Loading...</div>
  
	return (
	  <div className="email-configuration team-management__form">
		<div className="mg mg--t-4">
		  <h2 className="p p--b-2">Receipt of request from API subscriptions</h2>
		  <p className="p p--b-2">
			List of administrators who will receive an email with the API subscriptions
		  </p>
		  <div className="email-configuration__add">
			{!isEditing ? (
			  <></>
			) : (
			  <div className="email-configuration__add__button">
				<ButtonComponent
				  icon={iconPlus}
				  text={"Add Responsible"}
				  onClick={() => toggleDropdown("apiSubscriptions")}
				  color={"button-component--neutral"}
				/>
				{visibleDropdowns["apiSubscriptions"] && (
				  <DropdownComponent
					emails={filterEmailsByType('API_SUSCRIPTION').map((admin, index) => (admin))}
					onAddEmail={(email) => handleAddEmail(email, 'API_SUSCRIPTION')}
				  />
				)}
			  </div>
			)}
			<div className="email-configuration__emails">
			  {filterEmailsByType('API_SUSCRIPTION').map((admin, index) => (
				<div key={index} className="email-configuration__user">
				  <span>{admin.email}</span>
				  <img
					src={iconDelete}
					alt="Delete"
					className="delete-icon"
					onClick={() => handleDeleteEmail(admin)}
				  />
				</div>
			  ))}
			  {error && (
				<p className="email-configuration__error">{error}</p>
			  )} 
			</div>
		  </div>
		</div>
		<DividerComponent />
		<div>
			<h2 className="p p--b-2">Receipt of request from Support</h2>
			<p className="p p--b-2">
				List of administrators who will receive an email for support
			</p>
			<p className="p p--b-2">
				Responsible for Technical Doubt emails
			</p>
			<div className="email-configuration__add p p--b-2">
				{!isEditing ? (
					<></>
				) : (
					<div className="email-configuration__add__button">
						<ButtonComponent
							icon={iconPlus}
							text={"Add Responsible"}
							onClick={() => toggleDropdown("technicalDoubt")}
							color={"button-component--neutral"}
						/>
						{visibleDropdowns["technicalDoubt"] && (
							<DropdownComponent
								emails={filterEmailsByType('TECHNICAL_SUPPORT').map((admin, index) => (admin))}
								onAddEmail={(email) => handleAddEmail(email, 'TECHNICAL_SUPPORT')}
							/>
						)}
					</div>
				)}
				<div className="email-configuration__emails">
					{filterEmailsByType('TECHNICAL_SUPPORT').map((admin, index) => (
						<div key={index} className="email-configuration__user">
						<span>{admin.email}</span>
						<img
							src={iconDelete}
							alt="Delete"
							className="delete-icon"
							onClick={() => handleDeleteEmail(admin)}
						/>
						</div>
					))}
					{error && (
						<p className="email-configuration__error">{error}</p>
					)} 
				</div>
			</div>

			<p className="p p--b-2">Responsible for Incidents emails</p>
			<div className="email-configuration__add">
				{!isEditing ? (
					<></>
				) : (
					<div className="email-configuration__add__button">
						<ButtonComponent
							icon={iconPlus}
							text={"Add Responsible"}
							onClick={() => toggleDropdown("incidents")}
							color={"button-component--neutral"}
						/>
						{visibleDropdowns["incidents"] && (
							<DropdownComponent
								emails={filterEmailsByType('INCIDENTS_SUPPORT').map((admin, index) => (admin))}
								onAddEmail={(email) => handleAddEmail(email, 'INCIDENTS_SUPPORT')}
							/>
						)}
					</div>
				)}
				<div className="email-configuration__emails">
					{filterEmailsByType('INCIDENTS_SUPPORT').map((admin, index) => (
						<div key={index} className="email-configuration__user">
						<span>{admin.email}</span>
						<img
							src={iconDelete}
							alt="Delete"
							className="delete-icon"
							onClick={() => handleDeleteEmail(admin)}
						/>
						</div>
					))}
					{error && (
						<p className="email-configuration__error">{error}</p>
					)} 
				</div>
			</div>
		</div>
		<DividerComponent />
		<div>
			<h2 className="p p--b-2">
				Receipt of requests from new clients
			</h2>
			<p className="p p--b-2">
				List of administrators who will receive an email from new
				customers
			</p>
			<div className="email-configuration__add">
				{!isEditing ? (
					<></>
				) : (
					<div className="email-configuration__add__button">
						<ButtonComponent
							icon={iconPlus}
							text={"Add Responsible"}
							onClick={() => toggleDropdown("newClients")}
							color={"button-component--neutral"}
						/>
						{visibleDropdowns["newClients"] && (
							<DropdownComponent
								emails={filterEmailsByType('NEW_CLIENTS').map((admin, index) => (admin))}
								onAddEmail={(email) => handleAddEmail(email, 'NEW_CLIENTS')}
							/>
						)}
					</div>
				)}
				<div className="email-configuration__emails">
					{filterEmailsByType('NEW_CLIENTS').map((admin, index) => (
						
						<div key={index} className="email-configuration__user">
						<span>{admin.email}</span>
						<img
							src={iconDelete}
							alt="Delete"
							className="delete-icon"
							onClick={() => handleDeleteEmail(admin)}
						/>
						</div>
					))}
					{error && (
						<p className="email-configuration__error">{error}</p>
					)} 
				</div>
			</div>
		</div>
		<DividerComponent />
		{isEditing ? (
		  <div className="pair-buttons mg mg--t-4">
			<ButtonComponent
			  text={"Cancel"}
			  onClick={handleCancel}
			  color={"button-component--reject"}
			/>
			<ButtonComponent
			  text={"Save changes"}
			  onClick={handleSaveChanges}
			  color={"button-component"}
			/>
		  </div>
		) : (
		  <ButtonComponent
			text={"Edit"}
			onClick={toggleEdit}
			color={"button-component--neutral"}
		  />
		)}
	  </div>
	);
  };

export default EmailConfiguration;
