import React, { createContext, useState, useContext } from "react";

const LoginOrganizationContext = createContext();

export const LoginOrganizationProvider = ({ children }) => {
	const [loginOrganizationId, setLoginOrganizationId] = useState(null);

	return (
		<LoginOrganizationContext.Provider
			value={{ loginOrganizationId, setLoginOrganizationId }}
		>
			{children}
		</LoginOrganizationContext.Provider>
	);
};

// Hook personalizado para usar el contexto
export const useLoginOrganization = () => {
	const context = useContext(LoginOrganizationContext);
	if (!context) {
		throw new Error(
			"useLoginOrganization debe usarse dentro de un LoginOrganizationProvider"
		);
	}
	return context;
};
