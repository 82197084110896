import React, { useState, useEffect } from "react";
import InputComponent from "../../atoms/input-component/InputComponent";

const SearchComponent = ({ onSearch }) => {
	const [searchTerm, setSearchTerm] = useState("");

	// Manejar el cambio del input
	const handleInputChange = (name, value) => {
		if (name === "search") {
			setSearchTerm(value);
		}
	};

	useEffect(() => {
		onSearch(searchTerm);
	}, [searchTerm, onSearch]);

	return (
		<div className="search-container">
			<div className="icon-search"></div>
			<InputComponent
				type="text"
				name="search"
				value={searchTerm}
				onChange={handleInputChange}
				placeholder="Search..."
				className="search-input"
			/>
		</div>
	);
};

export default SearchComponent;
