import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from "../../../services/ApiClient";
import { useGlobalVar } from "../../utils/GlobalContext";
import { useApiCall } from "../../../hooks/useApiCall";

import './LoginBackendPage.css'


const LoginBackendPage = () => {
  const [action,setAction] = useState("Login")
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const { setLoggedIn, pathAfterLogin, setAuthToken, setSessionId } = useGlobalVar();
  // const [showUnauthorized, setShowUnauthorized] = useState(false)
  const navigate = useNavigate();


  const [loginCall] = useApiCall((data) => loginUser(data), {
    executeImmediately: false,
    notify:false,
    onSuccess: (response) => {
      setAuthToken(response?.data?.access_token);
      setSessionId(response?.data?.sessionId);
      setLoggedIn();
      navigate(pathAfterLogin || '/');
    },
    onFailure: () => {
      // setShowUnauthorized(true)
    }
  });

  const processLogin = () => {
    loginCall(loginUser({ username, password }));
  };

  const processSignUp = () => {
    
  };


return (

  <div className="container">
      <div className="header">
        <div className='text'>{action}</div> 
        <div className='underline'></div> 
      </div>

      <form className='inputs'>
        {action==="Login"?
          <div className='input'>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
          </div>       
        :
          <div className='input'>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email Id'/>
          </div>     
        }                        
        <div className='input'>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password'/>
        </div>      

        {action==="Sign Up"?<div></div>:<div className='forgot-password'>Not yet registered? <span onClick={()=>{setAction("Sign Up")}}>Click Here</span></div>}        
        <div className='submit-container'>
        {action==="Login"?
        <>
          <div className={"submit gray"} onClick={()=>{setAction("Sign Up")}}>Sign Up</div>
          <div className={"submit"} onClick={processLogin}>Login</div>
        </>  
        :
        <>
          <div className={"submit gray"} onClick={()=>{setAction("Login")}}>Cancel</div>
          <div className={"submit"} onClick={processSignUp}>Sign Up</div>
        </>  
        }  
        </div>   
      </form>
      
    </div>
  );
};

export default LoginBackendPage;