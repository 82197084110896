import React, { useEffect, useState } from "react";

import { WITHOUT, PENDING, SUBSCRIBED, UNSUBCRIPTION, UNSUBSCRIPTION_REQUESTED, SUBSCRIPTION_REQUESTED } from "../../utils/GlobalConsts";

const ApiSubscriptionStateComponent = ({ state }) => {
	const [textState, setTextState] = useState("Without subscription");

	useEffect(() => {
		if (state === SUBSCRIBED) setTextState("Subscribed");
		else if (state === PENDING) setTextState("Request to subscribe");
		else if (state === UNSUBCRIPTION) setTextState("Unsubscription");
		else if (state === WITHOUT) setTextState("Without subscription");
		else if (state === UNSUBSCRIPTION_REQUESTED) setTextState("Request to unsubscribe");
		else if (state === SUBSCRIPTION_REQUESTED) setTextState("Request to subscribe");
	}, [state]);

	return (
		<>
			<div
				className={`api-subscription-state api-subscription-state--admin ${
					state ? state.toLowerCase() : ""
				}`}
			>
				<div className="api-subscription-state__circle">
					<span className="api-subscription-state__circle__plus">+</span>
					<span className="api-subscription-state__circle__less">-</span>
				</div>
				<div className="api-subscription-state__text">{textState}</div>
			</div>
		</>
	);
};

export default ApiSubscriptionStateComponent;
