import React from "react";

import InputComponent from "../../../atoms/input-component/InputComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ApiSubscriptionStateComponentAdmin from "../../../atoms/api-subscription-state-component-admin/ApiSubscriptionStateComponentAdmin";


const ModifyAuditDetails = ({ jsonData }) => {
	

	return (
		<div>
			<div className="audit-details__data">
				<h2 className="mg mg--b-4">
					Request for {jsonData.environment} environment
				</h2>
				<p>APIs subcription</p>
				<div className="request-detail__information">
					<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
						<div className="input-content__checkboxes__checkbox__text">
							<div className="input-content__checkboxes__checkbox__text__title">
								<span>
								API ISO20022
								</span>
							</div>
							<div className="input-content__checkboxes__checkbox__text__description">
								<span>
								Brief description of the API utility
								</span>
							</div>
							<div className="input-content__checkboxes__checkbox__subscription">
								<ApiSubscriptionStateComponentAdmin state={jsonData?.subscribeAPI?.apiIso}/>
							</div>
						</div>
					</div>  
					{/* <div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
						<div className="input-content__checkboxes__checkbox__text">
							<div className="input-content__checkboxes__checkbox__text__title">
								<span>
								API R-Transactions
								</span>
							</div>
							<div className="input-content__checkboxes__checkbox__text__description">
								<span>
								Brief description of the API utility
								</span>
							</div>
							<div className="input-content__checkboxes__checkbox__subscription">
								<ApiSubscriptionStateComponentAdmin state={jsonData?.subscribeAPI?.apiRTran}/>
							</div>
						</div>
					</div>  
					<div className="input-content__checkboxes__checkbox__content input-content__checkboxes__checkbox__content--admin">
						<div className="input-content__checkboxes__checkbox__text">
							<div className="input-content__checkboxes__checkbox__text__title">
								<span>
								API Customer Portal
								</span>
							</div>
							<div className="input-content__checkboxes__checkbox__text__description">
								<span>
								Brief description of the API utility
								</span>
							</div>
							<div className="input-content__checkboxes__checkbox__subscription">
								<ApiSubscriptionStateComponentAdmin state={jsonData?.subscribeAPI?.apiCustomer}/>
							</div>
						</div>
					</div>   */}
				</div>

				<InputComponent
					type="textarea"
					name="publicKey"
					label="Public Key"
					value={`-----BEGIN PUBLIC KEY-----\n${jsonData.publicKey}\n-----END PUBLIC KEY-----`}
					disabled={true}
					className={"input--extralarge"}
				/>

				<InputComponent
					type="text"
					name="keyID"
					label="Key ID"
					value={jsonData?.keyId}
					disabled={true}
					className={"input--medium"}
				/>

				<InputComponent
					type="text"
					name="issuer"
					label="Issuer"
					value={jsonData?.insertIssuer}
					disabled={true}
					className={"input--medium"}
				/>
			</div>

			<DividerComponent />

			{jsonData.environment === "LIVE" ? (
				<>
					<div>
						<h3 className="mg mg--b-4">Notifier endpoint</h3>
						{/* NOTIFICATIONS TYPE */}
						{jsonData?.webhoot ? (
							<InputComponent
								type="text"
								name="notificationsType"
								label="Notifications type"
								value={"Webhook"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{jsonData?.statusReport ? (
							<InputComponent
								type="text"
								name="notificationsType"
								label="Notifications type"
								value={"Status report hub messages"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* NOTIFIER SECURITY */}
						{jsonData?.oauthSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"OAuth 2.0"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{jsonData?.basicSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"HTTP Basic"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{/* {jsonData?.notSecurity ? (
							<InputComponent
								type="text"
								name="notifierSecurity"
								label="Notifier security"
								value={"Without security"}
								disabled={true}
								className={"input--medium"}
							/>
						) : null} */}

						{/* OAUTH SECURITY PARAMS */}
						{jsonData?.oauthParam?.notificationUrl ? (
							<InputComponent
								type="text"
								name="funtionalEndpoint"
								label="Funtional endpoint"
								value={
									jsonData?.oauthParam?.notificationUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{jsonData?.oauthParam?.clientID ? (
							<InputComponent
								type="text"
								name="webhook"
								label="Webhook Client ID"
								value={jsonData?.oauthParam?.clientID}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{jsonData?.oauthParam?.clientSecret ? (
							<InputComponent
								type="text"
								name="clientSecret"
								label="Client Secret"
								value={
									jsonData?.oauthParam?.clientSecret
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{jsonData?.oauthParam?.tokenUrl ? (
							<InputComponent
								type="text"
								name="tokenURI"
								label="Token URI"
								value={jsonData?.oauthParam?.tokenUrl}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* BASIC SECURITY PARAMS */}
						{jsonData?.basicSecurityParam
							?.notificationUrl ? (
							<InputComponent
								type="text"
								name="funtionalEndpoint"
								label="Funtional endpoint"
								value={
									jsonData?.basicSecurityParam
										?.notificationUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{jsonData?.basicSecurityParam?.user ? (
							<InputComponent
								type="text"
								name="user"
								label="User"
								value={
									jsonData?.basicSecurityParam?.user
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}
						{jsonData?.basicSecurityParam?.pass ? (
							<InputComponent
								type="text"
								name="password"
								label="Password"
								value={
									jsonData?.basicSecurityParam?.pass
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null}

						{/* WITHOUT SECURITY PARAMS */}
						{/* {jsonData?.notSecurityParam?.tokenUrl ? (
							<InputComponent
								type="text"
								name="tokenURI"
								label="Token URI"
								value={
									jsonData?.notSecurityParam?.tokenUrl
								}
								disabled={true}
								className={"input--medium"}
							/>
						) : null} */}
					</div>
					<DividerComponent />
				</>
			) : null}
			<div>
				<h3 className="mg mg--b-4">
					Confidential information from PagoNxt
				</h3>
				<InputComponent
					type="text"
					name="keyID"
					label="Client ID"
					value={jsonData.clientAppId}
					disabled={true}
					className={"input--medium"}
				/>
				<InputComponent
					type="text"
					name="secret"
					label="Secret"
					value={jsonData.secret}
					disabled={true}
					className={"input--medium"}
				/>
			</div>
		</div>
	);
};

export default ModifyAuditDetails;
