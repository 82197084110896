import React from "react";
import ButtonComponent from "../../atoms/button-component/ButtonComponent";

const WelcomeModal = ({onClose}) => {
   
    return (
        <>
            <div className='welcome-modal'>
                <div className='welcome-modal__header'>
                    <div className='welcome-modal__header__title'>
                        <p>Welcome to <br />
                        <span>PagoNxt Payments Developer Portal</span></p>
                        
                    </div>
                </div>
                <div className="welcome-modal__body">
                    <div className="welcome-modal__body__t1">
                        <h4>
                            We are excited to have you here. Our platform is designed to help you develop and launch your projects faster and more efficiently.
                        </h4>
                    </div>
                    <div className="welcome-modal__body__t2">
                        <p>
                            We invite you to visit our knowledge space from the Developer portal link.
                        </p>
                    </div>
                </div>
                <div className="welcome-modal__button">
                    <ButtonComponent
                        onClick={onClose}
                        type="submit"
                        text={"Explore Platform"}
                    />
                </div>
            </div>
        </>
    )
}

export default WelcomeModal;
