import React, { createContext, useState, useContext } from "react";

const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
	const [organizationId, setOrganizationId] = useState(null);

	return (
		<OrganizationContext.Provider
			value={{ organizationId, setOrganizationId }}
		>
			{children}
		</OrganizationContext.Provider>
	);
};

export const useOrganization = () => {
	const context = useContext(OrganizationContext);
	if (!context) {
		throw new Error(
			"useOrganization debe usarse dentro de un OrganizationProvider"
		);
	}
	return context;
};
