const stepsData = [
	{
		step: "1",
		title: "Subscribe your APP",
		icon: "",
		description: "Subscribe to our APIs",
	},
	{
		step: "2",
		title: "Try our APIs",
		icon: "",
		description: "Use our product",
	},
	{
		step: "3",
		title: "Invite your coworkers",
		icon: "",
		description: "Share with your Team",
	},
	{
		step: "4",
		title: "Go to LIVE",
		icon: "",
		description: "Publish your APP",
	},
];

export default stepsData;
