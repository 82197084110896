import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalVar } from "../../../utils/GlobalContext";

import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import InputComponent from "../../../atoms/input-component/InputComponent";
import SubscribeAuditDetails from "../admin-area-audit-subscribe-details/SubscribeAuditDetails";
import SupportAuditDetails from "../admin-area-audit-support-details/SupportAuditDetails";
import ModifyAuditDetails from "../admin-area-audit-modify-details/ModifyAuditDetails";
import DeleteAuditDetails from "../admin-area-audit-delete-details/DeleteAuditDetails";
import NewCostumerAuditDetails from "../admin-area-audit-new-costumer-details/NewCostumerAuditDetails";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import AlertComponent from "../../../atoms/alert-component/alertComponent";

import { getOrganizationsTypes } from "../../../../services/ApiOrganization";

const AuditDetails = () => {
	const { accessToken } = useGlobalVar();

	const location = useLocation();
	const { rowData } = location.state;

	const [organization, setOrganization] = useState(null);

	const removeTime = (date_created) => {
		return date_created.split("T")[0];
	};

	const type = rowData.typeAudit;

	// String JSON recibido en la variable
	const jsonString = rowData.json;

	// Remover las barras invertidas (caracteres de escape)
	const jsonCleaned = jsonString.replace(/\\/g, "");

	// Convertir el string a un objeto JSON
	const jsonData = JSON.parse(jsonCleaned);

	const renderRequest = (type) => {
		switch (type) {
			case "SUBSCRIBE":
				return <SubscribeAuditDetails jsonData={jsonData} />;

			case "SUPPORT":
				return <SupportAuditDetails jsonData={jsonData} />;

			case "MODIFY":
				return <ModifyAuditDetails jsonData={jsonData} />;

			case "DELETE":
				return <DeleteAuditDetails jsonData={jsonData} />;

			case "NEW_CUSTOMER":
				return <NewCostumerAuditDetails jsonData={jsonData} />;

			default:
				return <div>Error</div>;
		}
	};

	const getTypeColor = (type) => {
		switch (type) {
			case "SUBSCRIBE":
				return "#D6E0FF"; // Morado
			case "SUPPORT":
				return "#DDE7EE"; // Gris
			case "DELETE":
				return "#F7C5C5"; // Rojo
			case "MODIFY":
				return "#FCE1C2"; // Amarillo
			case "NEW_CUSTOMER":
				return "#D9F4EB"; // Verde
			default:
				return "#fff"; // Color por defecto
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const typeOrganizationData = await getOrganizationsTypes(
					accessToken
				);

				setOrganization(
					typeOrganizationData.find(
						(org) =>
							org.codOrganization === jsonData.organizationCode
					)
				);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [accessToken, jsonData]);

	return (
		<>
			<div className="request-subscription audit-details">
				<div className="request-subscription__heading">
					<div className="request-subscription__heading__back-button">
						<BackButtonComponent />
					</div>
					<h1>Audit from {rowData.organizations}</h1>

					<div className="request-subscription__form">
						{/* Cambiar con la respuesta de done/Reject */}
						<span className="audit-details__status">
							<span
								style={{
									display: "inline-block",
									width: "16px",
									height: "16px",
									borderRadius: "50%",
									backgroundColor:
										rowData.status === "Done"
											? "#47D7AC"
											: "#C41C1C",
									marginRight: "8px",
								}}
							></span>
							{rowData.status}
						</span>
						{!jsonData.done &&
						(jsonData.mensaje !== undefined ||
							jsonData.message !== undefined) ? (
							<div className="audit-details__reject-reason">
								<AlertComponent
									icon={"icon-info"}
									text={`Reason for rejection: ${
										jsonData.mensaje || jsonData.message
									}`}
									bgColor={"bg--brand-neutral-100"}
								/>
							</div>
						) : null}

						<div className="request-subscription__form__input__title p p--b-2 p--t-3">
							<h4>Information</h4>
						</div>
						<div className="input-content p p--b-2">
							<div className="request-detail__information">
								<div className="request-detail__type audit-details__type">
									<p>Type</p>
									<span
										style={{
											display: "inline-block",
											padding: "4px 8px",
											borderRadius: "16px",
											backgroundColor: getTypeColor(
												rowData.typeAudit
											),
											color: "black",
										}}
									>
										{rowData.typeAudit}
									</span>
								</div>
								<InputComponent
									type="date"
									name="date_created"
									label="Date"
									value={removeTime(rowData.date_created)}
									disabled={true}
									className={"input--small"}
								/>
								<InputComponent
									type="text"
									name="organizations"
									label="Organization"
									value={rowData.organizations}
									disabled={true}
									className={"input--small"}
								/>
								{type === "SUBSCRIBE" ||
								type === "DELETE" ||
								type === "MODIFY" ? (
									<InputComponent
										type="text"
										name="appName"
										label="Aplication"
										value={jsonData.name}
										disabled={true}
										className={"input--small"}
									/>
								) : (
									<></>
								)}

								{type === "NEW_CUSTOMER" ? (
									<InputComponent
										type="text"
										name="appName"
										label="Type of organization"
										value={organization?.description || ""}
										disabled={true}
										className={"input--small"}
									/>
								) : (
									<></>
								)}

								<InputComponent
									type="text"
									name="firstname"
									label="User"
									value={rowData.firstname}
									disabled={true}
									className={"input--small"}
								/>

								<InputComponent
									type="text"
									name="email"
									label="Email"
									value={rowData.email}
									disabled={true}
									className={"input--small"}
								/>

								{(type === "SUPPORT" ||
									type === "NEW_CUSTOMER") && (
									<div className="request-subscription__items">
										<InputComponent
											type="tel"
											name="phone"
											label="Phone"
											value={
												rowData.phone || jsonData.phone
											}
											disabled={true}
											className={"input--small"}
										/>
									</div>
								)}

								{/* {type === "NEW_CUSTOMER" ? (
									<InputComponent
										type="text"
										name="appName"
										label="Job title"
										value={rowData.jobTitle}
										disabled={true}
										className={"input--small"}
									/>
								) : (
									<></>
								)} */}
							</div>
						</div>
						<DividerComponent />
						<div>{renderRequest(rowData.typeAudit)}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AuditDetails;
