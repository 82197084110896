import React, { useState, useEffect } from "react";
import { useGlobalVar } from "../../../utils/GlobalContext";

import {
	error as showError,
	success as showSuccess,
} from "../../../utils/NotificationsContainer";

import { useOrganization } from "../../../context/OrganizationContext";
import InputComponent from "../../../atoms/input-component/InputComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import AlertComponent from "../../../atoms/alert-component/alertComponent";

import { deleteUser } from "../../../../services/ApiAuth";
import { updateUserOrganization } from "../../../../services/ApiOrganization";

const EditUserOrganization = ({ user }) => {
	const { accessToken } = useGlobalVar();

	const [showModal, setShowModal] = useState(false);

	const { organizationId } = useOrganization();
	const [isEditing, setIsEditing] = useState(false);

	const [updateUserData, setUpdateUserData] = useState({
		firstname: "",
		lastname: "",
		phone: "",
		email: "",
		cif: organizationId,
		rol: "",
		id: "",
		active: true,
		subscribed: true,
	});

	// Este useEffect se ejecuta cuando el prop `user` cambia
	useEffect(() => {
		if (user) {
			// Si existe el `user`, actualiza el estado con los valores de `user`
			setUpdateUserData({
				firstname: user.firstname || "", // Usa los valores del usuario o un string vacío si no existe
				lastname: user.lastname || "",
				phone: user.phone || "",
				email: user.email || "",
				cif: organizationId, // Mantiene el cif de la organización
				rol: user.roles || "",
				id: user.id || "",
				active: user.active !== undefined ? user.active : true,
				subscribed:
					user.subscribed !== undefined ? user.subscribed : true,
			});
		}
	}, [user, organizationId]); // Se asegura de actualizar si cambia `user` o `organizationId`

	const handleChange = (name, value) => {
		if (name === "phone") {
			if (/^\d*$/.test(value) && value.length <= 15) {
				// Asegura que solo se acepten números y hasta 9 dígitos
				setUpdateUserData({
					...updateUserData,
					[name]: value,
				});
			}
		} else {
			setUpdateUserData((prevUserData) => ({
				...prevUserData,
				[name]: value,
			}));
		}
	};

	const handleSelectorChange = (event) => {
		const { name, value } = event.target;
		setUpdateUserData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const editUser = () => {
		setIsEditing(true);
	};

	const handleSubmit = async (e) => {
		if (e) e.preventDefault();

		const cleanedPayload = JSON.parse(JSON.stringify(updateUserData));

		try {
			await updateUserOrganization(cleanedPayload, accessToken);
			setIsEditing(false);

			showSuccess("User successfully modified");
		} catch (error) {
			showError("Check the form fields");
			console.error("Error updating organization:", error);
		}
	};

	const handleDelete = async (e) => {
		if (e) e.preventDefault();

		const payload = {
			email: updateUserData.email,
		};

		try {
			await deleteUser(payload, accessToken);
			setIsEditing(false);

			localStorage.setItem("reloadUserDeleted", "true");

			window.location.reload();
		} catch (error) {
			showError("There was a problem, please resubmit your request");
			console.error("Error updating organization:", error);
		}
	};

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	return (
		<div className="add-user-form__form__content">
			<BackButtonComponent text={"Back to User list"} reload />

			<h3>Edit User</h3>
			<div className="add-user-form__form__content__row">
				<InputComponent
					type="text"
					name="firstname"
					label="Name"
					placeholder="Name"
					value={updateUserData.firstname}
					onChange={handleChange}
					disabled={!isEditing}
				/>
				<InputComponent
					type="text"
					name="lastname"
					label="Lastname"
					placeholder="lastname"
					value={updateUserData.lastname}
					onChange={handleChange}
					disabled={!isEditing}
				/>
			</div>
			<div className="add-user-form__form__content__row">
				<InputComponent
					type="text"
					name="email"
					label="Email"
					placeholder="Email"
					value={updateUserData.email}
					onChange={handleChange}
					disabled
				/>
				<InputComponent
					type="tel"
					name="phone"
					label="Phone"
					placeholder="Phone number with the country code"
					value={updateUserData.phone}
					onChange={handleChange}
					disabled={!isEditing}
				/>
			</div>

			<div className="add-user-form__form__content__title">
				<h3>User rol</h3>
			</div>
			<div className="add-user-form__form__content__checkbox">
				<div className="input-content">
					<div className="input-content__radios">
						<input
							type="radio"
							value="DEVELOPER"
							name="rol"
							id="DEVELOPER"
							checked={updateUserData.rol === "DEVELOPER"}
							onChange={handleSelectorChange}
							disabled={!isEditing}
						/>
						<label htmlFor="DEVELOPER">
							<div className="input-content__radios__radio">
								<div className="input-content__radios__radio__content">
									<span className="input-content__radios__radio__title developer">
										DEVELOPER
									</span>
									<span className="input-content__radios__radio__description">
										Role for whoever needs to manage the
										APIs
									</span>
								</div>
							</div>
						</label>

						<input
							type="radio"
							value="ADMIN"
							name="rol"
							id="ADMIN"
							checked={updateUserData.rol === "ADMIN"}
							onChange={handleSelectorChange}
							disabled={!isEditing}
						/>
						<label htmlFor="ADMIN">
							<div className="input-content__radios__radio">
								<div className="input-content__radios__radio__content">
									<span className="input-content__radios__radio__title admin">
										ADMIN
									</span>
									<span className="input-content__radios__radio__description">
										With this role you can manage the
										company's users
									</span>
								</div>
							</div>
						</label>
					</div>
				</div>
			</div>
			{!isEditing ? (
				<></>
			) : (
				<div>
					<DividerComponent />
					<div className="edit-users__remove">
						<div className="edit-users__remove--text">
							<h4 className="edit-users__remove--text__title mg mg--b-2">
								Delete user
							</h4>
							<p className="edit-users__remove--text__subtitle">
								It will remove access permissions. This user
								will no longer be able to access their company
								information in PagoNxt
							</p>
						</div>
						<div className="edit-users__remove--btn">
							<span className="edit-users__remove--btn__icon"></span>
							<ButtonComponent
								text={"Remove access"}
								onClick={openModal}
								bgColor={"button-component--remove"}
							/>
						</div>
					</div>
					<ModalComponent
						show={showModal}
						onClose={closeModal}
						size={"lg"}
					>
						<div className="modal-delete-user__header">
							<h3>Do you want to remove this user's access?</h3>
						</div>

						<div className="modal-delete-user__body">
							<AlertComponent
								text={`Will remove access permissions from ${updateUserData.firstname} ${updateUserData.lastname}`}
								icon={"icon-info"}
								bgColor={"bg--brand-warning-100"}
							/>
						</div>
						<div className="modal-delete-user__buttons">
							<div className="pair-buttons">
								<ButtonComponent
									text={"Cancel"}
									onClick={closeModal}
									color={"button-component--neutral"}
								/>
								<ButtonComponent
									text={"Remove access"}
									onClick={handleDelete}
									color={"button-component--danger"}
								/>
							</div>
						</div>
					</ModalComponent>
				</div>
			)}
			<DividerComponent />
			<div>
				<div className="add-user__buttons">
					{!isEditing ? (
						<ButtonComponent
							text="Edit"
							onClick={editUser}
							color="button-component--neutral"
						/>
					) : (
						<div className="pair-buttons">
							<ButtonComponent
								text="Cancel"
								onClick={() => setIsEditing(false)}
								color="button-component--neutral"
							/>
							<ButtonComponent
								text="Apply Modification"
								onClick={handleSubmit}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default EditUserOrganization;
