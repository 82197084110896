import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import PasswordInput from "../../form/PasswordInput";
import {FETCH_KO, FETCH_OK, useApiCall} from "../../../hooks/useApiCall";
import {signup, validateToken} from "../../../api/api";
import {passwordPattern} from "../../utils/Patterns";
import {useTranslation} from "react-i18next";
import {LOGIN, SIGNUP_REQUEST} from "../../utils/Routes";

const SignUpPage = () => {
  const {t} = useTranslation("global");
  const {token} = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm();

  const [signupRequest, fetchStateValidateTokenStatus,] = useApiCall(
      () => validateToken(token),
      {
        executeImmediately: true,
      }
  );

  const [, fetchStatus, signupCall] = useApiCall(
      (data) => signup({
        signupRequestId: signupRequest.id,
        password: data.password,
        confirmPassword: data.confirm_password,
        token: token
      }), {
        executeImmediately: false,
      });

  let navigate = useNavigate();
  const goToLogin = () => {
    navigate(LOGIN);
  }
  const goToSignupRequest = () => {
    navigate(SIGNUP_REQUEST);
  }

  const onSubmit = (data) => signupCall(data);

  const defaultFormFieldProperties = {
    errors: errors,
    register: register,
    required: true,
    defaultValue: "",
  };

  return (
      <>
        {fetchStateValidateTokenStatus === FETCH_OK && fetchStatus !== FETCH_OK
            && (
                <div className="content-form">
                  <h2>Create your password</h2>
                  <p>Password must be at least 8 characters long.</p>
                  <p>Password must contain at least one upper case.</p>
                  <p>One lower case letter.</p>
                  <p>Password must contain at least one number or special
                    character.</p>
                  <div className="form-content">
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <div className="input-wrapper">
                        <label>Password</label>
                        <PasswordInput
                            defaultFormFieldProperties={defaultFormFieldProperties}
                            pattern={passwordPattern}
                            patternErrorMessage={t("app.form.error.password")}

                        />
                        <label>Re-Type password</label>
                        <PasswordInput
                            defaultFormFieldProperties={defaultFormFieldProperties}
                            name={"confirm_password"}
                            validatePassword={value => value === watch(
                                    "password")
                                || "The passwords do not match"}
                        />
                      </div>
                      <div className="buttons buttons-fixed">
                        <button type="submit">
                          {"Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
            )}
        {fetchStateValidateTokenStatus === FETCH_KO && (
            <div className="content-form">
              <h2>Error!</h2>
              <p>The token is invalid or has expired, start the registration
                process again</p>
              <button onClick={goToSignupRequest}>
                Register
              </button>
            </div>
        )}

        {fetchStatus === FETCH_OK && (
            <div className="content-form">
              <h2>Password created</h2>
              <p>Your password has been created</p>
              <button onClick={goToLogin}>
                Login
              </button>
            </div>
        )}
      </>
  );
};

export default SignUpPage;


