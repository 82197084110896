export const userPrivateAreaData = [
	{
		organization: "INDITEX",
		name: "John",
		lastname: "Doe",
		email: "john.doe@example.com",
		registration: "2024-01-15",
		role: "ADMIN",
	},
	{
		organization: "ZARA",
		name: "Jane",
		lastname: "Smith",
		email: "jane.smith@zara.com",
		registration: "2023-12-10",
		role: "DEVELOPER",
	},
	{
		organization: "PULL&BEAR",
		name: "Carlos",
		lastname: "Garcia",
		email: "carlos.garcia@pullandbear.com",
		registration: "2024-02-20",
		role: "DEVELOPER",
	},
	{
		organization: "BERSHKA",
		name: "Maria",
		lastname: "Martinez",
		email: "maria.martinez@bershka.com",
		registration: "2023-11-05",
		role: "ADMIN",
	},
	{
		organization: "MASSIMO DUTTI",
		name: "Luis",
		lastname: "Lopez",
		email: "luis.lopez@massimodutti.com",
		registration: "2024-03-18",
		role: "DEVELOPER",
	},
	{
		organization: "STRADIVARIUS",
		name: "Ana",
		lastname: "Hernandez",
		email: "ana.hernandez@stradivarius.com",
		registration: "2024-04-12",
		role: "DEVELOPER",
	},
];
