import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useGlobalVar } from "../../../utils/GlobalContext";

import InputComponent from "../../../atoms/input-component/InputComponent";
import AlertComponent from "../../../atoms/alert-component/alertComponent";
import DividerComponent from "../../../atoms/divider-component/DividerComponent";
import ButtonComponent from "../../../atoms/button-component/ButtonComponent";
import ModalComponent from "../../../molecules/modal-component/ModalComponent";
import RejectRequest from "../../../organisms/reject-request/RejectRequest";

import { REQUESTS_MANAGEMENT } from "../../../utils/Routes";
import { supportByEmail } from "../../../../services/ApiSubscriptions";

import { error as showError, success as showSuccess } from "../../../utils/NotificationsContainer";

import { updateSupport } from "../../../../services/ApiSubscriptions";

const SupportRequestDetails = ({ currentData, updatePhone }) => {
	const { accessToken, userEmail } = useGlobalVar();
	const navigate = useNavigate();

	const [supportAPIResponse, setSupportAPIResponse] = useState(null);

	const [files, setFiles] = useState([]);

	const [showModal, setShowModal] = useState(false);
	const [rejectMessage, setRejectMessage] = useState('');

	const closeModal = () => setShowModal(false);
	const openModal = () => setShowModal(true);

	const downloadFile = (fileContent, fileName) => {
		// Decodificar la cadena Base64
		const byteCharacters = atob(fileContent);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], { type: "image/png" });

		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const handleAprobar = async (e) => {
		e.preventDefault();

		// Combina los datos de requestResponse con confidentialData
		const updatedData = {
			...supportAPIResponse,
			nameOrganization: currentData.organization,
			done : true,
   			rejected: false,
		};

		 // Elimina la propiedad filesUploaded del objeto updatedData
		 const { filesUploaded, ...dataToSend } = updatedData;

		try {
			await updateSupport(dataToSend, userEmail, accessToken);
			showSuccess('Updated request');
			navigate(REQUESTS_MANAGEMENT);
		} catch (error) {
			showError('There was a problem, please try again');
			console.error(error);
		}
	}

	const handleReject = async (e) => {
		e.preventDefault();

		// Combina los datos de requestResponse con confidentialData
		const updatedData = {
			...supportAPIResponse,
			nameOrganization: currentData.organization,
			done : false,
   			rejected: true,
		};

		updatedData.mensaje = rejectMessage;

		 // Elimina la propiedad filesUploaded del objeto updatedData
		 const { filesUploaded, ...dataToSend } = updatedData;

		try {
			await updateSupport(dataToSend, userEmail, accessToken);
			showSuccess('Updated request');
			navigate(REQUESTS_MANAGEMENT);
		} catch (error) {
			showError('There was a problem, please try again');
			console.error(error);
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const email = currentData?.email;
				const id = currentData?.idSoporte;

				const response = await supportByEmail(email, id, accessToken);

				const { filesUploaded } = response;

				setSupportAPIResponse(response);
				setFiles(filesUploaded);
				updatePhone(response.phone);
			} catch (error) {
				console.error("Error al descargar el archivo:", error);
			}
		};

		fetchData();
	}, [currentData?.email, currentData?.idSoporte, updatePhone, accessToken]);

	return (
		<div className="subscribe-request-details subscribe-request-details--full">
			<h2 className="mg mg--b-4">Support request</h2>
			<InputComponent
				type="textarea"
				name="supportRequest"
				value={supportAPIResponse?.contactReason}
				rows={4}
				disabled={true}
				className={"input--full"}
			/>
			<div>
				<p className="fw600 mg mg--b-2">Attached files</p>
				<div className=" request-management__filters mg mg--b-4">
					{files ? (
						<ul>
							{files.map((file) => (
								<li key={file.id}>
									<button
										onClick={() =>
											downloadFile(
												file.fileContent,
												file.fileName
											)
										}
									>
										{file.fileName}
									</button>
								</li>
							))}
						</ul>
					) : null}
				</div>
				<AlertComponent
					text={"This user expects a response via email or phone."}
					bgColor={"bg--brand-warning-100"}
				/>
			</div>
			<DividerComponent />

			<div className="pair-buttons mg mg--t-4">
				<ButtonComponent
					text={"Cancel"}
					onClick={openModal}
					color={"button-component--reject"}
				/>
				<ButtonComponent
					text={"Done"}
					onClick={handleAprobar}
					color={"button-component"}
				/>
			</div>

			<ModalComponent show={showModal} onClose={closeModal} size={'lg'}>
				<RejectRequest onMessageChange={setRejectMessage} rejectRequest={handleReject} onClose={closeModal}/>
			</ModalComponent>
		</div>
	);
};

export default SupportRequestDetails;
