import { Navigate, Outlet } from "react-router";
import { useGlobalVar } from "../utils/GlobalContext";



function PrivateRoute() {
  const { isAuth} = useGlobalVar();
   
  //setPathAfterLogin(useLocation().pathname || '');
  // return <Outlet />;
  return <div>{isAuth ? <Outlet /> : <Navigate to='/' />}</div>;
}

export default PrivateRoute;
