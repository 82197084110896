import React from "react";

const LegalNotice = () => {
	return (
		<section className="info-legal">
			<div className="div-block-7 legalintro">
				<h1 className="title-h1 title-legal">Legal Notice</h1>
				<p className="text-legal"><strong className="text-legal">Last updated: August 2024</strong></p>
				
				<p className="text-legal">
				<strong>
					IMPORTANT INFORMATION
					</strong> <br /> <br />
					
					Please carefully read these terms and conditions (hereinafter, the "Terms") before using our private area of the portal for developers (hereinafter, the "Private Portal") or any of the services therein.
					By accessing, visiting or using the Private Portal, clicking on the “accept” button, or otherwise indicating assent, You (as defined below) are agreeing to be bound by these Terms and affirming that you are of legal age to enter into these Terms. If you do not agree to the Terms, do not access or use the Private Portal.
					For clarity purposes, the term “use” includes, but is not limited to, accessing, browsing, inputting information, and registering into the Private Portal to use the Services. Any reference to “you”, “You” or “Your”, means You as a user of the Private Portal and/or the API Services available through the Private Portal (“User”), and any reference to “we”, “We”, “us”, “Us” or “our” is to PagoNxt Trade Services, S.L. You and We mutually acknowledge sufficient legal capacity and competence for executing these Terms. 
					Access to the Private Portal to use the API Services in Sandbox Environment requires registration as a User, and access to the Private Portal to use the API Services in Pre and Live Environment requires registration and subscription of an agreement with Us, as explained below. <br /> <br /> <br />


					<strong>1. INTRODUCTION</strong><br /> <br />
					PagoNxt Trade Services, S.L. (“PagoNxt”) is a Spanish company domiciled in Avda. de Cantabria s/n, Ciudad Grupo Santander, 28660, Boadilla del Monte (Madrid) and with tax identification number B87959326. PagoNxt Trade Services, S.L. is registered in the Corporate Registry of Madrid, volume 41220, sheet 29, page M-655666, entry 13.
					PagoNxt is the owner or licensee of all intellectual property rights and of any other type of rights on the Private Portal.
					PagoNxt provides payments processing technology services and swift bureau services to financial services companies (“Direct Customers”). Direct Customers may, in certain circumstances, allow to use such technology directly by their final customers (“Indirect User”).
					The Privacy Policy in which we explain the terms under which We process personal data of Users is available here. <br /><br /><br />


					<strong>
						2. PURPOSE AND SCOPE
					</strong> <br /><br />
					The Private Portal is a technological tool that allows access to certain services (the “Services”). These Services may include:
					API Services: the services and/or functionalities accessible or executed through an specific API (ie. an Application Programming Interface –or any other similar technology or software component— offered by Us through the Private Portal to allow You to access and use any of Our API Services as available at any given time, through Your application(s)). Depending on the level of engagement You acquire with Us, API Services can be in Sandbox Environment or in Pre and Live Environment, as described below.
					SDKs: Software Development Kits offered by Us to facilitate use of the Private Portal and time to market. <br /><br /><br />
					
					<strong>
						3. ABOUT THE PRIVATE PORTAL
					</strong> <br /><br />
					<strong>3.1. USE OF THE PRIVATE PORTAL</strong><br /><br />
					
					The Private Portal offers two modalities of use:
					Use of the Private Portal to access to the API Services in Sandbox Environment, which requires to create an account but not to contract any Services. This use will be governed by these Terms.

					Use of the Private Portal to access to the API Services in Pre and Live Environment, which requires to create an account and to subscribe an agreement with Us under the following conditions:

					If You are or act on behalf of a Direct Customer, Your use of the API Services in Pre and Live Environment will be governed by these Terms and by the services agreement signed between such Direct Customer and Us (“Direct Agreement”). In case of discrepancy, the Direct Agreement shall prevail.

					If You are an Indirect User, the relevant Direct Customer will be Your only counterparty for the API Services in Pre and Live Environment. For this, Your agreement with the relevant Direct Customer (“Indirect Agreement”) shall govern Your use of the API Services in Pre and Live Environment. In these cases, PagoNxt will only be acting as a technology provider of the Direct Customer and will not be liable for the API Services in Pre and Live Environment towards the Indirect User in any case.
					For accessing to the API Services in Pre and Live Environment, please contact Us through payments.onboarding@pagonxt.com so We can explain how to proceed. <br /><br /><br />

					<strong>3.2. ACCESS TO THE PRIVATE PORTAL</strong> <br /><br />
					
					Regardless of the chosen mode of use, Your navigation through the Private Portal requires to create an account with login credentials. Once the account is created, we will share with You the token or access code necessary to use the API Services that You have previously agreed with Us.
					By creating an account, You fully accept and commit to comply with these Terms and with any instructions or recommendations made available from time to time on the Private Portal.
					You must treat Your login credentials as confidential and not disclose them to any third party. We have the right to disable any User identification code or password, whether chosen by You or allocated by Us, at any time, if in Our reasonable opinion You have failed to comply with any of the provisions of these Terms. If You know or suspect that anyone other than You knows Your user identification code or password, You must promptly notify Us at payments.support@pagonxt.com.
					Passwords are confidential and non-transferable and shall be valid indefinitely until You modify Your password using the tools available to You on the Private Portal for this purpose. It shall be generated by You following Our password security guidelines as available from time to time. In any case, We have the necessary functionalities allowing You to modify Your password when You deem necessary.
					You shall make diligent use of Your password and shall not make it available to any third party. You shall be responsible for keeping adequate custody of Your login credentials. You shall be liable for any illicit use of the Private Portal by any illegitimate third party who accesses the Private Portal using Your password. <br /><br /><br />

					<strong>
						3.3. API SERVICES
					</strong> <br /><br />
					
					<strong>
						3.3.1. SANDBOX ENVIRONMENT
					</strong> <br /><br />
					
					Some API Services might be available in sandbox environment (“Sandbox Environment”).
					The Sandbox Environment constitutes a trial or evaluation period and is based on a controlled (and therefore fictitious) environment that has no impact on the real world. Thus, any use that You make of the API Services offered through the Private Portal in Sandbox Environment is limited to a testing environment with the specific purpose of trying out the functionalities of the API Services and verifying the interoperability of Your systems prior to submitting Your application to enter live environment (as described below).
					You acknowledge that Your use of the API Services in Sandbox Environment will not constitute access or connection to databases of the entity providing the Services. Instead, the data that shall appear during Your use of the API Services will be completely fictitious and for testing purposes only.
					Likewise, You acknowledge and accept that, to the extent that the Sandbox Environment is based on a controlled trial environment, We may, but do not assume the obligation to, provide support or assistance. This is a fictitious environment made available to You for testing purposes only. <br /><br /><br />



					<strong>3.3.2. PRE ENVIRONMENT</strong> <br /><br />
					
					Pre Environment is a trial environment with no impact on the real world but with access (connection) to the databases and information of the entity which provides the particular Service (“Pre Environment”). It is the previous validation step before going into production in Live Environment. 
					Access to Pre Environment requires to subscribe a Direct Agreement with Us or an Indirect Agreement with one of our Direct Customers. The contracting process will take place offline.
					You expressly acknowledge and accept that We reserve the right to discretionally approve or reject Your request, without having to justify the decision.  <br /><br /><br />

					<strong>3.3.3. LIVE ENVIRONMENT</strong> <br /><br />
					
					Live environment is a production environment with impact on the real world and with access (connection) to the databases and information of the entity which provides the particular service (“Live Environment”). 
					Access to Live Environment requires to subscribe a Direct Agreement with Us or an Indirect Agreement with one of our Direct Customers. The contracting process will take place offline.
					You expressly acknowledge and accept that We reserve the right to discretionally approve or reject your request, without having to justify the decision.  <br /><br /><br />

					<strong>3.4. LIMITATIONS OF USE OF THE PRIVATE PORTAL</strong> <br /><br />
					
					It is expressly forbidden to use or access the Private Portal for illegal or non-authorised purposes. Obligations under this clause apply to all Users. In particular, the following actions are not allowed:
					make available through or in connection with the Private Portal any materials that are defamatory, offensive or otherwise objectionable;
					post any virus that is potentially harmful or invasive or intended to damage the operation of, or to monitor the use of the Private Portal;
					scrape, gather Private Portal content, or circumvent the navigational structure or presentation of the Private Portal, without our prior written consent;
					exploit the Private Portal without our prior written consent;
					make alterations to the Private Portal, or permit the Private Portal or any part of it to be combined with, or become incorporated in, any other programs;
					disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the Private Portal or attempt to do any such thing except solely to the extent that applicable law specifically prohibits such restrictions (such as under sec. 100.5 of the Spanish Copyright Act, provided that You shall first ask us to provide the necessary information, shall set a reasonable deadline for us to do so, and such deadline has passed without us having provided such information);
					provide or otherwise make available the Private Portal (including object and source code) to a third party, without our prior written consent;
					create Internet links to the Private Portal or frame or mirror the Private Portal, or host the Private Portal on a server (including a website) or in any other manner;
					to the maximum extent permitted by applicable law, access or use the Private Portal in order to build a competitive product or service;
					use the Private Portal in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms;
					infringe our intellectual property rights or those of any third party in relation to Your use of the Private Portal (to the extent that such use is not licensed by these Terms); and
					use the Private Portal in a way that could damage or compromise our systems or security or interfere with other users of the Private Portal.
					Failure to comply with any of the aforementioned obligations may entail the application of any measures that We deem necessary or merely convenient, without the obligation to compensate You for any damages which may arise as a result and regardless of the possible action for damages against You.
					We expressly reserve the right to suspend, block, modify, restrict, or temporarily or permanently interrupt Your access, navigation and/or use of the Private Portal, with or without prior notice, if We notice that You are infringing applicable legislation, these Terms or for unforeseen risks derived from the use of the Private Portal. <br /><br /><br />



					<strong>
						4. THIRD PARTY WEBSITES AND SERVICES
					</strong> <br /><br />
					
					<strong> 
						4.1. SERVICES
					</strong> <br /><br />
					
					If You are an Indirect User, Your use of the API Services in Pre or Live Environment is at Your own risk. Your use of the API Services in Pre or Live Environment may be subject to additional or different terms, conditions and policies with third parties as described in section 3.1. above. <br /><br /><br />

					<strong>
						4.2. LINK TO THIRD-PARTY WEBSITES
					</strong> <br /><br />
						
					In the event that the Private Portal displays hyperlinks to other websites by means of buttons, links, banners or embedded content, We inform You that, unless otherwise specified, these are managed and under the control of third parties. We have no control or means, whether technical or human, to monitor, control or approve the information, contents or services provided by other platforms or websites that may be linked to from our Private Portal.
					Consequently, We shall not be held liable for any aspect of the linked website, including, but not limited to, its performance, access, data, information, quality, reliability of the services, any links available in the website and/or any of its contents in general.
					In any case, the establishment of any kind of hyperlink in the Private Portal to another website does not imply any type of relationship, collaboration or dependency between Us and the owners of the third-party website. <br /><br /><br />

					<strong>
						4.3. LINKS TO THE PRIVATE PORTAL FROM OTHER WEBSITES
					</strong> <br /><br />
					
					We do not allow introducing links to the Private Portal in websites that include content or information that is in any way illicit, degrading, or obscene, or that in general contravene the law, morality, public order or generally accepted social standards.
					We have no control nor human or technical resources to access or approve the information, contents and services provided by other websites that may establish links to our Private Portal. We shall not be liable for any aspect related to the website that contains the link, including, but not limited to, aspects relating to its operation, access, data, information, reliance and quality of its services, its own links and/or any of its contents in general. The existence of any links to the Private Portal does not imply any type of relationship, collaboration or dependency between Us and the owners of the third-party website. <br /><br /><br />

					<strong>5. INTELLECTUAL PROPERTY RIGHTS OVER THE PRIVATE PORTAL </strong> <br /><br />
					
					All intellectual and industrial property rights (including under patent law, copyright law, trade secret law, trade mark law, and all other proprietary rights) over the Private Portal , including but not limited to, the Private Portal itself and any texts, photographs or illustrations, logos, brands, graphs, designs, interfaces or any other information, resource, content or service available through the Private Portal are owned by Us or have been licensed to Us accordingly by their corresponding owner for their display and/or availability on the Private Portal.
					Your intellectual and industrial property rights over the Services or the Private Portal shall be limited as follows:
					Services: The license over third-party API Services will be governed by the relevant terms and conditions applicable to each of the API Services as established by the PagoNxt entity who renders the Services.

					Private Portal: We only grant You a limited, non-exclusive, non-transferable, non-sublicensable and revocable license of the intellectual property rights over the Private Portal to access and use the Private Portal for Your internal business purpose and without commercial use.
					Nothwithstanding the above and unless otherwise authorized, Your access to and use of the Private Portal shall in no case involve any waiver, transmission, license or transfer, whether in whole or in part, of any intellectual and industrial property rights.
					We expressly reserve all our respective intellectual and industrial property rights over the Private Portal and its contents and Services. Particularly, We do not allow modification, copy, reproduction, communication, transformation or distribution in any media or form, in whole or in part, of the Private Portal or any of its content for any public or commercial purpose, except under our prior, explicit and written authorisation.
					Moreover, it is forbidden to remove or manipulate copyright indications or any other credit that may identify Private Portal lawful content holders, as well as technical protection devices, digital prints or any protective mechanism or information incorporated to the Private Portal.
					In the event that You submit or send Us information of any kind through the Private Portal, You acknowledge, warrant and accept that You have the necessary rights to do so, and that such information does not infringe intellectual and industrial property rights of any third party, and that such information is not confidential or detrimental to third parties. In this sense, You grant Us a license to use the information provided through the Private Portal, as necessary in order to provide You the requested services and/or contents. <br /><br /><br />

					<strong>6. LIMITATION OF LIABILITY</strong> <br /><br />
					The Private Portal and API Services, including without limitation the technology forming part of it, to the fullest extent permitted under applicable law, are provided on an “as is,” “where is” and “where available” basis, without any warranties or conditions of any kind, whether express or implied, statutory or otherwise. We disclaim all warranties and conditions with respect to the Private Portal or API Services including but not limited to warranties and conditions of satisfactory quality, merchantability, fitness for a particular purpose, non-infringement, title and/or availability.
					We do not guarantee that the Private Portal and API Services are or will remain updated, uninterrupted, complete, correct and/or secure. Third parties may make unauthorised alterations to the Private Portal and Services. If You become aware of any such alteration, contact us at payments.support@pagonxt.com  and provide us with a description of such alteration and its location on the Private Portal.
					To the fullest extent permitted under applicable law: (i) we will not be liable for damages of any kind; (ii) without limiting the foregoing, we will not be liable for damages of any kind resulting from Your use of or inability to use the Private Portal or the API Services, including from any virus that may be transmitted in connection therewith; (c) Your sole and exclusive remedy for dissatisfaction with the Private Portal or the API Services is to stop using the Private Portal and the Services; and (d) our maximum aggregate liability for all damages, losses and causes of action, whether in contract, tort (including negligence) or otherwise, shall be fifty euros (EUR50).
					IF YOU ARE ACTING AS A CONSUMER, i.e., for purposes which are outside your trade, craft, business or profession, the following two paragraphs fully replace the foregoing three paragraphs of this Section (Limitation on Liability):
					“Nothing in these Terms shall have the effect of excluding or limiting our legal liability under applicable law in the event of (i) death or personal injury; (ii) fraudulent misrepresentation or fraudulent concealment of defects of the Private Portal and the API Services; (iii) loss and futile expenses caused by willful intent or gross negligence; (iv) a breach of a contractual guarantee as to the quality of the Private Portal and the API Services (if any); or (v) other cases where liability cannot be excluded or limited by agreement in advance (such as e.g. under applicable product liability laws). Subject to the foregoing sentence only:
					We will not be liable for your loss and expenses (jointly the “Damages”) caused without any negligence or intent on our part;
					We will not be liable for your Damages caused by the breach of a non-material contractual obligation caused with only slight negligence;
					Our liability for the breach of material contractual obligations with only slight negligence will be limited to those Damages foreseeable to the parties at the time of the conclusion of the agreement between You and us that typically arise in transactions of this kind. For this purpose, a material contractual obligation is deemed an obligation, the fulfilment of which is a prerequisite for enabling the proper fulfilment of the contract and on the fulfilment of which You may generally rely.
					Where we provide the Private Portal to You free of charge, We will not be liable for Damages caused with only slight negligence.
					If you are acting as a consumer, i.e., for purposes which are outside your trade, craft, business or profession, your use of the Private Portal and the API Services may be covered by warranties against defects under applicable law. Nothing in these Terms limits or excludes your statutory warranty rights and claims.”
					All limitations of liability of any kind in these Terms are made for the benefit of us and our affiliates and their respective shareholders, directors, officers, employees, affiliates, agents, representatives, licensors, and service providers (collectively, the “Affiliated Entities”), and our and their respective successors and assigns. We do not exclude or limit in any way our liability to You where it would be unlawful to do so.
					To the fullest extent permitted under applicable law, You agree to defend, indemnify and hold harmless us, the Affiliated Entities and their respective successors and assigns, from and against all claims, liabilities, damages, judgments, awards, losses, costs, expenses and fees (including attorneys’ fees) (“Claims”) arising out of or relating to (i) Your use of, or activities in connection with the Private Portal and the API Services; and (ii) any violation or alleged violation of these Terms by You (except for Claims caused by an act or omission of us or our Affiliated Entities). <br /><br /><br />

					<strong>7. WARRANTIES BY US</strong> <br /><br />
					
					We cannot warrant the reliability, usefulness and veracity of all the information, services and/or content available on the Private Portal. As a consequence, We cannot warrant and shall not be liable for:
					The continuity of the contents and API Services available on the Private Portal;
					The absence of errors on the Private Portal and/or available API Services;
					The absence of virus and/or any other damaging components in the Private Portal or the server providing the Private Portal;
					The invulnerability of the Private Portal or the security measures adopted;
					The lack of usefulness or the malfunction of any of the contents of the Private Portal;
					The damages or losses caused by any person who violates the Terms, whether caused to themselves or to third parties. <br /><br /><br />

					<strong>8. WARRANTIES BY YOU</strong> <br /><br />
					
					You undertake to comply with the Terms and to always act in accordance with applicable legislation. Likewise, You will refrain from using the Private Portal in any way that may prevent, damage or deteriorate its normal operation, the assets or rights of PagoNxt, the rest of Users or, in general, any third party.
					You warrant and represent that:
					The information that You have provided is accurate, true and up to date, and that You are not, and will not, hide or provide false information regarding Your identity, and that You will not provide third party information without their explicit consent;
					You have a legitimate and lawful reason to access and use the Private Portal and the API Services;
					You will not perform acts that may harm Us and/or Our rights and interests over the Private Portal and the API Services;
					You will comply with these Terms and any other legal documents available on the Private Portal and/or made available to You through any means and all laws and regulations applicable to You;
					You will block your User account and inform Us as soon as possible if You suspect or have knowledge of an inappropriate, unauthorized or prohibited use by third parties of Your credentials, the Private Portal or any Services;
					If You are using Our Private Portal on behalf of a third-party (i.e., Your company), You have the necessary capacity to act on behalf of Your company and have obtained all necessary consents and approvals to disclose and/or use any data that You and/or Your application(s) collect, generate or use;
					During the term of these Terms, Your application(s) is not and shall not be identified or considered as a service addressed to minors and that You are not included in a website or online service addressed to minors; and
					Your electronic acceptance of these Terms, where applicable, is authentic and is an advance electronic signature from a duly authorized representative, being the signature valid for al purposes according to eIDAS Regulation (Regulation (EU) nº 910/2014 on electronic identification and trust services for electronic transactions in the internal market). <br /><br /><br />

					<strong>9. UPDATES OR MODIFICATIONS OF THE TERMS, THE PRIVATE PORTAL OR THE SERVICES </strong> <br /><br />
					
					We may update these Terms at any time and We will post the updated Terms on the Private Portal. They shall be in effect from the time of their publication until their total or partial modification. From then on, the modified Terms will come into force and apply to Your use of the Private Portal.
					We will notify You via email, or through the Private Portal, when there is a substantial modification of the Terms that majorly affects Your use of the Private Portal. The access and/or use of the Private Portal after these Terms have been applied and/or published imply Your acceptance of the same. 
					Additionally, on occasion, particularly when necessary for any technical, security or business reasons or business continuity, We may perform changes in relation to the Private Portal and/or any API Services (“Modifications”). In such cases, the Modifications to the Private Portal and/or to an available API Services will be published on the Private Portal. You shall comply with any new requirements set by any Modification thereto. 
					You acknowledge and accept that Modifications may adversely affect Your application(s), including but not limited to:
					Changes in the way Your application(s) communicates with any of the Services;
					Changes in the manner in which Your application requests, receives, accesses, displays, transmits and/or uses any Services;
					Changes to any of Our API Services related to a particular API or SDK.
					We shall not be liable to You or to any User for any damages or consequences of any kind arising from any Modification or from its unfavorable effects. <br /><br /><br />

					<strong>10. TERMINATION</strong> <br /><br />
					
					You may terminate these Terms by sending a notice of termination to us via e-mail to payments.support@pagonxt.com , prior notice of fifteen (15) working days. Termination of these Terms may not suppose the termination of the API Services you have hired or contracted under a Direct or Indirect Agreement, which will survive in case of termination of the Terms. To access the API Services once these Terms are terminated, please, contact payments.support @pagonxt.com. The termination of the API Services will be governed by the applicable terms and conditions.
					We may terminate these Terms by written notice to You: (i) on five (5) working days prior notice via e-mail to You for any reason; or (ii) immediately by notice via e-mail to You if You commit a material or persistent breach of these Terms which You fail to remedy (if remediable) within seven (7) natural days after the service of notice via e-mail requiring You to do so.
					Notwithstanding anything to the contrary in these Terms, no termination of these Terms will relieve any party of any of such party’s obligations or liabilities accrued prior to such termination. Termination of these Terms by us will be without prejudice to any other right or remedy that we may have, now or in the future. Upon termination for any reason: (i) all rights granted to You under these Terms shall immediately cease; (ii) we may, without liability to You or any third party, immediately deactivate or delete Your account, and all associated API Services, without any obligation to provide any further access to them and without any compensation of any kind; and (iii) You must immediately cease Your use of the Private Portal and the API Services. <br /><br /><br />

					<strong>11. FORCE MAJEURE</strong> <br /><br />
					
					We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Terms that is caused by any act or event beyond our reasonable control, including failure of public or private telecommunication networks (“Event Outside Our Control”).
					If an Event Outside Our Control takes place that affects the performance of our obligations under these Terms We will use our reasonable endeavours to find a solution by which our obligations under these Terms may be performed. <br /><br /><br />

					<strong>12. CUSTOMER SUPPORT</strong> <br /><br />
					
					As owners of the Private Portal, We offer You a customer service in which We will give due attention to all Your queries, complaints and suggestions in relation to the Private Portal. However, please note that, to the extent that Your query, request, complaint or suggestion refers to an API Service provided by a Direct Customer, We may need to refer it to the entity or We shall indicate You to contact the relevant entity for support. Nonetheless, we do not always have the capacity, means or control to provide You support regarding third-party resources or tools.
					You will be able to access the customer service through payments.support@pagonxt.com.
					Our customer service will respond to complaints or inquiries received in the shortest possible time and, in any case, within the deadline provided under applicable law, depending on the type of complaint or inquiry.
					Likewise, You can use our contact form to get in touch with Us in order to inform Us of the existence of contents within the Private Portal which You consider that are contrary to law, to these Terms and/or violate the legitimate rights of any third party.
					If You provide to us any ideas, proposals, suggestions or other materials (“Feedback”), You agree that such Feedback is not confidential, and that Your provision of such Feedback is free, unsolicited and without restriction, and does not place us under any fiduciary or other obligation. <br /><br /><br />

					<strong>13. CONFIDENTIAL INFORMATION</strong><br /><br />
					You undertake that it shall not at any time reproduce, use, sell, licence, exhibit, publish or otherwise disclose to any person any proprietary, secret or private information obtained as a result of these Terms (“Confidential Information”), except as permitted in this Section.
					You shall not use the Confidential Information for any purpose other than to exercise Your rights and perform Your obligations under or in connection with these Terms.
					Confidential Information does not include, and the obligations under this shall not apply to, information that the receiving party can evidence: (a) is, or later becomes, publicly available through no act or default of the receiving party or any third party; (b) is rightfully in its possession prior to disclosure to receiving party by disclosing party; (c) is received in good faith by the receiving party from a third party, free of any obligation of confidentiality; or (d) is independently developed without use of the disclosing parties Confidential Information. <br /><br /><br />

					<strong>14. MISCELANEA</strong> <br /><br />
					Each of the provisions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.
					We may transfer our rights and obligations under these Terms. You may only transfer Your rights or obligations under these Terms to another person if We agree in writing.
					These Terms do not, and shall not be construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between You and us.
					Any words following the terms: “including”, “include”, “in particular” or “for example” or any similar phrase shall be construed as illustrative and shall not limit the generality of the related general words. <br /><br /><br />

					<strong>15. GOVERNING LAW AND JURISDICTION</strong> <br /><br />
					
					This Private Portal, including Your use of the same, and particularly these Terms shall be subject to, interpreted and enforced in accordance with Spanish law.
					For all disputes, actions or claims that may arise regarding the interpretation and application of these Terms, You and We agree to submit to the jurisdiction of the Courts of the city of Madrid (Spain) with express and voluntary waiver to any other jurisdiction. If You are acting as a consumer, i.e., for purposes which are outside Your trade, craft, business or profession, the foregoing choice of forum and venue does not apply to You, and the forum for any disputes between You and us shall instead be determined by applicable law.
				</p>
			</div>
		</section>

	);
};

export default LegalNotice;
