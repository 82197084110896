import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const getOrganizationsList = async (accessToken) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/organization/list`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		if(error.response.status === 403){
			// REDIRECCIÓN
		}
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

// Repite esto para cada función, añadiendo accessToken como parámetro

export const getOrganizationsTypes = async (accessToken) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/organizations/type`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data.typeOrganizationList;
	} catch (error) {
		console.error("Error fetching type organization data:", error);
		throw error;
	}
};

export const getOrganizationsDetails = async (organizationId, accessToken) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/organization-list-user/${organizationId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const createOrganization = async (formData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/organization/create`,
			formData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		if(error.response.status === 403){
			// REDIRECCIÓN
		}
		console.error("Error:", error);
		throw error;
	}
};

export const updateOrganization = async (updateData, accessToken) => {
	try {
		const response = await axios.put(
			`${API_URL}/api/v1/organization/edit`,
			updateData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		if(error.response.status === 403){
			// REDIRECCIÓN
		}
		console.error("Error:", error);
		throw error;
	}
};

export const deleteOrganization = async (organizationId, accessToken) => {
	try {
		const response = await axios.delete(
			`${API_URL}/api/v1/organization/contract/inactive`,
			{
				data: { cif: organizationId },
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		if(error.response.status === 403){
			// REDIRECCIÓN
		}
		console.error("Error:", error);
		throw error;
	}
};

/* export const createUserOrganization = async (userData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/create-user-by-organization`,
			userData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
}; */

export const updateUserOrganization = async (updateData, accessToken) => {
	try {
		const response = await axios.put(
			`${API_URL}/api/v1/upadte-user-by-organization`,
			updateData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const updateUserMaster = async (updateData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/master`,
			updateData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};

export const getOrganizationsExcel = async (accessToken) => {
	try {
		const response = await axios.get(
			`${API_URL}/api/v1/organization/export`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		if(error.response.status === 403){
			// REDIRECCIÓN
		}
		console.error("Error fetching organization data:", error);
		throw error;
	}
};

export const updatePassword = async (formData, accessToken) => {
	try {
		const response = await axios.post(
			`${API_URL}/api/v1/users_register/reset`,
			formData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${accessToken}`,
				},
			}
		);

		if (response.status !== 200 && response.status !== 201) {
			throw new Error("Network response was not ok");
		}

		return response.data;
	} catch (error) {
		console.error("Error:", error);
		throw error;
	}
};
