import React, { useState } from "react";

const SelectFilterComponent = ({
	options,
	onChange,
	placeholder,
	className,
}) => {
	const [selectedValue, setSelectedValue] = useState("");

	const handleSelectChange = (e) => {
		const value = e.target.value;
		setSelectedValue(value);
		onChange(value);
	};

	return (
		<div className="input-content">
			<div className="input-content__selector">
				<select
					name="selectFilter"
					value={selectedValue}
					onChange={handleSelectChange}
					className={className}
				>
					<option value="">{placeholder}</option>{" "}
					{options.map((option, index) => (
						<option key={index} value={option}>
							{option}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default SelectFilterComponent;
