import React, { useState, useEffect } from "react";

import { success as showSuccess } from "../../../utils/NotificationsContainer";

import { useOrganization } from "../../../context/OrganizationContext";
import { useGlobalVar } from "../../../utils/GlobalContext";

import { getOrganizationsDetails } from "../../../../services/ApiOrganization";

import TabsComponent from "../../../molecules/tabs-component/TabsComponent";
import EditOrganization from "../admin-area-edit-organization/EditOrganization";
import TeamOrganization from "../admin-area-team-manage/TeamOrganization";
import SubscriptionOrganization from "../admin-area-subscription-organization/SubscriptionOrganization";
import BackButtonComponent from "../../../atoms/back-button-component/BackButtonComponent";

import { MANAGE_ORGANIZATIONS } from "../../../utils/Routes";

const DetailsOrganization = () => {
	const { organizationId } = useOrganization();
	const { accessToken } = useGlobalVar();
	const [organizationDetails, setOrganizationDetails] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const currentOrganizationId = localStorage.getItem('currentOrganizationId');
		
		const fetchData = async () => {

			try {

				const organizationDetailData = await getOrganizationsDetails(
					currentOrganizationId,
					accessToken
				);
				

				setOrganizationDetails(organizationDetailData);

				setLoading(false);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
			setLoading(false);
		};

		if (currentOrganizationId) {
			fetchData();
		}
	}, [organizationId, accessToken]);

	const tabs = [
		{ label: "ORGANIZATION", component: EditOrganization },
		{ label: "TEAM", component: TeamOrganization },
		{ label: "SUBSCRIPTIONS", component: SubscriptionOrganization },
	];

	useEffect(() => {
		const reloadUserDeleted = localStorage.getItem('reloadUserDeleted');
		const reloadUserAdded = localStorage.getItem('reloadUserAdded');
		
		if (reloadUserDeleted) {
			showSuccess(`User successfully deleted`);
			localStorage.removeItem('reloadUserDeleted');
		} else if (reloadUserAdded) {
			showSuccess(`New user successfully added`);
			localStorage.removeItem('reloadUserAdded');
		}
		
	  }, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div className="details-organization mg mg--t-4">
			<div className="request-subscription__heading__back-button">
				<BackButtonComponent text={"Back to Organizations list"}  url={MANAGE_ORGANIZATIONS}/>
			</div>
			<h1>{organizationDetails.organization.name}</h1>
			<TabsComponent tabs={tabs} />
		</div>
	);
};

export default DetailsOrganization;
