// DEV
export const poolData = {
  UserPoolId: process.env.AWS_USER_POOL_ID || 'eu-west-1_uRWBvJsHJ',
  ClientId: process.env.AWS_CLIENT_ID || '2vthigaqq7c7o01mggmc21pisv'
};

// PRE
// export const poolData = {
//   UserPoolId: 'eu-west-1_Dpvv1BXDu',
//   ClientId: '53lq64s02bbs2569en2mnct01f'
// };

// PRO
// export const poolData = {
//   UserPoolId: process.env.AWS_USER_POOL_ID || 'eu-west-1_mVcHB7CKD',
//   ClientId: process.env.AWS_CLIENT_ID || '2gd64ed1bb5oc8q6f7puj68afv'
// };
