import React from "react";

const CodeConduct = () => {
	return (
		<section className="info-legal">
			<div className="div-block-7 legalintro">
				<h1 className="title-h1 title-legal">Code of Conduct and Canal Abierto</h1>
				<p className="text-legal"><strong className="text-legal">Last updated: August 2024</strong></p>
				
				<p className="text-legal">
				Speak up! It's our right to be heard <br /><br />
 

				At PagoNxt, we strongly believe in the power of speaking up and reporting any misconduct to ensure that our ethical culture and way of doing things are upheld. <br /><br />
				
				<strong>We strive to foster an environment where employees and anyone associated with PagoNxt can speak openly and be truly heard.</strong> Therefore, we do not hesitate to speak up. <br /><br />
				
				For this purpose, in alignment with Santander Group standards, we have established a whistleblowing channel called Canal Abierto. <br /><br />
				
				Canal Abierto is a multilingual internet platform available 24 hours a day, seven days a week, and it is managed outside Payments Hub to ensure confidentiality and anonymity. <br /><br />
				
				Details on how to access the channel can be found below: <br /><br />
				
				
				<a rel="noreferrer" target="_blank" href="https://secure.ethicspoint.eu/domain/media/en/gui/109840/index.html">https://pagonxt.ethicspoint.com</a> <br /><br />
				
				
				
				Canal Abierto can be used to report any behavior that goes against our Code of Conduct, including our culture and other obligations imposed by the regulation (e.g., irregular financial and accounting practices). <br /><br />
				
				Please refer to the PagoNxt Code of Conduct and our internal regulation on the Canal Abierto Policy for further information. <br /><br />
				
				
				<a rel="noreferrer" href="https://assets.developer.pagonxt.com/site/binaries/content/assets/santander/santanderpaymentshub/common/public/conductcode/2023pagonxt_codigo_de_conducta_405x630pxvf.pdf" target="_blank">Code of Conduct</a><br />
				<a rel="noreferrer" href="https://assets.developer.pagonxt.com/site/binaries/content/assets/santander/santanderpaymentshub/common/public/conductcode/pagonxt-canal-abierto-vf.pdf" target="_blank">Canal Abierto Policy</a>
				</p>
			</div>
		</section>

	);
};

export default CodeConduct;
