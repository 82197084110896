import React, { useState } from "react";

import ButtonComponent from "../../atoms/button-component/ButtonComponent";

// PLACEHOLDERS PARA TEXTAREA
const placeholders = {
	message: "Insert your  reason for rejection",
};


const RejectRequest = ({ onMessageChange, rejectRequest, onClose }) => {

	const [formData, setFormData] = useState({
		message: "",
	});


	const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        
        // Llamar a la función de actualización del padre para actualizar rejectMessage
        if (name === 'message') {
            onMessageChange(value);
        }
    };


	return (
		<>
			<div className="reject-request">
				<div className="reject-request__header">
					<div className="reject-request__header__title">
						<h3>Do you want to reject this request?</h3>
					</div>
					
				</div>
				<form onSubmit={rejectRequest}>
					<div className="reject-request__form">
						
						<div className="reject-request__form__section">
							<div className="reject-request__form__input-content">
								<div className="input-content full">
									<div className="input-content__title">
										<span>
                                            Please leave a reason for reject the request so the user know it.
										</span>
									</div>
									<label>
										<textarea
											name="message"
											value={formData.message}
											placeholder={
												placeholders.message
											}
											autoComplete="off"
											onChange={handleChange}
										/>
									</label>
								</div>
							</div>
						</div>

						
						<div className="reject-request__form__submit">
                            <div className="reject-request__form__submit__button">
								<ButtonComponent 
                                    onClick={onClose} 
                                    color={
										"button-component--neutral"
									} 
                                    text={"Cancel"} 
                                />
							</div>
							<div className="reject-request__form__submit__button">
								<ButtonComponent 
                                    type="submit" 
                                    onClick={rejectRequest}
                                    text={"Yes, reject"}
                                    bgColor={'button-component--bg-danger-500'}
                                />
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default RejectRequest;
