import React, { useCallback, useState } from "react";

const InputComponent = React.memo(
	({
		type,
		name,
		placeholder,
		value,
		onChange,
		label,
		options,
		disabled,
		className,
		rows,
	}) => {
		const [error, setError] = useState(""); // Estado para el mensaje de error

		const handleChange = useCallback(
			(e) => {
				const { name, value, type, checked } = e.target;

				// Si es checkbox, el valor es el "checked"; si no, el valor del input
				onChange(name, type === "checkbox" ? checked : value);

				// Validación para el input de teléfono
				if (type === "tel") {
					const phonePattern = /^\+?[0-9]{10,15}$/; // Expresión regular actualizada
					if (!phonePattern.test(value)) {
						setError("Phone number is not valid");
					} else {
						setError(""); // Limpiar el error si el número es válido
					}
				} else if (type === "email") {
					const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
					if (!value) {
						setError(`${type === "tel" ? "Phone number" : "Email"} is required`);
						return; // Salir de la función para evitar más validaciones si está vacío
					}
					if (!emailPattern.test(value)) {
					  setError("Email is not valid");
					} else {
					  setError(""); // Limpiar el error si el email es válido
					}
				  }
			},
			[onChange]
		);

		switch (type) {
			case "text":
				return (
					<div className="input-container input-content">
						<label>{label}</label>
						<input
							type="text"
							name={name}
							placeholder={placeholder}
							value={value}
							onChange={handleChange}
							disabled={disabled}
							className={className}
						/>
					</div>
				);

			case "email": // Caso para el input de teléfono
				return (
					<div className="input-container input-content">
						<label>{label}</label>
						<input
							type="email"
							name={name}
							placeholder={placeholder}
							value={value}
							onChange={handleChange}
							disabled={disabled}
							className={className}
						/>
						{/* Mostrar el mensaje de error si existe */}
						{error && (
							<small className="err">
								<span></span>
								{error}
							</small>
						)}
					</div>
			);

			case "tel": // Caso para el input de teléfono
				return (
					<div className="input-container input-content">
						<label>{label}</label>
						<input
							type="tel"
							name={name}
							placeholder={placeholder}
							value={value}
							onChange={handleChange}
							disabled={disabled}
							className={className}
							maxLength={15}
						/>
						{/* Mostrar el mensaje de error si existe */}
						{error && (
							<small className="err">
								<span></span>
								{error}
							</small>
						)}
					</div>
			);

			case "textarea":
				return (
					<div className="input-container input-content">
						<label>{label}</label>
						<textarea
							name={name}
							value={value}
							placeholder={placeholder}
							onChange={handleChange}
							rows={rows}
							disabled={disabled}
							className={className}
						/>
					</div>
				);

			case "checkbox":
				return (
					<div className="input-container input-content input-checkbox">
						<label>
							<input
								type="checkbox"
								name={name}
								checked={value}
								onChange={handleChange}
								disabled={disabled}
								className={className}
							/>
							<div className="checkmark">
								<div className="check"></div>
							</div>
							<p>{label}</p>
						</label>
					</div>
				);

			case "radio":
				return (
					<div className="input-container__radio input-content">
						{label}
						{options.map((option, index) => (
							<label key={index}>
								<input
									type="radio"
									name={name}
									value={option.value}
									checked={value === option.value}
									onChange={handleChange}
									disabled={disabled}
									className={className}
								/>
								<div>
									<p className="input-container__radioTitle">
										{option.label}
									</p>
									<p>{option.description}</p>
								</div>
							</label>
						))}
					</div>
				);

			case "select":
				return (
					<div className="input-container input-content">
						<div className="input-content__selector">
							<label>{label}</label>
							<select
								name={name}
								value={value}
								onChange={handleChange}
								disabled={disabled}
								className={className}
							>
								<option value="" disabled>
									{placeholder}
								</option>
								{options.map((option, index) => (
									<option key={index} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</div>
					</div>
				);

			case "date":
				return (
					<div className="input-container input-content">
						<label>{label}</label>
						<input
							type="date"
							name={name}
							value={value}
							onChange={handleChange}
							placeholder={placeholder}
							disabled={disabled}
							className={className}
						/>
					</div>
				);

			case "toggle":
				return (
					<div className="input-container__toggle input-content">
						<label>{label}</label>
						<label className="switch">
							<input
								type="checkbox"
								name={name}
								checked={value}
								onChange={handleChange}
								disabled={disabled}
								className={className}
							/>
							<span className="slider round"></span>
						</label>
					</div>
				);

			case "password":
				return (
					<div className="input-container input-content">
						<label>{label}</label>
						<input
							type="password"
							name={name}
							placeholder={placeholder}
							value={value}
							onChange={handleChange}
							disabled={disabled}
							className={className}
						/>
					</div>
				);

			default:
				return null;
		}
	}
);

export default InputComponent;
