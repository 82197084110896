import { React, useEffect, useState } from "react";
import { useGlobalVar } from "../../../utils/GlobalContext";

import { getApisList } from "../../../../services/ApiApis";

import TableComponent from "../../../molecules/table-component/TableComponent";
import PaginationComponent from "../../../molecules/pagination-component/PaginationComponent";

const APITable = ({ onRowClick }) => {
	const { accessToken, userEmail } = useGlobalVar();
	const requesAPIHeader = ["Name", "Description", "Status"];
	const headerToFieldMap = {
		Name: "nameApis",
		Description: "description",
		Status: "enabled",
	};

	const columnWidths = ["30%", "40%", "30%"];

	const [requestData, setRequestData] = useState([]);
	const [sortColumn, setSortColumn] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [sortedData, setSortedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	// const [filterType, setFilterType] = useState("");
	// const totalPages = Math.ceil(sortedData.length / itemsPerPage);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const apisData = await getApisList(userEmail, accessToken);

				setRequestData(apisData);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [accessToken, userEmail]);

	const handleSort = (header) => {
		const field = requesAPIHeader[header] || header;
		const newSortOrder =
			sortColumn === header && sortOrder === "asc" ? "desc" : "asc";
		const sorted = [...sortedData].sort((a, b) => {
			if (a[field] < b[field]) return newSortOrder === "asc" ? -1 : 1;
			if (a[field] > b[field]) return newSortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setSortColumn(header);
		setSortOrder(newSortOrder);
		setSortedData(sorted);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleItemsPerPageChange = (newItemsPerPage) => {
		setItemsPerPage(newItemsPerPage);
		setCurrentPage(1);
	};

	// const handleFilterTypeChange = (type) => {
	// 	setFilterType(type);
	// };

	const currentData = requestData.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const handleRowClick = (rowIndex) => {
		const API = currentData[rowIndex];

		if (API) {
			onRowClick(API);
		}
	};

	return (
		<div>
			<h2 className="mg mg--b-2 mg--t-4">API configurations</h2>
			<TableComponent
				headers={requesAPIHeader}
				headerToFieldMap={headerToFieldMap}
				data={currentData}
				onSort={handleSort}
				sortColumn={sortColumn}
				sortOrder={sortOrder}
				combineName={true}
				onRowClick={handleRowClick}
				showBtn={false}
				columnWidths={columnWidths}
			/>
			<PaginationComponent
				currentPage={currentPage}
				totalPages={Math.ceil(requestData.length / itemsPerPage)}
				onPageChange={handlePageChange}
				itemsPerPage={itemsPerPage}
				onItemsPerPageChange={handleItemsPerPageChange}
			/>
		</div>
	);
};

export default APITable;
